
@import '@/variables.scss';

.footer {
  &__wrapper {
    position: static;
    width: 100%;
    background: #2e2e2e;
    padding: 40px 0;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &--no-margin {
      margin-bottom: 0;
    }
  }

  &__logo {
    width: auto;
    height: 30px;
  }
  &__social-media {
    margin-left: auto;

    &__link {
      margin-right: 30px;
      display: inline-block;
      width: 30px;
      height: 30px;

      &__icon {
        max-width: 100%;
        height: auto;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__nav-menu__link {
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    line-height: 14px;
    margin-right: 20px;

    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }

  &__bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__copyright {
      color: #9d9d9d;
      font-size: 14px;
      line-height: 14px;
    }

    &__links {
      display: flex;
      align-items: center;
    }

    &__link {
      color: #9d9d9d;
      font-size: 14px;
      line-height: 14px;
      position: relative;
      margin-right: 16px;

      &:hover {
        text-decoration: none;
        color: #9d9d9d;
      }

      &:after {
        content: '';
        position: absolute;
        top: 1px;
        right: -8px;
        height: 13px;
        width: 1px;
        background: #9d9d9d;
      }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: $desktop-s) {
  .footer__row {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer__social-media {
    margin-left: 0;
    margin-top: 24px;
  }

  .footer__social-media__link {
    margin-right: 15px;
  }

  .footer__nav-menu {
    flex-direction: row;
  }

  .footer__bottom {
    flex-direction: column-reverse;
  }

  .footer__bottom__copyright {
    margin-top: 10px;
  }
}

@media screen and (max-width: $mobile-l) {
  .footer__wrapper {
    padding: 30px 0;
  }

  .footer__logo {
    height: 20px;
  }

  .footer__nav-menu {
    flex-direction: column;

    &__link {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 26px;
      }
    }
  }
  .footer__bottom__links {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer__bottom__link {
    margin-right: 0;
    margin-bottom: 10px;

    &:after {
      display: none;
    }
  }
  .footer__bottom__copyright {
    line-height: 16px;
  }
}
