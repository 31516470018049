<template>
  <div class="side-bar-container">
    <h3>{{ $t('borrowerJourney.sideBar.section1.title') }}</h3>
    <p v-html="$t('borrowerJourney.sideBar.section1.p1')" />
    <p v-html="$t('borrowerJourney.sideBar.section1.p2')" />
    <h3>{{ $t('borrowerJourney.sideBar.section2.title') }}</h3>
    <p>
      {{ $t('borrowerJourney.sideBar.section2.p1') }}
      <em>{{ $t('genericPhone') }}</em>
      {{ $t('borrowerJourney.sideBar.section2.p2') }}
      <a :href="'mailto:' + $t('companiesEmail')">{{ $t('companiesEmail') }}</a>
    </p>
  </div>
</template>

<style scoped>
  .side-bar-container {
    max-width: 576px;
    background: #f3f3f3;
    width: 30%;
  }
  @media (min-width: 1025px) {
    .side-bar-container {
      padding: 50px;
    }
  }
  p {
    margin: 20px 0;
  }
  @media (max-width: 1024px) {
    .side-bar-container {
      padding: 20px;
    }
  }
  @media (max-width: 520px) {
    .side-bar-container {
      padding: 10px;
    }
  }
</style>
