
@import '@/sass/components/common/ing.orange.juice';
.become-partner-form {
  @include mq-size(s) {
    width: 92.36%;
  }
  @include mq-size(m) {
    width: 69.9%;
  }
  @include mq-size(xl) {
    width: 62.02%;
  }

  &__fieldset {
    padding: 0 0 px2rem(24px);
    legend {
      background: transparent;
      color: $Primary-Grey600;
      text-align: left;
      padding: 0;
      margin: 0 0 px2rem(12px) 0;
      font-size: px2rem(19px);
    }
  }
}
.general-error-container {
  margin-block: 0 40px;
}
