
.ing-label-wrapper {
  padding-right: 5px;
  display: flex;
}

.ing-input-tooltip {
  margin: -2px 0 0 5px;
  height: 16px;
  width: 100%;
  padding-left: 3px;
}

.ing-input-tooltip-message {
  visibility: hidden;
  word-break: normal;
  font-weight: normal;
  position: absolute;
  background-color: #767676;
  border-radius: 4px;
  border: 1px solid #a8a8a8;
  font-size: 12px;
  padding: 10px;
  color: white;
  top: -79px;
  z-index: 100;
}
.is-readonly input[readonly] {
  opacity: 0.5;
}
