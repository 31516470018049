
@import '@/sass/components/common/ing.orange.juice';

.company-representative-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3%;
  &__col {
    flex-basis: 100%;
    box-sizing: content-box;
    @include mq-size(s) {
      flex-basis: 87.15%;
    }
    @include mq-size(m) {
      flex-basis: 48.5%;
    }
    &--fullWidth {
      flex-basis: 100%;
    }
  }
}
