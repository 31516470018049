[dominicon] .lendico_logo svg,
[dominicon] .lendico_logo_variation svg {
  overflow: visible !important;
}
.lendico_logo,
.lendico_logo_variation {
  .only-icon-icon {
    display: none;
  }
  svg {
    width: 160px;
    height: 24px;
  }
  svg * {
    @extend .transition;
  }
  svg .lendico_logo_text {
    @extend .transition;
    * {
      fill: $grey;
    }
  }
  svg .lendico_logo_icon {
    transform-origin: center;
    overflow: visible;
  }
  svg .lendico_logo_icon * {
    //fill: $petroleum;
  }
  // &:hover {
  //     svg .lendico_logo_icon * {
  //         fill: $darkNavy;
  //     }
  // }
  &.dark,
  &.image {
    svg .lendico_logo_text * {
      fill: $white;
    }
    svg .lendico_logo_icon * {
      fill: $white;
    }
    &:hover {
      svg .lendico_logo_icon * {
        fill: $white;
      }
    }
  }
  &.white {
    svg .lendico_logo_text * {
      fill: $darkerGrey;
    }
    svg .lendico_logo_icon * {
      fill: $darkerGrey;
    }
    &:hover {
      svg .lendico_logo_icon * {
        fill: $darkerGrey;
      }
    }
  }
  .wrapper {
    width: 100%;
  }
  .new {
    svg {
      margin-top: 10px;
      width: 170px;
      height: 26px;
    }
  }
}
.lendico_logo_variation {
  svg {
    width: 133px;
    height: 24px;
  }
}
