
@import '@/variables.scss';

.ing-custom-select {
  position: relative;

  &__input {
    position: relative;

    display: flex;
    border-radius: 4px;

    &:after {
      content: '';
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent;
    }

    &__icon,
    &__element {
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      cursor: pointer;
    }

    &__icon {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      padding: 6px 12px;
      display: flex;
      align-items: center;
    }

    &__element {
      padding: 6px 36px 6px 12px;
      background: #fff;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      outline: none;
      border: solid 1px #ccc;
      width: 100%;
      font-family: inherit;

      &::selection {
        background: transparent;
      }
      &::-moz-selection {
        background: transparent;
      }

      &::placeholder {
        color: #333;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: #333;
      }
      &::-ms-input-placeholder {
        color: #333;
      }

      &--error {
        border: 1px solid #ff0000;
      }
    }
  }

  &__options {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border-radius: 4px;

    position: absolute;
    display: -ms-grid;
    display: grid;
    right: 0;
    left: 0;
    top: 38px;
    overflow: hidden;
    z-index: 1;

    font-size: 16px;
    font-weight: 400;
    line-height: 16px;

    &__item {
      padding: 6px 20px;
      text-align: initial;
      color: #333;

      &:hover {
        background-color: #f5f5f5;
        color: #262626;
        cursor: pointer;
      }

      &--selected {
        color: #ff6200 !important;
      }
    }
  }
}

/*
  * Mobile
  */
@media screen and (max-width: $mobile-l) {
  .ing-custom-select {
    &__input {
      &__icon,
      &__element {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}
