
@import '../../../../sass/custom/variables';
@import '@/variables.scss';
.campaign-container {
  &__heading {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    color: $gray-dark;
    margin-top: 50px;
    margin-bottom: 24px;
    @media screen and (max-width: $desktop-s) {
      font-size: 24px;
      line-height: 32px;
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }
  &__content {
    font-size: 24px;
    line-height: 32px;
    color: $gray-dark;
    @media screen and (max-width: $desktop-s) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.ing-advantages-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ing-advantage-panel-container {
  display: flex;
  width: 296px;

  h4.header {
    font-size: 16px;
    font-weight: bold;
    color: $ing-gray-label;
  }

  p.text {
    font-size: 19px;
    line-height: 28px;
    color: $ing-gray-label;
    margin-top: 6px;
  }

  .tick-icon {
    height: 23px;
  }
  .ing-advantages-text {
    margin-left: 13px;
  }
}

@media (max-width: $mobile-l) {
  .ing-advantages-container {
    flex-direction: column;
    margin-top: 25px;

    h4.header,
    p.text {
      font-size: 14px;
      line-height: 22px;
      margin-top: 0;
    }
  }
  .ing-advantage-panel-container {
    width: fit-content;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* styling when this component is loaded in the hero section */
.hero-style {
  flex-direction: column;
  @media screen and (max-width: $desktop-s) {
    margin-top: 20px;
  }

  .ing-advantages-text {
    display: flex;

    @media screen and (max-width: $desktop-s) {
      flex-direction: column;
    }
  }

  h4.header {
    font-size: 24px;
    line-height: 32px;

    @media screen and (max-width: $desktop-s) {
      font-size: 16px;
      line-height: 24px;
    }

    @media screen and (max-width: $mobile-l) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  p.text {
    font-size: 19px;
    line-height: 28px;
    margin-top: 0;
    margin-left: 6px;

    @media screen and (max-width: $desktop-s) {
      font-size: 16px;
      line-height: 24px;
      margin-left: 0;
    }
  }

  .tick-icon {
    width: 23px;
    height: auto;
  }

  .ing-advantage-panel-container {
    width: fit-content;
    margin-bottom: 24px;
    align-items: flex-start;

    .tick-icon {
      padding-top: 4px;
    }
    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: $desktop-s) {
      .tick-icon {
        padding-top: 0;
      }
    }

    @media screen and (max-width: $mobile-l) {
      width: 260px;
    }
  }
}
