
.arrow-down {
  transform: rotate(0deg);
  transition: transform 0.2s ease;

  &.up {
    transform: rotate(180deg);
    transition: transform 0.2s ease;
  }
}
