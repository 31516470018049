<template>
  <div class="ing-header-container">
    <img
      alt="Submit Icon"
      class="submit-icon header-icon"
      src="/ing_thumbs_up.png"
    />
    <div class="ing-header-container__content">
      <h2 class="ing-header-text">
        {{ $t('ingBorrowerSignupForm.submitHeader.title') }}
      </h2>
    </div>
  </div>
</template>
<style scoped>
  .ing-header-container {
    align-items: center;
  }

  .header-icon {
    margin-bottom: 0;
  }
  h2.ing-header-text {
    font-weight: bold;
    padding-bottom: 0 !important;
  }
</style>
