
@import '../../../../sass/custom/settings';
@import '../../../../sass/custom/variables';
.field-label {
  display: inline-flex;
  align-items: baseline;
}
em {
  color: $ing-dark-gray;
}
@media (max-width: 870px) {
  .online-revenue-title-container {
    max-width: 80%;
  }
}
