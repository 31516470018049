
@import '../../../sass/custom/variables';
.signing-first-paragraph {
  margin-bottom: 20px;
}
p {
  color: $ing-gray-advantages;
  em {
    color: $ing-black;
  }
}
h2 {
  font-weight: bold;
}
