
@import 'src/sass/components/common/ing.orange.juice.scss';

.offer-contact-title {
  color: $Primary-Orange;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 14px;
}
.content {
  color: $Primary-Grey600;
  margin-bottom: 24px;
}
.contact-link {
  color: $Secondary-Indigo500;
  display: inline-block;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-decoration-line: underline;
}
.contact-phone {
  margin-right: 20px;
}
.contact-email {
  margin-left: 20px;
}
