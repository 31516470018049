
@import '../../../../sass/custom/settings';
.legal-name-wrapper {
  position: relative;
  line-height: 1.75;
}
.legal-name-tooltip {
  position: absolute;
  left: 80px;
  top: 5px;
  &.on-input-focus {
    top: -12px;
  }
}
