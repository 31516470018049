@mixin checklist-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 2px;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: block;
}
.checklist {
    padding: 0 0 20px;
    position: relative;
    display: block;
    margin: 0;
    @include bp(mobile) {
        padding: 0 0 20px;
        margin-bottom: 0;
        position: relative;
        width: 100%;
        display: table;
        min-height: 0 !important;
    }
    &.greyed-out li.ticked,
    li {
        color: $darkerGrey;
        @include font-size(16);
        padding-left: 30px;
        position: relative;
        display: block;
        margin-top: 0;
        margin-bottom: 10px;
        big {
            color: $petroleum;
            font-weight: 700;
        }
        p {
            margin: 0;
        }
        &:before {
            content: '' !important;
        }
    }
    li.delimiter {
        height: 14px;
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background: $darkerGrey;
            z-index: 1;
        }
        label, label * {
            color: $petroleum;
            @include font-size(14);
            font-weight: 100;
            font-style: italic;
            float: right;
            line-height: 1;
            padding: 0 0 0 10px;
            background: $white;
            position: relative;
            z-index: 2;
        }
    }
    &.greyed-out li.delimiter {
        &:before {
            background: $grey;
        }
        label, label * {
            color: $grey;
        }
    }
    &.greyed-out li,
    li.crossed {
        color: $grey;
        @include opacity(.6);
        &.green {
            @include opacity(1);
        }
        // span.icon {
        //
        // }
        big {
            color: $grey;
            font-weight: 700;
        }
    }
    &.greyed-out li.ticked {
        color: $darkerGrey;
        @include opacity(1);
        &.green {
            @include opacity(1);
        }
        // span.icon {
        //
        // }
        big {
            color: $petroleum;
            font-weight: 700;
        }
    }
    span.icon {
        @include checklist-icon;
    }
    span.icon-ticked {
      // moved to cdn background-image: url('../assets/images/img-tick-dark.svg');
        &.green {
          // moved to cdn background-image: url('../assets/images/img-tick-green.svg');
        }
    }
    span.icon-crossed {
        width: 16px;
        height: 16px;
      // moved to cdn background-image: url('../assets/images/img-cross-dark.svg');
        &.green {
          // moved to cdn background-image: url('../assets/images/img-cross-green.svg');
        }
    }
    &.dark {
        &.greyed-out li.ticked p,
        li p{
            color: $white;
        }
        li.delimiter {
            &:before {
                background: $white;
            }
            label, label * {
                color: $petroleum;
                background: $darkerGrey;
            }
        }
        &.greyed-out li.delimiter {
            &:before {
                background: $white;
            }
            label, label * {
                color: $white;
            }
        }
        &.greyed-out li p,
        li.crossed p {
            color: $grey;
            big {
                color: $grey;
            }
        }
        &.greyed-out li.ticked p {
            color: $white;
            big {
                color: $petroleum;
            }
        }
        span.icon-ticked {
          // moved to cdn background-image: url('../assets/images/img-tick-white.svg');
            &.green {
              // moved to cdn background-image: url('../assets/images/img-tick-green.svg');
            }
        }
        span.icon-crossed {
          // moved to cdn background-image: url('../assets/images/img-cross-white.svg');
            &.green {
              // moved to cdn background-image: url('../assets/images/img-cross-green.svg');
            }
        }
    }
    &.grey {
        &.greyed-out li.ticked p,
        li p {
            color: $grey;
        }
        li.delimiter {
            &:before {
                background: $grey;
            }
            label, label * {
                color: $petroleum;
                background: $lightGrey;
            }
        }
        &.greyed-out li,
        li.crossed {
            color: $grey;
            @include opacity(1);
            &.green {
                @include opacity(1);
            }
            // span.icon {
            //
            // }
            big {
                color: $grey;
                font-weight: 700;
            }
            span.icon-ticked {
                @include opacity(.5);
            }
            span.icon-crossed {
                @include opacity(.5);
            }
        }
        &.greyed-out li.ticked {
            color: $darkerGrey;
            @include opacity(1);
            &.green {
                @include opacity(1);
            }
            span.icon-ticked {
                @include opacity(.5);
            }
            span.icon-crossed {
                @include opacity(.5);
            }
            big {
                color: $petroleum;
                font-weight: 700;
            }
        }
        &.greyed-out li.delimiter {
            &:before {
                background: $grey;
            }
            label, label * {
                color: $grey;
            }
        }
        &.greyed-out li p,
        li.crossed p {
            color: $grey;
            big {
                color: $grey;
            }
        }
        &.greyed-out li.ticked p {
            color: $grey;
            big {
                color: $petroleum;
            }
        }
        span.icon-ticked {
          // moved to cdn background-image: url('../assets/images/img-tick-dark.svg');
            @include opacity(.5);
            &.green {
              // moved to cdn background-image: url('../assets/images/img-tick-green.svg');
            }
        }
        span.icon-crossed {
            @include opacity(.5);
          // moved to cdn background-image: url('../assets/images/img-cross-dark.svg');
            &.green {
              // moved to cdn background-image: url('../assets/images/img-cross-green.svg');
            }
        }
    }
    &.image {
        li.delimiter {
            &:before {
                background: $white;
            }
            label, label * {
                display: none !important;
            }
        }
        &.greyed-out li.delimiter {
            label, label * {
                display: none !important;
            }
        }
    }
}
