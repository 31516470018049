
@import '../../sass/custom/variables';
h2 {
  font-weight: bold;
}
ol {
  list-style: decimal outside;
  color: $ing-gray-advantages;
  margin: 40px 0 40px 20px;
}

li {
  line-height: 24px;
}
.submit-button-wrapper {
  display: flex;
  justify-content: center;
}
