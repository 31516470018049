
@import '@/sass/components/common/ing.orange.juice';

.ing-company-name-field {
  position: relative;
}
.spinner {
  position: relative;
}

.ing-dropdown-menu {
  position: absolute;
  top: 71px;
  bottom: auto;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  background: $Primary-white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 0;
  padding: 8px 0;
}
.ing-dropdown-item {
  font-size: 16px;
  line-height: 24px;
  color: $Primary-Grey600;
  padding: 3px 12px;
  white-space: normal;
  cursor: pointer;
  &:hover {
    background: $Secondary-Indigo500;
    color: $Primary-white;
  }

  &--selected {
    font-weight: bold;
  }

  &--keep-typing,
  &--wrong-company-name,
  &--no-company-found {
    padding-block: 0;
    &:hover {
      background: transparent;
      color: $Primary-Grey600;
      cursor: default;
    }
  }

  &--keep-typing {
    text-align: center;
  }

  &--wrong-company-name {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    gap: 11px;
  }

  &--no-company-found {
    border-top: 5px solid $Primary-white;
    box-shadow: 0px -1px 0px 0px $Primary-Grey600;
    margin: 8px 12px 0;
    padding: 0 0 8px 0;
  }
}
