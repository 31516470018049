
@import '../../../../sass/custom/variables';
@import '@/variables.scss';

.ing-privacy-policy {
  font-size: 12px;
  margin: 20px 0 40px 0;
  color: $ing-gray;

  @media screen and (max-width: $tablet) and (min-width: $mobile-l) {
    margin: 40px 0;
  }

  p {
    padding-bottom: 10px;
  }

  .modal-inner-content {
    font-size: 16px;
    line-height: 24px;
    color: #333;
    letter-spacing: -0.02em;
    padding-bottom: 50px;
  }
  .modal-inner-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    padding: 0;
  }
}
