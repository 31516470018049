
@import '@/variables.scss';

.first-column,
.second-column {
  vertical-align: top;
  margin-top: 4px;
}
.first-column {
  padding-right: 3.5%;
}
.second-column {
  padding-left: 3.5%;
}
.flex--center {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: $mobile-l) {
  .first-column,
  .second-column {
    padding: 0;
    margin-top: 0;
  }
  .first-column {
    margin-top: 4px;
  }
}
