
@import '~@/variables.scss';
@import '~@/sass/custom/_settings.scss';

.header-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 40px;
}

.title {
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 38px;
  letter-spacing: normal;
  color: #29bdba;
  margin-left: 12px;
  margin-bottom: 18px;
  margin-top: 0;
}

.explanation {
  text-align: left;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #2e2e2e;
  width: 100%;
}

.tooltip-icon {
  position: relative;
  background: url('~@/assets/img/tooltip_icon.svg') 96% 2px no-repeat;
  padding-right: 24px;
  display: inline-block;
}

.tooltip-content {
  display: none;
  position: absolute;
  bottom: 35px;
  left: -134px;
  background-color: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 4px rgba(46, 46, 46, 0.1);
  border-radius: 1px;
  font-size: 12px;
  line-height: 14px;
  padding: 10px;
  color: $darkGrey;
  z-index: 100;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  width: 287px;

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
  }
}

.show-tooltip {
  display: block;
}

@media only screen and (max-width: $mobile-l) {
  .header-container {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 25px;
  }
  .title {
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-left: 0;
  }
  .explanation {
    font-size: 16px;
    line-height: 18px;
  }
}

// Only for Tablets
@media screen and (max-width: ($desktop-s - 1px)) and (min-width: $tablet) {
  .tooltip-content {
    left: 5px;

    &:after {
      left: 2%;
    }
  }
}

@media screen and (max-width: $mobile-l) {
  .tooltip-content {
    bottom: unset;
    top: 110%;
    width: 100%;
    left: 0;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25), 0 0 4px rgba(46, 46, 46, 0.1);

    &:after {
      display: none;
    }
  }
  .explanation {
    position: relative;
  }
  .tooltip-icon {
    position: unset;
  }
}
