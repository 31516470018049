.main-table{
  width: 100%;
  display: table;
  position: relative;
  td, .td{
    padding: 10px 5px;
    vertical-align: middle;
    &:first-child{
      padding-left: 20px;
    }
    &:last-child{
      padding-right: 20px;
    }
    color: $grey;
    @include font-size(14);
    font-weight: $semibold;
    text-transform: uppercase;
    strong{
      font-weight: $bold;
      color: $darkerGrey;
    }
    &.min-width{
      min-width: 120px;
    }
    @include bp(mobile){
      padding: 20px;
    }

    input[type=text]{
      font-weight: $bold;
      color: $darkerGrey;
      background: none;
      text-align: right;
      border: none;
      max-width: 70px;
    }
  }
  td.clear-style{
    padding: 0;
  }
  tr{
    background: $white;
    &:nth-child(even){
      background: $lightGrey;
    }
  }
  thead, tbody{
    width: 100%;
    position: relative;
  }
  thead{
    tr{
      background: $grey;
    }
    td{
      color: $white;
      @include font-size(14);
      font-weight: $semibold;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }
  a{
    @extend .cta;
    @include font-size(14);
    text-transform: none;
    font-weight: $semibold;
  }
  .custom-graph svg{
    width: 18px;
    height: 18px;
  }

  .hide-desktop{
    display: none;
  }

  @include bp(mobile){
    .hide-desktop{
      display: block;
    }
    thead{
      display: none;
    }
    table, tr{
      display: block;
    }
    td{
      display: inline-block;
      width: 100%;
      text-align: center;
      //vertical-align: top;
      vertical-align: middle;
      background: lighten($lightGrey, 5%);
      .row-lable {
        float: left;
      }
      .value {
        float: right;
      }
    }
    td:not(:first-child), .td:not(:first-child){
      display: none;
      width: 100%;
      text-align: right !important;
      vertical-align: middle;
      padding: 0px 20px;
      margin: 10px 0;
      background: none;
      *:not(.modal, .modal *){
        display: inline-block;
      }
      label{
        float: left;
      }
      &.hide-label label{
        display: none;
      }
      &.cta-cell a.cta{
        background: $petroleum;
        color: $white;
        border: solid 1px rgba($petroleum, 0);
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        //min-width: 273px;
        width: 100%;
        @include font-size(16);
        font-weight: $semibold !important;
        &.active,
        &:focus,
        &:hover {
          background: $darkNavy;
          text-decoration: none;
          color: $white;
          font-weight: $semibold;
        }
      }
    }
    td:first-child{
      display: block;
      position: relative;
      &:after {
        // moved to cdn background-image: url('../assets/images/img-arrow_down-dark.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        width: 60px;
        height: 55px;
        position: absolute;
        display: block;
        right: 0;
        top: 50%;
        margin-top: -27px;
        content: '';
      }
    }
    tr{
      border-bottom: solid 1px $lightGrey;
    }
    tr{
      background: $white;
      &:nth-child(even){
        background: $white;
      }
    }
    tr.open td:not(:first-child), tr.open .td:not(:first-child){
      display: inline-block;
    }
    tr.open td:first-child{
      &:after {
        @include css3( 'transform', 'rotate(-180deg)');
      }
    }
  }
}

svg.dots{
  width: 12px;
  height: 12px;
  display: inline-block;
  circle{
    fill: $darkerGrey;
  }
  &.green circle{
    fill: $green;
  }
  &.yellow circle{
    fill: $yellow;
  }
  &.red circle{
    fill: $orange;
  }
}
