
@import '../../../../sass/custom/variables';
.ing-progress-bar-container {
  position: relative;
  display: flex;
  margin-bottom: 50px;
  align-items: baseline;
}
.step-container {
  padding: 2%;
  height: 36px;
  line-height: normal;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
}
.first-step-container {
  color: $ing-gray;
  border: 1px solid $ing-gray;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.first-step-container.active {
  color: $ing-orange;
  border: 1px solid $ing-orange;
  border-right: none;
}
.second-step-container {
  color: $ing-gray;
  border: 1px solid $ing-gray;
  border-left: none;
  padding-left: 6%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.second-step-container.active {
  color: $ing-orange;
  border: 1px solid $ing-orange;
  border-left: none;
}

.second-step-container.disabled {
  cursor: default;
}

.step-arrow {
  position: absolute;
  left: 190px;
  top: 5px;
  border: solid $ing-orange;
  border-width: 0 1px 1px 0;
  border-bottom-right-radius: 4px;
  display: inline-block;
  padding: 12px;
  height: 12px;
  transform: rotate(-45deg);
}
@media (max-width: 512px) {
  .step-container {
    font-size: 12px;
  }
  .step-arrow {
    left: 135px;
  }
}
@media (max-width: 300px) {
  .step-container {
    font-size: 10px;
  }
  .step-arrow {
    left: 105px;
  }
}
