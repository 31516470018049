
@import '../../../sass/custom/_settings.scss';
p {
  font-size: 14px;
  line-height: 21px;
}
@media (max-width: 870px) {
  .cash-intensity-title-container {
    max-width: 80%;
  }
}
