<template>
  <div>
    <h3 class="sme-header">
      {{ $t('forms.signupForm.smeHeader.h3') }}
    </h3>
    <p class="sme-description">
      {{ $t('forms.signupForm.smeHeader.p') }}
    </p>
  </div>
</template>

<style scoped lang="css">
  .sme-header {
    font-size: 19px;
    line-height: 19px;
  }
  .sme-description {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
  }
</style>
