
@import '../../../sass/custom/variables';

h2 {
  font-weight: bold;
}

h3 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}
p {
  margin-bottom: 40px;
}

p,
li {
  color: $ing-gray-advantages;
}

li {
  line-height: 24px;
}
li::before {
  background: no-repeat left url('~@/assets/img/ing_submit_icon.png');
  background-size: contain;
  width: 16px;
  height: 14px;
  content: '';
  display: inline-block;
  margin-right: 20px;
}
