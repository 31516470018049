
@import '@/variables.scss';

.bookkeeping-header-container {
  display: flex;
  flex-direction: row;
  position: relative;

  .tooltip-wrapper {
    margin-left: 15px;
  }
  .ing-tooltip-message {
    height: fit-content;
    bottom: 35px;
    right: auto;
    left: -50px;
    width: 400px;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 100%;
      margin-left: -3px;
      height: 0;
      width: 0;
      border: 5px solid transparent;
      border-top: 5px solid #767676;
      display: block;
      z-index: 200;
    }
  }

  @media screen and (max-width: $tablet) {
    .ing-tooltip-message {
      left: 50px;
      width: 300px;

      &:before {
        left: 99px;
      }
    }
  }

  @media screen and (max-width: $mobile-l) {
    .ing-tooltip-message {
      width: 100%;
      left: auto;

      &:before {
        left: 150px;
      }
    }
  }
}
