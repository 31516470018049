
@import '@/variables.scss';

.len-native-select {
  position: relative;
  display: flex;
  border-radius: 4px;

  &__icon,
  &__element {
    font-size: 16px;
    line-height: 20px;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 6px 0 6px 15px;
    z-index: 0;
  }

  &__element {
    cursor: pointer;
    padding-right: 29px;
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    position: relative;
    z-index: 1;
    font-family: inherit;
    line-height: 20px !important;

    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
    /* For IE10 */
    &::-ms-expand {
      display: none;
    }
  }
}
