
@import '@/sass/components/common/ing.orange.juice';
.identity-details {
  .radio-group {
    padding-bottom: 1rem;
  }
  .passport-hint {
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 1rem;

    .warning-icon {
      position: relative;
      top: 2px;
    }
  }
}
