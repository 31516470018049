
@import 'src/sass/components/common/ing.orange.juice.scss';

.signing-info-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 16px;
}
.signing-first-paragraph {
  margin-bottom: 20px;
}
.content {
  color: $Primary-Grey600;
  em {
    font-weight: 700;
  }
}
