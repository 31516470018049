
.last-name-birth-label {
  margin-bottom: 0;
}
.radio-input-container {
  display: flex;
  height: 41px;
}
:deep(.last-name-birth-radio) {
  margin-right: 20px;
  padding-top: 7px;
  text-align: center;
}
.validation {
  margin-bottom: 0px;
}
.hidden {
  visibility: hidden;
}
:deep(.ing-error) {
  height: fit-content;
  line-height: 15px;
}
