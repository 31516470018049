
@import '@/sass/components/common/ing.orange.juice';
.page-container {
  flex-grow: 1;

  .contact {
    &__headline {
      font-size: 32px;
      line-height: 40px;
      color: $Primary-Orange;
      font-weight: 400;
      padding-bottom: 24px;
      margin-top: 5vh;
      @include mq-size(m) {
        width: 89.6%;
      }
      @include mq-size(xl) {
        width: 69.3%;
      }
    }
    &__content {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 24px;
      &:last-child {
        padding-bottom: 0;
      }
      @include mq-size(m) {
        width: 89.6%;
      }
      @include mq-size(xl) {
        width: 69.3%;
      }
    }
    &__link {
      font-size: 24px;
      line-height: 32px;
      text-decoration: underline;
      color: $Secondary-Indigo500;

      &:nth-child(1) {
        margin-right: 20px;
      }
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
    &__link-container {
      font-size: 18px;
      line-height: 32px;
    }
  }
}
