
@import '../../../../../sass/custom/variables';
.input-container {
  display: flex;
  align-items: center;
}
.input-container input,
.input-container div {
  font-size: 16px;
}
.input-container input {
  border: none;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  width: 100%;
  outline: none;
  padding-left: 5px;
}
.input-container input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b5b5b5;
  opacity: 1; /* Firefox */
}
.input-container input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b5b5b5;
}
.input-container input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b5b5b5;
}

:local {
  --border-radius: 2px;
}

li.last-preferred {
  border-bottom: 1px solid #cacaca;
}
.iti-flag {
  margin-right: 5px;
  margin-left: 5px;
}
.dropdown-item .iti-flag {
  display: inline-block;
}
.selection {
  font-size: 0.8em;
  display: flex;
  align-items: center;
}
ul {
  padding: 0;
  margin: 0;
  text-align: left;
  list-style: none;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  top: 26px;
  left: -12px;
  background-color: #fff;
  border: 1px solid $ing-orange;
  z-index: 1;
}
.dropdown {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}
.dropdown.open {
  background-color: #f3f3f3;
}
.dropdown:hover {
  background-color: #f3f3f3;
}
.change-country-disabled.dropdown {
  background-color: white;
  cursor: auto;
}
.dropdown-arrow {
  transform: scaleY(0.5);
  display: inline-block;
  color: #666;
}
.dropdown-item {
  cursor: pointer;
  padding: 4px 0;
  display: flex;
  align-items: center;
}
.dropdown-item.highlighted {
  background-color: #f3f3f3;
}
