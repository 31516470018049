
@import '@/variables.scss';
@import '@/sass/custom/_variables';

.ing-submission-overview {
  padding: 0 20px 0;

  @media (min-width: $desktop-l) {
    padding: 0;
  }
}

.ing-data-overview-container {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  word-break: break-all;
  flex-direction: column;

  @media (min-width: $tablet) {
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    width: 1100px;
  }

  @media (max-width: 1280px) {
    p {
      margin: 5px 0 10px;
    }
  }
}
h2 {
  font-weight: bold;
}

.column {
  &--left,
  &--right {
    flex: 1 1 auto;
  }

  @media (min-width: 1280px) {
    &--left {
      flex-basis: 40%;
      padding-right: 10px;
    }

    &--right {
      flex-basis: 50%;
      padding-left: 10px;
    }
  }

  &__section {
    margin-bottom: 20px;
  }
}
