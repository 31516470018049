
@import '@/variables.scss';
@import '@/sass/components/common/ing.orange.juice';

.company-info-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3%;
  &__col {
    flex-basis: 100%;
    box-sizing: content-box;
    @include mq-size(s) {
      flex-basis: 87.15%;
    }
    @include mq-size(m) {
      flex-basis: 48.5%;
    }
  }
}
.form-sub-section {
  &__title {
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
    color: $Primary-Grey600;
    padding: 0.75rem 0;
  }
  &__para {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 20px;
  }
}
