
@import '@/sass/components/common/ing.orange.juice';
.form-section {
  font-size: 16px;
  &__title {
    line-height: 36px;
    font-size: 28px;
    padding-bottom: 16px;
    font-weight: 700;
    @include mq-size(m) {
      width: 89.6%;
    }
    @include mq-size(xl) {
      width: 69.3%;
    }
  }
  &__content {
    padding-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
    @include mq-size(m) {
      width: 89.6%;
    }
    @include mq-size(xl) {
      width: 69.3%;
    }
  }
  &__ordered-list,
  &__unordered-list {
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
  }
  &__ordered-list {
    list-style: decimal;
  }
  &__unordered-list {
    list-style: square;
    padding-bottom: 20px;
    margin-top: -15px;
  }
  &__para {
    padding-bottom: 16px;
    &:last-child {
      padding-bottom: 0;
    }
  }
}
