
@import '@/sass/custom/_settings.scss';

.checkbox-label {
  font-size: 16px;
  line-height: 18px;
}
.tooltip-icon {
  position: relative;
  background: url('~@/assets/img/tooltip_icon.svg') 96% 2px no-repeat;
  padding-right: 28px;
  display: inline-block;
}
.tooltip-content {
  display: none;
  position: absolute;
  bottom: 25px;
  left: 93px;
  background-color: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 4px rgba(46, 46, 46, 0.1);
  border-radius: 1px;
  font-size: 12px;
  line-height: 14px;
  padding: 15px 20px;
  color: $darkGrey;
  z-index: 100;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
  }
}
.show-tooltip {
  display: block;
}
.remove-white-space {
  margin-right: -2px;
}
