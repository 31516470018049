
.form-group {
  padding-bottom: 0;
}
.error-container {
  height: 25px;
}
.validation {
  margin-bottom: 0px;
}

:local {
  --border-radius: 2px;
}
.vue-country-input {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 16px;
}

input {
  border: none;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  width: 100%;
  outline: none;
  padding-left: 7px;
}
ul.dropdown-list {
  padding: 0;
  margin: 0;
  text-align: left;
  list-style: none;
  max-height: 276px;
  overflow-y: scroll;
  position: absolute;
  top: -25px;
  left: 0;
  background-color: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0px 2px 4px rgba(46, 46, 46, 0.15);
  border-radius: 0 0 4px 4px;
}
ul.dropdown-list.is-empty {
  border: none;
}
.dropdown {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: relative;
}
.dropdown-item {
  cursor: pointer;
  padding: 12px 10px;
  display: flex;
  align-items: center;

  &.highlighted {
    background: #29bdba;
    color: #fff;
    font-weight: 600;
  }
}

.dropdown-menu.show {
  max-height: 300px;
  overflow: scroll;
}

.long-error {
  height: 50px;
}
ul.dropdown-list-error {
  top: -50px;
}
