
.modal {
  &.hidden {
    visibility: hidden;
  }

  display: block;
  .modal-dialog {
    max-width: 816px;
    min-height: 544px;
  }

  .authentication-content {
    padding: 20px;
    text-align: center;
    h3 {
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 10px;
    }
    p {
      font-size: 16px;
      margin: 0 auto;
    }
    fieldset {
      display: block;
    }
  }
  .password-input {
    padding: 55px 0 20px 0;
    text-align: left;
    margin: 0 auto;
  }
  .ing-password-error {
    min-height: 102px;
    height: fit-content;
    padding: 5% 8% 2% 8%;
    justify-content: center;
    img {
      margin: 3px 5px 0 0;
    }
    p {
      align-self: flex-start;
    }
  }
  .error-message {
    font-size: 16px;
    text-align: left;
  }
}
