
@import '@/sass/custom/_settings.scss';
@import '@/variables.scss';

.top-header {
  &__wrapper {
    padding: 120px 0;
  }
  &__title {
    font-weight: $semibold;
    font-size: 64px;
    line-height: 64px;
    text-align: center;
    color: $petroleum;
  }
  &__caption {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: $darkGrey;
    margin: 40px auto 0;
    max-width: 900px;
  }
}

.thank-you-content {
  &__wrapper {
    margin: 80px 0;
    color: $darkerGrey;
  }

  &__header {
    font-size: 48px;
    line-height: 48px;
    font-weight: $semibold;
  }

  &__blocks {
    display: flex;
    flex-flow: row wrap;
    .thank-you-content__block {
      &:nth-child(1) {
        flex: 0 0 100%;
      }
      &:nth-child(2) {
        flex: 0 0 calc(50% - 40px);
        margin-right: 40px;
      }
      &:nth-child(3) {
        flex: 0 0 calc(50% - 40px);
        margin-left: 40px;
      }
    }
  }

  &__block-header {
    margin: 40px 0 13px 0;
    font-size: 24px;
    line-height: 28px;
    font-weight: $semibold;
  }

  &__data-block-row {
    display: flex;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }

    &__title,
    &__text {
      font-size: 18px;
      line-height: 24px;
      word-break: break-word;
    }

    &__title {
      flex: 0 0 190px;
    }
  }

  &__list {
    list-style: decimal inside;
    margin-top: 40px;
    color: $darkerGrey;

    &__sub-list {
      color: $darkGrey;
      margin-top: 20px;

      .thank-you-content__list-item {
        margin-bottom: 10px;
      }
    }
  }

  &__list-item {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $tablet) {
  .top-header__wrapper {
    padding: 80px 0;
  }

  .thank-you-content {
    &__blocks {
      .thank-you-content__block {
        &:nth-child(2) {
          flex: 0 0 calc(50% - 15px);
          margin-right: 15px;
        }
        &:nth-child(3) {
          flex: 0 0 calc(50% - 15px);
          margin-left: 15px;
        }
      }
    }

    &__data-block-row {
      &__title,
      &__text {
        font-size: 16px;
        line-height: 18px;
      }

      &__title {
        flex: 0 0 142px;
      }
    }

    &__list-item {
      line-height: 24px;
    }
  }
}

@media screen and (max-width: $mobile-l) {
  .top-header {
    &__wrapper {
      padding: 40px 0;
    }
    &__title {
      font-size: 32px;
      line-height: 38px;
    }
    &__caption {
      font-size: 16px;
      line-height: 22px;
      margin-top: 20px;
    }
  }

  .thank-you-content {
    &__wrapper {
      margin: 40px 0;
    }

    &__header {
      font-size: 24px;
      line-height: 24px;
    }

    &__blocks {
      .thank-you-content__block {
        &:nth-child(2),
        &:nth-child(3) {
          flex: 0 0 100%;
          margin: 0;
        }
      }
    }

    &__block-header {
      margin: 40px 0 18px 0;
      font-size: 18px;
      line-height: 21px;
    }

    &__data-block-row {
      &__title,
      &__text {
        font-size: 16px;
        line-height: 18px;
      }

      &__title {
        flex: 0 0 128px;
      }
    }

    &__list {
      margin-top: 20px;
    }

    &__list-item {
      font-size: 16px;
      line-height: 18px;
    }
  }
}
