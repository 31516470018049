
@import '@/sass/components/common/ing.orange.juice';
.radio-container {
  display: flex;
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  user-select: none;
  font-weight: normal;
  padding: 3px;
  clear: left;
  &--focus {
    .radio-checkmark {
      border: 1px solid $Primary-Orange;
    }
  }

  &__label {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }

  &__checkmark {
    display: flex;
    flex: 0 1 auto;
    margin-right: 8px;
    align-items: center;
  }
}

/* Hide the browser's default radio button */
.radio-container input.form-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

/* Create a custom radio button */
.radio-checkmark {
  position: relative;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #9b9b9b;
  box-sizing: border-box;

  &--error {
    border-color: #d64245;
  }
}

/* On mouse-over, add a grey background color */
.radio-container:hover input.form-radio ~ .radio-checkmark {
  background-color: white;
}

/* When the radio button is checked, add a lendico color background */
.radio-container input.form-radio:checked ~ .radio-checkmark {
  background-color: white;
  border-color: #2e2e2e;
}

.radio-checkmark {
  &:after {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
    position: absolute;
    display: none;
  }

  &--checked::after {
    display: block;
  }
}

/*end custom radio button */
