
@import '@/sass/custom/_settings.scss';
@import '@/sass/custom/_variables.scss';
@import '@/variables.scss';
.sme-header {
  margin-bottom: 16px;
}
.sme-description {
  margin-top: 10px;
}
.employees-number-title-container {
  margin-top: 24px;
}
.field-label {
  display: inline-flex;
  align-items: baseline;
}
em {
  color: $ing-dark-gray;
}
.company-revenues-title-container {
  margin-top: 16px;
}
.company-assets-title-container {
  margin-top: 16px;
}
.sme-value {
  font-weight: normal;
  font-size: 14px;
}
.mllf-info {
  font-size: 14px;
}
@media (max-width: $tablet) {
  .employees-number-title-container,
  .company-revenues-title-container,
  .company-assets-title-container {
    max-width: 80%;
  }
}
