
@import '@/variables.scss';

.hero-skeleton {
  padding: 80px 115px;
  justify-content: center;
  @media screen and (max-width: $desktop-l) {
    padding: 40px 40px 0 40px;
  }

  @media screen and (max-width: $tablet) {
    padding: 40px 20px 0 20px;
  }

  @media screen and (max-width: $mobile-l) {
    padding: 0 20px;
    flex-direction: column;
    align-items: center;
  }

  &__section {
    margin-top: 24px;
  }

  .block-container {
    @media screen and (max-width: $mobile-l) {
      margin: 0 -20px 24px -20px;
    }
  }
}

.form-skeleton {
  &__section {
    margin-bottom: 48px;
  }

  &__left-col {
    padding-right: 30px;

    @media screen and (max-width: $mobile-l) {
      padding-right: 0;
    }
  }

  &__right-col {
    padding-left: 30px;

    @media screen and (max-width: $mobile-l) {
      padding-left: 0;
    }
  }
}
