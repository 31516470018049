
@import '@/sass/components/common/ing.orange.juice';
.ing-text-input {
  position: relative;
  align-items: center;
  width: 100%;
  font-family: inherit;
  padding-bottom: px2rem(33px);
  &__element {
    font-family: inherit;
    font-weight: bold;
    background: $Primary-white;
    padding: 8px 12px;
    border: 1px solid $Primary-Grey400;
    box-shadow: inset 0 2px 2px $Primary-Grey200;
    border-radius: 4px;
    width: 100%;
    outline: none;
    box-sizing: border-box;
    color: $Primary-Grey600;
    height: px2rem(40px);
    font-size: px2rem(16px);
    line-height: px2rem(24px);
    &:focus {
      border: 3px solid $Secondary-Indigo500;
      padding: 6px 10px;
    }
    &:disabled {
      border: 1px solid $Primary-Grey200;
      background: $Primary-Grey100;
    }
    &--textarea {
      height: px2rem(80px);
    }
    &--error {
      border: 3px solid $Functional-Minus500;
      padding: 6px 10px;
    }
    &--xs {
      width: 96px;
    }
    &--s {
      width: 120px;
    }
  }
  &__label {
    display: block;
    font-family: inherit;
    color: $Primary-Grey600;
    font-weight: 400;
    font-size: px2rem(16px);
    line-height: px2rem(24px);
  }
  &__caption {
    font-family: inherit;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    margin-bottom: 4px;
    color: $Primary-Grey400;
    text-align: left;
    width: 100%;
  }
}
