
.account-details {
  margin-top: 10px;

  &--no-margin {
    margin-top: 0;
  }

  &__header {
    font-size: 19px;
    line-height: 27px;
    margin-bottom: 4px;
    font-weight: bold;
  }
  &__sub-header {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
