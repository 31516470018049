
@import '@/sass/custom/_settings.scss';
@import '@/variables.scss';

.error-wrapper {
  font-size: 16px;
  line-height: 24px;
  .general-error-container {
    margin-block: 0 40px;
  }
}
.button {
  margin-top: 50px;
}
