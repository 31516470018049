
.error-container {
  height: 25px;
}
.validation {
  margin-bottom: 0;
}
.is-readonly input {
  cursor: not-allowed;
}
.form-group {
  textarea.form-control {
    transition: max-height 0.25s ease-in-out;
    max-height: 36px;
    height: auto;
    line-height: 14px;

    &:focus,
    &.has-value {
      max-height: 78px;
    }
  }
}
