
@import '@/sass/components/common/ing.orange.juice';
.radio-container {
  font-family: inherit;
  display: flex;
  cursor: pointer;
  font-size: px2rem(16px);
  line-height: px2rem(24px);
  user-select: none;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  border: 3px solid transparent;
  padding: 3px;
  &--focus {
    border: 3px solid $Secondary-Indigo500;
    padding: 3px;
    border-radius: 18px 4px 4px 18px;
  }
  &__label {
    font-family: inherit;
    display: flex;
    flex: 1 1 auto;
    color: $Primary-Grey600;
    align-items: center;
  }
  &__checkmark {
    display: flex;
    flex: 0 1 auto;
    margin-right: 8px;
    align-items: center;
  }
  &__status {
    font-size: px2rem(16px);
    margin: 6px 0 0;
  }
  &--error {
    border: 3px solid $Functional-Minus500;
    padding: 3px;
    border-radius: 18px 4px 4px 18px;
  }
}

/* Hide the browser's default radio button */
.radio-container input.form-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

/* Create a custom radio button */
.radio-checkmark {
  position: relative;
  height: px2rem(24px);
  width: px2rem(24px);
  background-color: $Primary-white;
  border-radius: 50%;
  border: 1px solid $Primary-Grey400;
  box-sizing: border-box;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input.form-radio ~ .radio-checkmark {
  background-color: $Primary-white;
}

/* When the radio button is checked, add a lendico color background */
.radio-container input.form-radio:checked ~ .radio-checkmark {
  background-color: $Primary-white;
  border-color: $Primary-Grey400;
}

.radio-checkmark:after {
  top: px2rem(4px);
  left: px2rem(4px);
  height: px2rem(14px);
  width: px2rem(14px);
  border-radius: 50%;
  content: '';
  position: absolute;
  display: none;
}

.radio-checkmark--checked::after {
  display: block;
  background: $Primary-Orange;
}

/*end custom radio button */
