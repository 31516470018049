
@import '@/variables.scss';

.ing-error {
  margin-top: 12px;
  justify-content: center;
}
@media screen and (max-width: $mobile-l) {
  .ing-error {
    margin-top: 11px;
    font-size: 12px;
    line-height: 14px;
    height: auto;
    text-align: left;
  }
}
