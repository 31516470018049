.icon-svg{
  svg{
    width: 80px;
    height: 80px;
  }
  svg *{
    fill: $petroleum;
  }
  svg .circular_border{
    fill: $grey;
  }
  &.dark, &.image{
    svg .circular_border{
      fill: $white;
    }
  }
  &.light{
    svg .circular_border{
      fill: $darkerGrey;
    }
  }
  &.m svg{
    width: 74px;
    height: 74px;
  }
  &.sm svg{
    width: 50px;
    height: 50px;
  }
}
