
@import '@/variables.scss';

.header-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 40px;
}
.title {
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 38px;
  letter-spacing: normal;
  color: #29bdba;
  margin-left: 12px;
  margin-bottom: 18px;
  margin-top: 0;
}
@media only screen and (max-width: $mobile-l) {
  .header-container {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 25px;
  }
  .title {
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-left: 0;
    margin-bottom: 0;
  }
}
