
@import '@/sass/components/common/ing.orange.juice';
.ing-button {
  background-color: $Primary-Orange;
  color: $Primary-white;
  border-radius: 8px;
  height: px2rem(44px);
  font-weight: 700;
  font-size: px2rem(19px);
  line-height: px2rem(19px);
  text-align: center;
  cursor: default;
  outline: none;
  border: 3px solid $Primary-Orange;
  padding: px2rem(8px) px2rem(16px);
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  @include mq-size(s) {
    width: auto;
  }

  &:disabled {
    cursor: default;
  }
  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 3px $Primary-white;
  }
  &.button-secondary {
    background-color: $Secondary-Indigo500;
    border-color: $Secondary-Indigo500;
  }
  &.outlined {
    color: $Secondary-Indigo500;
    background-color: inherit;
    border-color: $Secondary-Indigo500;
    &:hover {
      background-color: $Secondary-Indigo500;
      box-shadow: none;
      color: $Primary-white;
    }
  }
  &.small {
    height: unset;
    font-weight: 700;
    font-size: px2rem(14px);
    line-height: px2rem(16px);
    padding: px2rem(6px) px2rem(10px);
  }
  &.align-right {
    position: absolute;
    top: 14px;
    right: 16px;
    width: auto;
    @include mq-size(m) {
      top: 22px;
      right: 24px;
    }
  }
}
