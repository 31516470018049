
.identification-issuing-authority-wrapper {
  position: relative;
  line-height: 1.75;
}
.identification-issuing-authority-tooltip {
  position: absolute;
  left: 170px;
  top: 10px;
  transition: all 0.2s ease;
  &.on-input-focus {
    top: -12px;
    left: 128px;
  }
}
