
@import '@/sass/components/common/ing.orange.juice';
.partner-success-page {
  .form-section {
    &__unordered-list {
      margin-top: px2rem(18px);
      padding-bottom: 0px;
      margin-left: px2rem(25px);
      font-size: px2rem(18px);
      line-height: px2rem(27px);
      &__item {
        &::marker {
          font-size: px2rem(14px);
        }
      }
    }

    &__para {
      font-size: px2rem(18px);
      margin: 19px 0 27px;
      padding-bottom: 0;
      &__title {
        font-size: px2rem(28px);
        color: $Primary-Orange;
        padding-bottom: px2rem(3px);
      }
    }
  }
  .data-section {
    display: flex;
    column-gap: 3%;
    row-gap: 48px;
    flex-wrap: wrap;
    padding-bottom: 48px;
    &:last-child {
      padding-bottom: 0;
    }
    &__list {
      flex-basis: 100%;
      @include mq-size(xl) {
        flex-basis: 48.5%;
      }
    }
    &__address {
      white-space: pre-line;
    }
    &__row {
      display: flex;
      column-gap: 3%;
      flex-wrap: wrap;
      @include mq-size(s) {
        column-gap: 3%;
      }
      @include mq-size(xl) {
        column-gap: 5%;
      }
      &--divider {
        padding-top: 24px;
      }
      &--header {
        padding-bottom: 8px;
        @include mq-size(s) {
          text-align: right;
        }
      }
      &--header#{&} span {
        font-size: 19px;
        line-height: 28px;
        font-weight: 700;
      }
      span {
        font-size: 16px;
        line-height: 24px;
        flex-basis: 100%;
        word-break: break-all;
        @include mq-size(s) {
          flex-basis: 48.5%;
        }
        @include mq-size(xl) {
          flex-basis: 47.5%;
        }
        &:first-child {
          @include mq-size(s) {
            text-align: right;
          }
        }
        &:last-child {
          font-weight: 700;
          padding-bottom: 5px;
          min-height: 24px;
          @include mq-size(s) {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
