<template>
  <div class="header-container">
    <img
      alt="Percentage Icon"
      class="percentage-icon-size"
      src="/ing_percentage_borderless.png"
      data-unit-test="ing-offer-details-icon"
    />
    <div class="text-container" data-unit-test="ing-offer-details-header">
      <h2>{{ $t('signersMissingInfo.formPage.offerConditions.title.h2') }}</h2>
      <p>{{ $t('signersMissingInfo.formPage.offerConditions.title.p') }}</p>
    </div>
  </div>
</template>

<style scoped>
  .percentage-icon-size {
    width: 48px;
    height: 48px;
  }
</style>
