$font_path: '~@/assets/fonts/';

@font-face {
    font-family: "ING Me";
    src: url($font_path + 'INGMeWeb-Regular.woff2') format('woff2'),
    url($font_path + 'INGMeWeb-Regular.woff') format('woff'),
    url($font_path + 'INGMeWeb-Regular.ttf') format('font-type');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ING Me";
    src: url($font_path + 'INGMeWeb-Bold.woff2') format('woff2'),
    url($font_path + 'INGMeWeb-Bold.woff') format('woff'),
    url($font_path + 'INGMeWeb-Bold.ttf') format('font-type');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "ING Me";
    src: url($font_path + 'INGMeWeb-Italic.woff2') format('woff2'),
    url($font_path + 'INGMeWeb-Italic.woff') format('woff'),
    url($font_path + 'INGMeWeb-Italic.ttf') format('font-type');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "ING Me";
    src: url($font_path + 'INGMeWeb-BoldItalic.woff2') format('woff2'),
    url($font_path + 'INGMeWeb-BoldItalic.woff') format('woff'),
    url($font_path + 'INGMeWeb-BoldItalic.ttf') format('font-type');
    font-weight: bold;
    font-style: italic;
}

.hero-copy {
    max-width: 950px;
    h1, h2 {
        color: $white;
        @include font-size(60);
        font-weight: $light;
        strong {
            font-weight: $bold;
        }
    }
}
.boxed-content,
.text-block {
    // display: table;
    display: block;
    textarea.snippet {
        position: relative;
        width: 100%;
        display: block;
        min-height: 200px;
        background: #eee;
        padding: 10px;
        color: black;
    }
    h1 {
        color: $darkerGrey;
    }
    h2 {
        @include font-size(40);
        color: $darkerGrey;
        font-weight: $bold;
        text-transform: uppercase;
        //margin: 20px 0 30px;
        margin: 0 0 30px;
    }
    h2.quote {
        @include font-size(40);
        font-weight: $light;
        text-transform: none;
        color: $darkerGrey;
        margin: 0 0 30px;
        line-height: 1;
        &:after,
        &:before {
            content: 'ʻʻ';
            @include font-size(40);
            font-weight: $semibold;
            color: $petroleum;
            font-family: "Source Sans Pro", sans-serif;
            line-height: 1;
        }
        &:after {
            content: 'ʼʼ';
        }
        &.small {
            @include font-size(20);
            line-height: 1;
            &:after,
            &:before {
                @include font-size(20);
                line-height: 1;
            }
        }
    }
    h2.light {
        @include font-size(40);
        font-weight: $light;
        text-transform: none;
        color: $darkerGrey;
        margin: 0 0 30px;
        line-height: 1;
        &.small {
            @include font-size(20);
            line-height: 1;
        }
    }
    h3 {
        @include font-size(24);
        color: $petroleum;
        font-weight: $bold;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
    }
    h4 {
        @include font-size(18);
        color: $darkerGrey;
        font-weight: $bold;
        text-transform: uppercase;
        margin: 10px 0 10px;
    }
    h5 {}
    h6 {}
    p {
        @include font-size(14);
        color: $grey;
        margin: 0 0 10px;
        font-weight: $normal;
        big {
            color: $petroleum;
        }
        .big,
        big {
            @include font-size(24);
            font-weight: $bold;
            margin: 0 20px;
        }
        strong {
            font-weight: $bold;
        }
        a {
            color: $petroleum;
            font-size: inherit;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    a,
    a.cta {
        @include font-size(14);
    }
    ol,
    ul {
        position: relative;
    }
    ul.bullet-list li {
        @include font-size(14);
        color: $grey;
        list-style: none;
        padding: 1px 0 0 30px;
        line-height: 1.5;
        position: relative;
        margin: 5px 0;
        font-weight: $normal;
        border: none;
        text-align: left;
        cursor: default;
        text-transform: none;
        &:before {
            content: '◦';
            font-size: 35px;
            font-weight: $light;
            line-height: 22px;
            color: $petroleum;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:hover{
          @include font-size(14);
          color: $grey;
          list-style: none;
          padding: 1px 0 0 30px;
          line-height: 1.5;
          position: relative;
          margin: 5px 0;
          font-weight: $normal;
          border: none;
          text-align: left;
        }
    }

    ol{
      counter-reset: list;
    }
    ol li {
      list-style: none;
      position: relative;
      &:before {
        counter-increment: list;
        content: counter(list) ".";
        position: relative;
        margin-right: 7px;
        display: inline-block;
      }
    }

    li a{
      color: $petroleum;
      &:hover{
        color: $darkNavy;
      }
    }

    &--big {
        p {
            @include font-size(18);
            margin: 0 0 20px;
            .big,
            big {
                @include font-size(24);
            }
            a,
            a.cta {
                @include font-size(24);
            }
            a {
                color: $petroleum;
                @include font-size(18);
                text-decoration: underline;
                &:hover {
                    color: $darkNavy;
                }
            }
        }
        a,
        a.cta {
            @include font-size(18);
        }
    }
    blockquote {
        background: #f9f9f9;
        border-left: 10px solid #ccc;
        margin: 1.5em 10px;
        padding: 0.5em 10px;
        quotes: "\201C""\201D""\2018""\2019";
    }
    blockquote:before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
    blockquote p {
        display: inline;
    }
    dl,
    table {
        width: 100%;
        margin-bottom: 0;
        @extend .transition;
        dd,
        dt {
            width: 100%;
            display: table;
            vertical-align: middle;
            @extend .transition;
        }
        dd {
            display: none;
            @extend .transition;
        }
        .expandable {
            width: 100%;
            display: block;
            position: relative;
        }
        .expandable.open dd {
            display: block;
        }
        .expandable dt {
            padding-left: 10px;
            position: relative;
            cursor: pointer;
            margin-bottom: 3px;
            @extend .transition;
        }
        .expandable dl {
            margin-top: -3px;
        }
        .expandable {
            > dt:before {
                content: '+';
                color: $petroleum;
                @include font-size(14);
                width: 10px;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -7px;
                height: 20px;
                text-align: left;
            }
            &.open > dt:before {
                content: '-';
            }
        }
        .vertical-padding div,
        .vertical-padding td {
            padding: 10px 0;
        }
        .bottom-border,
        .top-border {
            border-color: $darkerGrey;
            &.focus {
                border-bottom-color: $petroleum;
            }
            &.error {
                border-bottom-color: $validation;
            }
        }
        div,
        td {
            @include font-size(14);
            @include bp(mobile){
                display: block;
                p {
                    text-align: center !important;
                }
            }
            color: $grey;
            font-weight: $normal;
            display: table-cell;
            text-align: right;
            vertical-align: middle;
            p {
                margin-bottom: 0;
            }
            &.chf {
                width: 25px;
            }
            strong {
                @include font-size(14);
                font-weight: $semibold;
                color: $darkerGrey;
            }
            text-align: right;
            &:first-child {
                text-align: left;
            }
        }
        div input,
        td input {
            width: 100%;
            border: none;
            background: none;
            display: block;
            position: relative;
            text-align: right;
            color: $darkerGrey;
            font-weight: $semibold;
        }
        div label,
        td label {
            width: 100%;
            display: block;
            position: relative;
            text-align: right;
            color: $grey;
            font-weight: $semibold;
        }
        tbody {
            display: table;
            position: relative;
            margin: 0 auto;
        }
    }
}
.boxed-content {
    border: solid 2px $petroleum;
    display: block;
    h3 {
        color: $darkerGrey;
        text-transform: none;
    }
    &.greyed-out {
        border: solid 2px $grey;
        h3 {
            color: $grey;
            font-weight: $semibold;
        }
    }
    &.dark {
        h3 {
            color: $white;
            text-transform: none;
        }
        &.greyed-out {
            border: solid 2px $grey;
            h3 {
                color: $grey;
            }
        }
    }
}
.dark-section .boxed-content,
.dark-section .text-block {
    h1,
    h2,
    h3,
    h4 {
        color: $white;
    }
}
.dark-section .boxed-content {
    border: solid 2px $petroleum;
    display: block;
    h3 {
        color: $white;
        text-transform: none;
    }
    &.greyed-out {
        border: solid 2px $grey;
        h3 {
            color: $grey;
        }
    }
}
.light-section .text-block {
    h1,
    h2,
    h4 {
        color: $darkerGrey;
    }
}
.light-section .boxed-content,
.white-section .boxed-content {
    border: solid 2px $petroleum;
    display: block;
    h3 {
        color: $darkerGrey;
        text-transform: none;
    }
    &.greyed-out {
        border: solid 2px $grey;
        h3 {
            color: $grey;
            font-weight: $semibold;
        }
    }
}
.white-section .text-block {
    h1,
    h2,
    h4 {
        color: $darkerGrey;
    }
}
.dark-section {
    .hero-copy {
        h2 {
            color: $white;
        }
    }
}
.light-section {
    .hero-copy {
        h2 {
            color: $darkerGrey;
        }
    }

}
.white-section {
    .hero-copy {
        h2 {
            color: $darkerGrey;
        }
    }
}

.white-section, .light-section{
  p{
      color: $darkerGrey;
  }
  .boxed-content ul.bullet-list li, .text-block ul.bullet-list li{
      color: $darkerGrey;
  }
}
.horizontal-list {
    .boxed-content,
    .text-block {
        display: block;
    }
    &.equal-height .equal-height__item {
        display: block;
        position: relative;
    }
    .list-img {
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        &.max-width {
            max-width: 148px;
        }
    }
    p {
        @include font-size(14);
        strong {
            font-weight: $bold;
        }
    }
}
.vertical-list {
    hr {
        //padding: 0 !important;
        margin-left: 40px !important;
        @include bp(mobile) {
            margin-left: 20px !important;
        }
    }
    // .grid__item {
    //     vertical-align: middle !important;
    // }
    @include bp(mobile) {
        .grid__item {
            vertical-align: top !important;
        }
    }
    .list-img {
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        &.max-width {
            max-width: 148px;
        }
    }
    p {
        @include font-size(14);
        strong {
            font-weight: $bold;
        }
    }
}
.testimonials {
    p {
        color: $darkerGrey;
        margin: 20px 0 0;
        strong {
            font-weight: $semibold;
        }
    }
}
.dark-section {
    .testimonials {
        p {
            color: $white;
        }
    }
}

big,
h1,
h2,
h3,
h4,
h5,
h6,
i,
label,
p,
small,
span,
strong {
    &.striked,
    .striked {
        text-decoration: line-through !important;
    }
    &.underline,
    .underline{
      text-decoration: underline !important;
    }
    &.bold,
    .bold {
        font-weight: $bold !important;
    }
    &.semibold,
    .semibold {
        font-weight: $semibold !important;
    }
    &.normal,
    .normal {
        font-weight: $normal !important;
    }
    &.light,
    .light {
        font-weight: $light !important;
    }
    &.italic,
    .italic,
    i {
        font-style: italic !important;
    }
    &.white,
    .white {
        color: $white !important;
    }
    &.green,
    .green {
        color: $petroleum !important;
    }
    &.dark,
    .dark {
        color: $darkerGrey !important;
    }
    &.grey,
    .grey {
        color: $grey !important;
    }
    &.validation,
    .validation {
      color: $validation !important;
    }
    &.red,
    .red {
      color: $red !important;
    }
    &.uppercase,
    .uppercase{
      // text-transform: uppercase !important;
    }
}

.ing-wrapper {
  * {
    font-family: $ing-main-font;
  }
    .ing-lendico-header-text {
        font-size: 16px;
    }

}
