
@import 'src/sass/components/common/ing.orange.juice.scss';

.pdf-icon {
  height: 56px;
  width: 40px;
}
.contract-container {
  padding-bottom: 40px;
}
.contract-icon {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 0 0 20px 10px;
}
.contract-text {
  margin-left: 10px;
  font-size: 19px;
  font-weight: 700;
}
.contract-actions {
  display: flex;
  flex-direction: row;
}
.unavailable-contract {
  font-size: 16px;
  color: $Functional-Minus500;
}
.download-link {
  height: 36px;
  margin-left: 10px;
}
.download-button {
  font-size: 16px;
}
