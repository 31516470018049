<template>
  <div class="submit-container">
    <button
      type="button"
      class="button sm no-outline"
      @click="$emit('cancel-button-clicked')"
    >
      « {{ $t('signersMissingInfo.formPage.cancel') }}
    </button>
    <button
      type="button"
      class="button sm"
      @click="$emit('submit-button-clicked')"
    >
      {{ $t('signersMissingInfo.formPage.save') }}
    </button>
  </div>
</template>

<style scoped>
  .submit-container {
    display: flex;
    justify-content: space-between;
  }
</style>
