
@import '@/sass/components/common/ing.orange.juice';
.ing-check-box {
  padding-bottom: px2rem(33px);
  &__container {
    display: flex;
    column-gap: 10px;
    font-weight: 700;
    position: relative;
    cursor: pointer;
    padding: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 3px solid transparent;
    border-radius: 4px;
    &--error {
      border: 3px solid $Functional-Minus500;
    }
    &--focus {
      border: 3px solid $Secondary-Indigo500;
    }
  }
  &__element {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      + .ing-check-box__checkmark {
        &:after {
          display: block;
        }
      }
    }
  }
  &__checkmark {
    position: relative;
    left: 3px;
    height: px2rem(24px);
    min-width: px2rem(24px);
    background-color: $Primary-white;
    border: 1px solid $Primary-Grey400;
    border-radius: 4px;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 24%;
      top: 46%;
      width: px2rem(10px);
      height: px2rem(15px);
      border: 0 solid $Primary-Orange;
      border-right-width: px2rem(3px);
      border-bottom-width: px2rem(3px);
      border-radius: 0 0 2px 0;
      transform: rotate(45deg) translate(-50%, -50%);
    }
  }
  &__label {
    font-size: px2rem(16px);
    .link {
      color: $Secondary-Indigo500;
      text-decoration: underline;
    }
  }
}
