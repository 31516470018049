
@import '../../../../sass/custom/settings';
@import '../../../../sass/custom/variables';
h3 {
  font-size: 19px;
  font-weight: bold;
}
p {
  font-size: 14px;
  line-height: 21px;
  color: $darkerGrey;
}
.missing-ubo-sentence {
  padding: 40px 40px 0 0;
  a {
    color: $petroleum;
  }
}
.overview {
  font-size: 16px;
  line-height: 20px;
  color: $ing-gray;
  margin-bottom: 40px;
}
.roles-wrapper {
  margin-bottom: 40px;
  margin-top: 10px;
  color: $ing-gray;
}
.signer-header-wrapper {
  display: flex;
  justify-content: space-between;
}
.signer-column-container {
  flex-grow: 1;
  word-break: break-all;
}

.signer-information-container {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.overview-container {
  &.alert {
    border: solid 1px $red;
  }
  &.dark-background {
    border: none;
    background-color: $ing-signer-section-background;
    &.alert {
      border: solid 1px $red;
    }
  }
}
@media (max-width: 1024px) {
  .signer-information-container {
    flex-direction: column;
  }
}
@media (max-width: 476px) {
  .signer-header-wrapper {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .roles-wrapper {
    margin-bottom: 10px;
  }
}
