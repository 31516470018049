
@import '@/variables.scss';

.company-information-container {
  margin-bottom: 50px;
}

.first-column {
  margin-top: 17px;
  padding-right: 3.5%;
}
.second-column {
  vertical-align: top;
  margin-top: 17px;
  padding-left: 3.5%;
}
@media (max-width: $desktop-s) {
  .first-column {
    margin-top: 17px;
    padding-right: 3.5%;

    fieldset:nth-child(2) {
      margin-top: 10px;
    }
  }
  .second-column {
    margin-top: 5px;
    padding-left: 3.5%;
  }
}
@media (max-width: $mobile-l) {
  .company-information-container {
    margin-bottom: 12px;
  }
  .first-column {
    margin-top: 0;
    padding-right: 0;

    fieldset:nth-child(2) {
      margin-top: 0;
    }
  }
  .second-column {
    margin-top: 0;
    padding-left: 0;
  }
}
