
@import '@/variables.scss';

.explanation {
  font-size: 18px;
  line-height: 24px;
  color: #2e2e2e;
}

@media only screen and (max-width: $mobile-l) {
  .explanation {
    font-size: 16px;
    line-height: 18px;
  }
}
