
@import '@/variables.scss';

.radio {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    min-height: 34px;

    > label.radio-container {
      margin: 0;
      font-size: 16px;
    }
  }

  &__label {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    color: #2e2e2e;
  }
}

.error-container {
  height: 20px;
  padding-left: 193px;
  width: 100%;
  margin-top: -4px;
  margin-bottom: 9px;
}
.validation {
  margin-bottom: 0;
  margin-top: 0;
}

@media (max-width: $desktop-s) {
  .radio {
    &__wrapper {
      justify-content: flex-start;
      > label.radio-container {
        flex: 0.35;
      }
    }
    &__label {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }

  .error-container {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 5px;
  }
  .validation {
    margin-top: 0;
  }
}

@media (max-width: $tablet) {
  .radio {
    &__wrapper {
      > label.radio-container {
        flex: 0.5;
      }
    }
  }
}

@media (max-width: $mobile-l) {
  .radio {
    &__wrapper {
      flex-direction: column;
      align-items: flex-start;
      > label.radio-container {
        flex: auto;

        &:last-child {
          margin-top: 15px;
        }
      }
    }
  }
}
