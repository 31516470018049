
@import '@/sass/components/common/ing.orange.juice';
.ing-native-select {
  font-family: inherit;
  position: relative;
  background: $Primary-white;
  color: $Primary-Grey600;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &__icon,
  &__element {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    font-family: inherit;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 11px 13px;
    z-index: 0;
  }

  &__element {
    color: $Primary-Grey600;
    font-weight: bold;
    padding: 8px 44px 8px 12px;
    background: transparent;
    outline: none;
    border-radius: 4px;
    border: 1px solid $Primary-Grey400;
    box-shadow: inset 0 2px 2px $Primary-Grey200;
    box-sizing: border-box;
    width: 100%;
    height: px2rem(40px);
    font-size: px2rem(16px);
    line-height: px2rem(24px);
    font-family: inherit;
    position: relative;
    z-index: 1;

    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
    /* For IE10 */
    &::-ms-expand {
      display: none;
    }

    &:focus {
      border: 3px solid $Secondary-Indigo500;
      padding: 6px 42px 6px 10px;
    }
    &:disabled {
      border: 1px solid $Primary-Grey200;
      background: $Primary-Grey100;
    }
    &--error {
      border: 3px solid $Functional-Minus500;
      padding: 6px 42px 6px 10px;
    }
  }
}
