
@import '../../sass/custom/_settings.scss';
.signers-tooltip-wrapper {
  position: relative;
}
.signers-tooltip-message.active {
  visibility: visible;
}
.signers-tooltip-message {
  visibility: hidden;
  position: absolute;
  padding: 20px;
  font-size: 12px;
  color: $mediumGrey;
  background: white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.signers-tooltip-arrow {
  position: absolute;
  height: 0;
  width: 0;
  border: 8px solid transparent;
  border-top: 8px solid white;
  left: 10px;
  top: -15px;
  z-index: 1;
}
.signers-tooltip-info-icon {
  margin-left: 10px;
  :deep(svg) {
    vertical-align: text-top;
    width: 16px;
    height: 16px;
  }
  display: inline-block;
}
