button,
input,
label,
option,
select,
textarea {
    cursor: text;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: 0;
}
html {
    font-size: 16px !important;
    &.overflow-hidden {
        overflow: hidden;
    }
}
*,
a,
a:focus {
    outline: none !important;
}
//Setting transition classes
.transition {
    @include css3( 'transition', 'all 240ms ease-in-out');
}
.no-transition {
    @include css3( 'transition', 'all 0s 0s !important');
}
.max-width {
    position: relative;
    display: block;
    //overflow: hidden;
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
}
section {
    position: relative;
    display: block;
}
.display {
    &--table {
        position: relative;
        display: table;
    }
    &--table-cell {
        position: relative;
        display: table-cell;
    }
    &--inline-block {
        position: relative;
        display: inline-block;
    }
    &--inline-table {
        position: relative;
        display: inline-table;
    }
    &--block {
        position: relative;
        display: block;
    }
    &--none {
        display: none;
    }
}
.font-size0 {
    font-size: 0;
}
.column {
    position: relative;
    display: inline-block;
    vertical-align: top;
}
.clear {
    clear: none;
    &--both {
        clear: both !important;
    }
    &--left {
        clear: left !important;
    }
    &--right {
        clear: right !important;
    }
}
.float {
    &--left {
        float: left !important;
    }
    &--right {
        float: right !important;
    }
    &--none {
        float: none !important;
    }
}
.text-align {
    &--left {
        text-align: left !important;
    }
    &--right {
        text-align: right !important;
    }
    &--justify {
        text-align: justify !important;
    }
    &--center {
        text-align: center !important;
    }
}
.horizontal-align {
    &--center {
        display: block;
        margin: 0 auto;
        text-align: center;
    }
}
.vertical-align {
    &--top {
        vertical-align: top !important;
    }
    &--middle {
        vertical-align: middle !important;
    }
    &--baseline {
        vertical-align: baseline !important;
    }
    &--bottom {
        vertical-align: bottom !important;
    }
}
.border-radius--circular {
    @include border-radius(50%);
}
.position {
    &--relative {
      //changed relative positioning to flex-box so that the footer
      flex: 1 0 auto;
    }
    &--fixed {
        position: fixed !important;
    }
    &--absolute {
        position: absolute !important;
        top: 0;
        left: 0;
        display: block;
        &-right {
            position: absolute !important;
            top: 0;
            right: 0;
            display: block;
        }
    }
}
.multicolumn {
    @include bp(desktop) {
        -webkit-column-count: 2;
        /* Chrome, Safari, Opera */
        -moz-column-count: 2;
        /* Firefox */
        column-count: 2;
    }
    @include bp(mobile) {
        -webkit-column-count: 1;
        /* Chrome, Safari, Opera */
        -moz-column-count: 1;
        /* Firefox */
        column-count: 1;
    }
}
/** Grid wrapper */
.grid {
    margin-left: -40px;
    list-style: none;
    margin-bottom: 0;
    font-size: 0;
    .grid__item {
        display: inline-block;
        padding-left: 40px;
        vertical-align: top;
        @include font-size(16);
        margin: 0;
    }
    &.half {
        margin-left: -20px;
        .grid__item {
            padding-left: 20px;
        }
        @include bp(mobile) {
            margin-left: -20px;
            .grid__item {
                padding-left: 20px;
            }
        }
    }
    &.double {
        margin-left: -80px;
        .grid__item {
            padding-left: 80px;
        }
        @include bp(mobile) {
            margin-left: -20px;
            .grid__item {
                padding-left: 20px;
            }
        }
    }
    @include bp(mobile) {
        margin-left: -20px;
        list-style: none;
        margin-bottom: 0;
        & .grid__item,
        &.double .grid__item,
        &.half .grid__item,
        &.none .grid__item {
            display: inline-block;
            padding-left: 20px;
            vertical-align: top;
        }
    }
    &.none {
        margin-left: 0;
        .grid__item {
            padding-left: 0;
        }
        @include bp(mobile) {
            margin-left: -20px;
            .grid__item {
                padding-left: 20px;
            }
        }
    }
}
.grid {
    &.double,
    &.half,
    &.none {
        .grid {
            margin-left: -20px;
            .grid__item {
                padding-left: 20px;
            }
        }
        .grid.doube {
            margin-left: -80px;
            .grid__item {
                padding-left: 80px;
            }
        }
        .grid.none {
            margin-left: 0;
            .grid__item {
                padding-left: 0;
            }
        }
        @include bp(mobile) {
            .grid {
                margin-left: -20px;
                .grid__item {
                    padding-left: 20px;
                }
            }
        }
    }
}
.bottom-border {
    border-bottom: solid 1px $grey;
}
.top-border {
    border-top: solid 1px $grey;
}
.dark-section {
    background: $darkerGrey;
    color: $white;
}
.light-section {
    background: $whiteSmoke;
    color: $darkerGrey;
}
.white-section {
    background: $white;
    color: $darkerGrey;
}
.dark-section.dark-overlay-section {
    background: rgba(#000, .5) !important;
}
.no-wrap {
    white-space: nowrap;
}
.in dt div, .in strong {
    font-size: 1rem !important;
}
.loan-calculator h4 {
    margin-top: 0;
    margin-bottom: 0;
}
.modal-dialog {
    text-transform: none;
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #29bdba; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -60px;
    margin-left: -60px;
}

.loader.small {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #29bdba; /* Blue */
    width: 60px;
    height: 60px;
    left: 50%;
    top: 50%;
    margin-top: -30px;
    margin-left: -30px;
}
.loader.tiny {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #29bdba; /* Blue */
    width: 30px;
    height: 30px;
    left: 50%;
    top: 50%;
    margin-top: -15px;
    margin-left: -15px;
}
.loader.top-right {
    right: 0;
    left: initial;
    top: initial;
}

.loader_layer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=);
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.kv-upload-progress {
    height: 25px;
}

.position-initial {
  position: initial !important;
}

.position-relative {
  position: relative !important;
}

.tooltip-inner {
  min-width: 150px;
  font-size: 0.9em;
}


.documents-state {
  // moved to cdn background-image: url('../assets/images/verification_status_sprite.png');
  background-size: 300% auto;
  background-repeat: no-repeat;
  margin: 0 auto;
  height: 65px;
  width: 120px;
  &.green {
    background-position-x: -240px;
  }
  &.orange {
    background-position-x: -120px;
  }
}

.vue-slider-process {
  background-color: #29bdba !important;
}
.vue-slider-tooltip {
  border-color: #29bdba !important;
  background-color: #29bdba !important;
}

.slider-wrapper label {
  font-size: 14px !important;
}

.slide-down-enter-active, .slide-down-leave-active {
  transition: all .3s ease;
  max-height: 500px;
}
.slide-down-enter, .slide-down-leave-to {
  max-height: 0px;
  overflow: hidden;
}
