
@import '~@/variables.scss';

.offer-expired-error-page-container {
  display: flex;
  flex-grow: 1;
  padding: 80px 35px;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 64px;
  margin-bottom: 20px;
}
.title {
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: #ff6200;
  margin-bottom: 20px;
}
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #808080;
  max-width: 900px;
}
.caption {
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: $tablet) {
  .content-wrapper {
    max-width: 641px;
  }

  .offer-expired-error-page-container {
    padding: 80px 63px;
  }
}

@media screen and (max-width: $mobile-l) {
  .offer-expired-error-page-container {
    padding: 80px 16px;
  }
  .icon {
    width: 50px;
    margin-bottom: 28px;
  }
  .title {
    font-size: 24px;
    line-height: 32px;
    padding: 0 57px;
  }
}
