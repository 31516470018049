
.company-tax-number-wrapper {
  position: relative;
  line-height: 1.75;
}
.company-tax-number-tooltip {
  position: absolute;
  left: 95px;
  top: 10px;
  transition: all 0.2s ease;
  &.on-input-focus {
    top: -12px;
    left: 72px;
  }
}
