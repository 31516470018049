@keyframes zoom-in {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-enter-active {
  animation: fade-in 350ms cubic-bezier(0.4, 0, 0.2, 1);
}
.fade-leave-active {
  animation: fade-in 350ms cubic-bezier(0.4, 0, 0.2, 1) reverse;
}

.zoom-enter-active {
  animation: zoom-in 250ms cubic-bezier(0, 0, 0.2, 1),
  fade-in 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.zoom-leave-active {
  animation: zoom-in 250ms cubic-bezier(0, 0, 0.2, 1) reverse,
  fade-in 250ms cubic-bezier(0.4, 0, 0.2, 1) reverse;
}
