
@import '../../../../sass/custom/settings';
@import '../../../../sass/custom/variables';
p {
  font-size: 14px;
  line-height: 21px;
}
.form-heading {
  margin-bottom: 40px;
}
.client-title-container {
  padding: 40px 0 0 60px;
}
.client-section-container {
  width: 100%;
  padding: 40px;
  &.dark-background {
    background-color: $ing-signer-section-background;
  }
}
.client-submit-section {
  padding-top: 30px;
}
.col-sm-11 {
  margin-left: 4%;
}
.form-container {
  &.alert {
    border: solid 1px $red;
  }
}
.general-error-container {
  min-height: 25px;
  height: fit-content;
}
.general-error-container p {
  text-align: center;
  font-size: 0.8rem;
}
.padding-left-alignment {
  padding-left: 2%;
}
.submit-section-container {
  width: 100%;
  padding: 30px 40px 40px 40px;
}
.identity-section {
  padding: 40px 0px 0 60px;
}
.no-padding-top {
  padding-top: 0;
}
