
@import '@/sass/custom/_variables.scss';
.online-revenue-section {
  margin-top: 40px;
}
.overview__loan-owner-button-container {
  display: flex;
  justify-content: space-between;
}
.overview-container__subsection {
  padding: 20px 40px;
  &:first-child {
    padding-top: 40px;
  }
  &:last-child {
    padding-bottom: 40px;
  }
  &.dark-background {
    padding-top: 40px;
    background-color: $ing-signer-section-background;
  }
}

.edit-button-alignment {
  margin-left: auto;
}
.flex--column {
  flex-direction: column;
}
.overview__iban-subtitle {
  padding-bottom: 16px;
}
p {
  font-size: 14px;
  line-height: 21px;
}
.separator {
  border: 0.5px solid $ing-section-gray;
  width: 90%;
  margin: 20px 40px;
}
.overview-container {
  &.--no-padding {
    padding: 0;
  }
  &.alert {
    border: solid 1px $ing-red;
  }
}
@media (max-width: 990px) {
  .overview__loan-owner-button-container {
    flex-wrap: wrap;
    word-break: break-all;
  }
  .overview__loan-owner-container {
    flex: 1 0 33%;
  }
  .edit-button-alignment {
    flex-basis: 100%;
    padding: 20px 0;
  }
}
@media (max-width: 476px) {
  .overview__loan-owner-button-container {
    flex-direction: column-reverse;
  }
}
