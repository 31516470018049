//
// Tooltips
// --------------------------------------------------


// Base class
.tooltip {
  position: absolute !important;
  z-index: $zindex-tooltip !important;
  display: block !important;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text;
  font-size: $font-size-small !important;

  @include opacity(0);

  &.in     { @include opacity($tooltip-opacity); }
  &.top    { margin-top:  -3px !important; padding: $tooltip-arrow-width 0 !important; }
  &.right  { margin-left:  3px !important; padding: 0 $tooltip-arrow-width !important; }
  &.bottom { margin-top:   3px !important; padding: $tooltip-arrow-width 0 !important; }
  &.left   { margin-left: -3px !important; padding: 0 $tooltip-arrow-width !important; }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width !important;
  padding: 3px 8px !important;
  color: $tooltip-color !important;
  text-align: center !important;
  background-color: $tooltip-bg !important;
  border-radius: $border-radius-base !important;
}

// Arrows
.tooltip-arrow {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  border-color: transparent !important;
  border-style: solid !important;
  padding: 0 !important;
}
// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
.tooltip {
  &.top .tooltip-arrow {
    bottom: 0 !important;
    left: 50%;
    margin-left: -$tooltip-arrow-width !important;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0 !important;
    border-top-color: $tooltip-arrow-color !important;
  }
  &.top-left .tooltip-arrow {
    bottom: 0 !important;
    right: $tooltip-arrow-width !important;
    margin-bottom: -$tooltip-arrow-width !important;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0 !important;
    border-top-color: $tooltip-arrow-color !important;
  }
  &.top-right .tooltip-arrow {
    bottom: 0 !important;
    left: $tooltip-arrow-width !important;
    margin-bottom: -$tooltip-arrow-width !important;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0 !important;
    border-top-color: $tooltip-arrow-color !important;
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0 !important;
    margin-top: -$tooltip-arrow-width !important;
    border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0 !important;
    border-right-color: $tooltip-arrow-color !important;
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0 !important;
    margin-top: -$tooltip-arrow-width !important;
    border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width !important;
    border-left-color: $tooltip-arrow-color !important;
  }
  &.bottom .tooltip-arrow {
    top: 0 !important;
    left: 50%;
    margin-left: -$tooltip-arrow-width !important;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width !important;
    border-bottom-color: $tooltip-arrow-color !important;
  }
  &.bottom-left .tooltip-arrow {
    top: 0 !important;
    right: $tooltip-arrow-width !important;
    margin-top: -$tooltip-arrow-width !important;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width !important;
    border-bottom-color: $tooltip-arrow-color !important;
  }
  &.bottom-right .tooltip-arrow {
    top: 0 !important;
    left: $tooltip-arrow-width !important;
    margin-top: -$tooltip-arrow-width !important;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width !important;
    border-bottom-color: $tooltip-arrow-color !important;
  }
}
