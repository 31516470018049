
@import '@/sass/components/common/ing.orange.juice';
.ing-form-sub-section {
  margin: auto;
  @include mq-size(s) {
    width: 82%;
  }
  @include mq-size(m) {
    width: 62%;
  }
  @include mq-size(l) {
    column-gap: 74%;
  }

  .section-title {
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
    color: $Primary-Grey600;
    padding: 0.75rem 0;
  }
}
