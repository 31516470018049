
.partner-policy-container {
  padding-top: 24px;
}
.modal-inner-content {
  font-size: 16px;
  line-height: 20px;
  color: #333;
  letter-spacing: -0.02em;
  padding-bottom: 50px;
}
.modal-inner-header {
  text-transform: none;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
