
@import '@/sass/components/common/ing.orange.juice';
.ing-the-header {
  padding: 16px;
  line-height: 0;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  @include mq-size(m) {
    padding: 24px;
  }
  &__logo {
    width: 120px;
    height: auto;
  }
}
