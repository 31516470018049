
@import '../../../sass/custom/_settings.scss';
p {
  font-size: 14px;
  line-height: 21px;
}
h3 {
  font-size: 16px;
  font-weight: bold;
  color: $darkerGrey;
}
.client-title-container {
  padding: 40px 0 0 40px;
}
.client-section-container {
  width: 100%;
  padding: 40px;
}
.form-container {
  &.alert {
    border: solid 1px $red;
  }
}
.general-error-container {
  min-height: 25px;
  height: fit-content;
}
.general-error-container p {
  text-align: center;
  font-size: 0.8rem;
}
.submit-section-container {
  width: 100%;
  padding: 0 40px 40px 40px;
}
.title-field {
  margin-top: 23px;
}
