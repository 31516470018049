
.ing-legal-status {
  position: relative;

  &__not-supported {
    position: absolute;
    left: 0;
    top: 68px;
    font-size: 12px;
    line-height: 12px;
    color: #333;
    padding: 2px 0 2px 20px;
    background: url('~@/assets/img/ing_info_icon.svg') 0 0 no-repeat;
  }
}
