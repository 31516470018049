
@import '@/sass/custom/_settings.scss';
@import '@/variables.scss';

.len-promise-section {
  margin-top: 35px;
}
.len-promise {
  display: flex;
  flex-direction: row;
  padding: 40px 0;

  &__icon {
    width: 60px;
  }

  &__title {
    font-weight: $semibold;
    font-size: 24px;
    line-height: 24px;
    margin: 20px 0 10px 0;
  }

  &__caption {
    font-size: 18px;
    line-height: 24px;
    color: $darkGrey;
  }
}

@media screen and (max-width: $tablet) {
  .len-promise {
    padding: 40px 20px;
    justify-content: space-between;

    &__wrapper {
      max-width: 210px;
    }

    &__caption {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

@media screen and (max-width: $mobile-l) {
  .len-promise-section {
    margin-top: 0;
  }
  .len-promise {
    padding: 40px 0;
    flex-direction: column;

    &__wrapper {
      display: flex;
      margin-bottom: 40px;
      max-width: none;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__icon {
      width: 40px;
      margin-right: 16px;
    }
    &__title,
    &__caption {
      font-size: 16px;
      line-height: 16px;
    }
    &__title {
      margin: 0 0 10px 0;
    }
  }
}
