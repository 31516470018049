
@import '@/sass/custom/_settings.scss';
@import '@/variables.scss';

.len-contact {
  display: flex;
  width: 100%;
  padding: 80px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2e2e2e;
  text-align: center;

  &__title {
    font-size: 48px;
    line-height: 48px;
    font-weight: $semibold;
  }

  &__description {
    font-size: 18px;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 20px;

    &--first-line {
      display: block;
    }
  }

  &__phone-email {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    line-height: 28px;
    font-weight: $semibold;

    &__union {
      font-size: 18px;
      line-height: 24px;
      margin: 0 12px;
      font-weight: normal;
    }
  }
}

@media screen and (max-width: $tablet) {
  .len-contact {
    &__title {
      line-height: 54px;
    }

    &__description {
      letter-spacing: -0.3px;
    }
    &__title {
      margin: 0;
    }
  }
}

@media screen and (max-width: $mobile-l) {
  .len-contact {
    padding: 40px 0;

    &__title {
      font-size: 24px;
      line-height: 28px;
    }

    &__description {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: initial;
      margin: 20px 0;

      &--first-line {
        display: inline;
      }
    }

    &__phone-email {
      font-size: 16px;
      line-height: 28px;
      flex-direction: column;

      &__union {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}
