
@import '../../../../sass/custom/variables';

.overview-container {
  font-size: 16px;

  &.--no-padding {
    padding: 0;
  }

  &__subsection {
    padding: 40px;

    &.dark-background {
      background-color: $ing-signer-section-background;
    }
  }
}

.radio-container {
  padding-left: 0;
}

.disbursement-option {
  display: flex;
  margin-bottom: 12px;
  min-height: 36px;
}

.amount-form {
  display: flex;
  align-items: center;
}

.amount-input {
  position: relative;
  margin: 0 12px;

  .form-control {
    width: 127px;
  }

  &__symbol {
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    right: 0;
    top: 0;
  }

  &__hint {
    font-size: 14px;
    line-height: 22px;
    position: absolute;
    text-align: center;
    color: $ing-gray;
    width: 100%;
    top: 40px;
  }

  &--invalid {
    .form-control {
      border-color: #ff0000;
    }
  }

  &--invalid &__hint {
    color: #ff0000;
  }
}

p {
  margin-bottom: 20px;
}

.ing-error {
  margin-top: 40px;
}

.rate-breakdown {
  margin-top: 30px;
  width: 410px;

  &__row {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__label {
    flex: 1 1 200px;
  }

  &__value {
    font-weight: bold;
    flex: 1 1 210px;
  }

  &__divider {
    height: 1px;
    max-height: 1px;
    line-height: 1px;
    background-color: #333333;
    margin: 10px 0;
    width: 100%;
  }
}
