
@import '@/variables.scss';

.ing-native-select {
  position: relative;
  display: flex;
  border-radius: 4px;

  &:before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    background: #fff;
  }

  &__icon,
  &__element {
    font-size: 16px;
    line-height: 20px;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 6px 12px;
    z-index: 0;
  }

  &__element {
    cursor: pointer;
    padding: 6px 36px 6px 12px;
    background: transparent;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    outline: none;
    border: solid 1px #ccc;
    width: 100%;
    position: relative;
    z-index: 1;
    font-family: inherit;

    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
    /* For IE10 */
    &::-ms-expand {
      display: none;
    }

    &--error {
      border: 1px solid #ff0000;
    }
  }
}
