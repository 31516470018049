// Predefine the variables below in order to alter and enable specific features.

$mp-spacing-namespace: '';

$mp-enable-margins--none: true;
$mp-enable-margins: true;
$mp-enable-margins--tiny: true;
$mp-enable-margins--small: true;
$mp-enable-margins--large: true;
$mp-enable-margins--huge: true;
$mp-enable-margins--negative-tiny: true;
$mp-enable-margins--negative-small: true;
$mp-enable-margins--negative-large: true;
$mp-enable-margins--negative-huge: true;

$mp-enable-paddings--none: true;
$mp-enable-paddings: true;
$mp-enable-paddings--tiny: true;
$mp-enable-paddings--small: true;
$mp-enable-paddings--large: true;
$mp-enable-paddings--huge: true;
$mp-enable-paddings--negative-tiny: true;
$mp-enable-paddings--negative-small: true;
$mp-enable-paddings--negative-large: true;
$mp-enable-paddings--negative-huge: true;



$mp-base-spacing-unit: 10px;
$mp-margin:                          $mp-base-spacing-unit !default;
$mp-margin--small:                       calc($mp-margin/2) !default;
$mp-margin--tiny:                      calc($mp-margin/4) !default;
$mp-margin--large:                      $mp-margin*2 !default;
$mp-margin--huge:                    $mp-margin*4 !default;

$mp-padding:                          $mp-base-spacing-unit !default;
$mp-padding--small:                      calc($mp-padding/2) !default;
$mp-padding--tiny:                     calc($mp-padding/4) !default;
$mp-padding--large:                     $mp-padding*2 !default;
$mp-padding--huge:                   $mp-padding*4 !default;

$mp-enable-margins:                  false !default;
$mp-enable-margins--tiny:            false !default;
$mp-enable-margins--small:           false !default;
$mp-enable-margins--large:           false !default;
$mp-enable-margins--huge:            false !default;

$mp-enable-margins--negative:        false !default;
$mp-enable-margins--negative-tiny:   false !default;
$mp-enable-margins--negative-small:  false !default;
$mp-enable-margins--negative-large:  false !default;
$mp-enable-margins--negative-huge:   false !default;

$mp-enable-margins--none:            false !default;

$mp-enable-paddings:                 false !default;
$mp-enable-paddings--tiny:           false !default;
$mp-enable-paddings--small:          false !default;
$mp-enable-paddings--large:          false !default;
$mp-enable-paddings--huge:           false !default;

$mp-enable-paddings--none:           false !default;


///*------------------------------------*\
//    #RESPONSIVE-SETTINGS
//\*------------------------------------*/

// Hold our breakpoint aliases and conditions in a list.
//
// These can be invoked later on via the `media-query()` mixin found in

$breakpoints: (
  'mobile'			'screen and (max-width: 768px)',
  'mobile-small'		'screen and (max-width: 480px)',
  'mobile-tiny'		'screen and (max-width: 363px)',
  'desktop'			'screen and (min-width: 769px)',
  'desktop-medium-1'	'screen and (max-width: 849px)',
  'desktop-medium'	'screen and (min-width: 850px)',
  'desktop-big-1'		'screen and (max-width: 1079px)',
  'desktop-big'		'screen and (min-width: 1080px)',
  'desktop-large'		'screen and (max-width: 1237px)',
  'retina'        '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)'
) !default;

// If we have included this file, set a variable to tell the rest of the
// framework that we have some responsive settings.
$mp-responsive-settings: true;


/*------------------------------------*\
    #SPACING-RESPONSIVE
\*------------------------------------*/

/**
 * Margin and padding helper classes. Use these to tweak layout on a micro
 * level.
 *
 * `.(m|p)(t|r|b|l|h|v)(-|+|0) {}` = margin/padding top/right/bottom/left/horizontal/vertical less/more/none
 */

// Predefine the variables below in order to alter and enable specific features.
$mp-enable-responsive-margins:                   true !default;
$mp-enable-responsive-margins--tiny:             true !default;
$mp-enable-responsive-margins--small:            true !default;
$mp-enable-responsive-margins--large:            true !default;
$mp-enable-responsive-margins--huge:             true !default;

$mp-enable-responsive-margins--negative:         true !default;
$mp-enable-responsive-margins--negative-tiny:    true !default;
$mp-enable-responsive-margins--negative-small:   true !default;
$mp-enable-responsive-margins--negative-large:   true !default;
$mp-enable-responsive-margins--negative-huge:    true !default;

$mp-enable-responsive-margins--none:             true !default;

$mp-enable-responsive-paddings:                  true !default;
$mp-enable-responsive-paddings--tiny:            true !default;
$mp-enable-responsive-paddings--small:           true !default;
$mp-enable-responsive-paddings--large:           true !default;
$mp-enable-responsive-paddings--huge:            true !default;

$mp-enable-responsive-paddings--none:            true !default;


// Internally used mixin to quickly generate our different variants based upon
// the breakpoints defined in `settings.responsive`.
$mp-generate-spacing-alias: null;
@mixin mp-generate-spacing() {

    // Loop through our previously-defined breakpoints.
    @each $breakpoint in $breakpoints {

        $mp-generate-spacing-alias:      nth($breakpoint, 1) !global;
        $mp-generate-spacing-condition:  nth($breakpoint, 2);

        // This isn’t ideal, but we definitely don’t want to generate widths
        // for retina devices. Exclude retina media-qeuries manually.
        @if ($mp-generate-spacing-alias != "retina") {

            @include media-query($mp-generate-spacing-alias) {
                @content;
            } // Close media query.

        } // Close retina check.

        // Take the global variable back out of scope.
        $mp-generate-spacing-alias: null !global;

    } // Close breakpoints loop.

}

@mixin media-query($mq) {

    $breakpoint-found: false;

    // Loop through the list of breakpoints we’ve provided in our settings file.
    @each $breakpoint in $breakpoints {

        // Grab the alias and the condition from their respective locations in
        // the list.
        $alias:     nth($breakpoint, 1);
        $condition: nth($breakpoint, 2);

        // If the media query we’ve specified has an alias and a condition...
        @if $mq == $alias and $condition {

            // ...tell the mixin that we’ve found it...
            $breakpoint-found: true;

            // ...and spit it out here.
            @media #{$condition} {
                @content;
            }

        }

    }

    // If the user specifies a non-exitent alias, send them a warning.
    @if $breakpoint-found == false{
        @warn "Oops! Breakpoint ‘#{$mq}’ does not exist."
    }

}



@if ($mp-enable-margins == true) {

    /**
     * Margin helper classes.
     *
     * Add margins.
     */

    .#{$mp-spacing-namespace}u-m     { margin:           $mp-margin !important; }
    .#{$mp-spacing-namespace}u-mt    { margin-top:       $mp-margin !important; }
    .#{$mp-spacing-namespace}u-mr    { margin-right:     $mp-margin !important; }
    .#{$mp-spacing-namespace}u-mb    { margin-bottom:    $mp-margin !important; }
    .#{$mp-spacing-namespace}u-ml    { margin-left:      $mp-margin !important; }
    .#{$mp-spacing-namespace}u-mh    { margin-right:     $mp-margin !important; margin-left:      $mp-margin !important; }
    .#{$mp-spacing-namespace}u-mv    { margin-top:       $mp-margin !important; margin-bottom:    $mp-margin !important; }

}


@if ($mp-enable-margins--tiny == true) {

    /**
     * Add tiny margins.
     */

    .#{$mp-spacing-namespace}u-m--   { margin:           $mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u-mt--  { margin-top:       $mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u-mr--  { margin-right:     $mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u-mb--  { margin-bottom:    $mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u-ml--  { margin-left:      $mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u-mh--  { margin-right:     $mp-margin--tiny !important; margin-left:     $mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u-mv--  { margin-top:       $mp-margin--tiny !important; margin-bottom:   $mp-margin--tiny !important; }

}


@if ($mp-enable-margins--small == true) {

    /**
     * Add small margins.
     */

    .#{$mp-spacing-namespace}u-m-    { margin:           $mp-margin--small !important; }
    .#{$mp-spacing-namespace}u-mt-   { margin-top:       $mp-margin--small !important; }
    .#{$mp-spacing-namespace}u-mr-   { margin-right:     $mp-margin--small !important; }
    .#{$mp-spacing-namespace}u-mb-   { margin-bottom:    $mp-margin--small !important; }
    .#{$mp-spacing-namespace}u-ml-   { margin-left:      $mp-margin--small !important; }
    .#{$mp-spacing-namespace}u-mh-   { margin-right:     $mp-margin--small !important; margin-left:   $mp-margin--small !important; }
    .#{$mp-spacing-namespace}u-mv-   { margin-top:       $mp-margin--small !important; margin-bottom: $mp-margin--small !important; }

}


@if ($mp-enable-margins--large == true) {

    /**
     * Add large margins.
     */

    .#{$mp-spacing-namespace}u-m\+   { margin:           $mp-margin--large !important; }
    .#{$mp-spacing-namespace}u-mt\+  { margin-top:       $mp-margin--large !important; }
    .#{$mp-spacing-namespace}u-mr\+  { margin-right:     $mp-margin--large !important; }
    .#{$mp-spacing-namespace}u-mb\+  { margin-bottom:    $mp-margin--large !important; }
    .#{$mp-spacing-namespace}u-ml\+  { margin-left:      $mp-margin--large !important; }
    .#{$mp-spacing-namespace}u-mh\+  { margin-right:     $mp-margin--large !important; margin-left:      $mp-margin--large !important; }
    .#{$mp-spacing-namespace}u-mv\+  { margin-top:       $mp-margin--large !important; margin-bottom:    $mp-margin--large !important; }

}


@if ($mp-enable-margins--huge == true) {

    /**
     * Add huge margins.
     */

    .#{$mp-spacing-namespace}u-m\+\+     { margin:           $mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u-mt\+\+    { margin-top:       $mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u-mr\+\+    { margin-right:     $mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u-mb\+\+    { margin-bottom:    $mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u-ml\+\+    { margin-left:      $mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u-mh\+\+    { margin-right:     $mp-margin--huge !important; margin-left:   $mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u-mv\+\+    { margin-top:       $mp-margin--huge !important; margin-bottom: $mp-margin--huge !important; }

}


@if ($mp-enable-margins--none == true) {

    /**
     * Remove margins.
     */

    .#{$mp-spacing-namespace}u-m0    { margin:           0 !important; }
    .#{$mp-spacing-namespace}u-mt0   { margin-top:       0 !important; }
    .#{$mp-spacing-namespace}u-mr0   { margin-right:     0 !important; }
    .#{$mp-spacing-namespace}u-mb0   { margin-bottom:    0 !important; }
    .#{$mp-spacing-namespace}u-ml0   { margin-left:      0 !important; }
    .#{$mp-spacing-namespace}u-mh0   { margin-right:     0 !important; margin-left:      0 !important; }
    .#{$mp-spacing-namespace}u-mv0   { margin-top:       0 !important; margin-bottom:    0 !important; }

}


@if ($mp-enable-margins--negative == true) {

    /**
     * Negative margins.
     */

    .#{$mp-spacing-namespace}u--m    { margin:           -$mp-margin !important; }
    .#{$mp-spacing-namespace}u--mt   { margin-top:       -$mp-margin !important; }
    .#{$mp-spacing-namespace}u--mr   { margin-right:     -$mp-margin !important; }
    .#{$mp-spacing-namespace}u--mb   { margin-bottom:    -$mp-margin !important; }
    .#{$mp-spacing-namespace}u--ml   { margin-left:      -$mp-margin !important; }
    .#{$mp-spacing-namespace}u--mh   { margin-right:     -$mp-margin !important; margin-left:      -$mp-margin !important; }
    .#{$mp-spacing-namespace}u--mv   { margin-top:       -$mp-margin !important; margin-bottom:    -$mp-margin !important; }

}


@if ($mp-enable-margins--negative-tiny == true) {

    /**
     * Tiny negative margins.
     */

    .#{$mp-spacing-namespace}u--m--  { margin:           -$mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u--mt-- { margin-top:       -$mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u--mr-- { margin-right:     -$mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u--mb-- { margin-bottom:    -$mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u--ml-- { margin-left:      -$mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u--mh-- { margin-right:     -$mp-margin--tiny !important; margin-left:     -$mp-margin--tiny !important; }
    .#{$mp-spacing-namespace}u--mv-- { margin-top:       -$mp-margin--tiny !important; margin-bottom:   -$mp-margin--huge !important; }

}


@if ($mp-enable-margins--negative-small == true) {

    /**
     * Small negative margins.
     */

    .#{$mp-spacing-namespace}u--m-   { margin:           -$mp-margin--small !important; }
    .#{$mp-spacing-namespace}u--mt-  { margin-top:       -$mp-margin--small !important; }
    .#{$mp-spacing-namespace}u--mr-  { margin-right:     -$mp-margin--small !important; }
    .#{$mp-spacing-namespace}u--mb-  { margin-bottom:    -$mp-margin--small !important; }
    .#{$mp-spacing-namespace}u--ml-  { margin-left:      -$mp-margin--small !important; }
    .#{$mp-spacing-namespace}u--mh-  { margin-right:     -$mp-margin--small !important; margin-left:   -$mp-margin--small !important; }
    .#{$mp-spacing-namespace}u--mv-  { margin-top:       -$mp-margin--small !important; margin-bottom: -$mp-margin--small !important; }

}


@if ($mp-enable-margins--negative-large == true) {

    /**
     * Large negative margins.
     */

    .#{$mp-spacing-namespace}u--m\+  { margin:           -$mp-margin--large !important; }
    .#{$mp-spacing-namespace}u--mt\+ { margin-top:       -$mp-margin--large !important; }
    .#{$mp-spacing-namespace}u--mr\+ { margin-right:     -$mp-margin--large !important; }
    .#{$mp-spacing-namespace}u--mb\+ { margin-bottom:    -$mp-margin--large !important; }
    .#{$mp-spacing-namespace}u--ml\+ { margin-left:      -$mp-margin--large !important; }
    .#{$mp-spacing-namespace}u--mh\+ { margin-right:     -$mp-margin--large !important; margin-left:      -$mp-margin--large !important; }
    .#{$mp-spacing-namespace}u--mv\+ { margin-top:       -$mp-margin--large !important; margin-bottom:    -$mp-margin--large !important; }

}


@if ($mp-enable-margins--negative-huge == true) {

    /**
     * Huge negative margins.
     */

    .#{$mp-spacing-namespace}u--m\+\+    { margin:           -$mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u--mt\+\+   { margin-top:       -$mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u--mr\+\+   { margin-right:     -$mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u--mb\+\+   { margin-bottom:    -$mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u--ml\+\+   { margin-left:      -$mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u--mh\+\+   { margin-right:     -$mp-margin--huge !important; margin-left:   -$mp-margin--huge !important; }
    .#{$mp-spacing-namespace}u--mv\+\+   { margin-top:       -$mp-margin--huge !important; margin-bottom: -$mp-margin--huge !important; }

}


@if ($mp-enable-paddings == true) {

    /**
     * Padding helper classes.
     *
     * Add paddings.
     */

    .#{$mp-spacing-namespace}u-p     { padding:          $mp-padding !important; }
    .#{$mp-spacing-namespace}u-pt    { padding-top:      $mp-padding !important; }
    .#{$mp-spacing-namespace}u-pr    { padding-right:    $mp-padding !important; }
    .#{$mp-spacing-namespace}u-pb    { padding-bottom:   $mp-padding !important; }
    .#{$mp-spacing-namespace}u-pl    { padding-left:     $mp-padding !important; }
    .#{$mp-spacing-namespace}u-ph    { padding-right:    $mp-padding !important; padding-left:    $mp-padding !important; }
    .#{$mp-spacing-namespace}u-pv    { padding-top:      $mp-padding !important; padding-bottom:  $mp-padding !important; }

}


@if ($mp-enable-paddings--tiny == true) {

    /**
     * Add tiny paddings.
     */

    .#{$mp-spacing-namespace}u-p--   { padding:           $mp-padding--tiny !important; }
    .#{$mp-spacing-namespace}u-pt--  { padding-top:       $mp-padding--tiny !important; }
    .#{$mp-spacing-namespace}u-pr--  { padding-right:     $mp-padding--tiny !important; }
    .#{$mp-spacing-namespace}u-pb--  { padding-bottom:    $mp-padding--tiny !important; }
    .#{$mp-spacing-namespace}u-pl--  { padding-left:      $mp-padding--tiny !important; }
    .#{$mp-spacing-namespace}u-ph--  { padding-right:     $mp-padding--tiny !important; padding-left:      $mp-padding--tiny !important; }
    .#{$mp-spacing-namespace}u-pv--  { padding-top:       $mp-padding--tiny !important; padding-bottom:    $mp-padding--tiny !important; }

}


@if ($mp-enable-paddings--small == true) {

    /**
     * Add small paddings.
     */

    .#{$mp-spacing-namespace}u-p-    { padding:           $mp-padding--small !important; }
    .#{$mp-spacing-namespace}u-pt-   { padding-top:       $mp-padding--small !important; }
    .#{$mp-spacing-namespace}u-pr-   { padding-right:     $mp-padding--small !important; }
    .#{$mp-spacing-namespace}u-pb-   { padding-bottom:    $mp-padding--small !important; }
    .#{$mp-spacing-namespace}u-pl-   { padding-left:      $mp-padding--small !important; }
    .#{$mp-spacing-namespace}u-ph-   { padding-right:     $mp-padding--small !important; padding-left:    $mp-padding--small !important; }
    .#{$mp-spacing-namespace}u-pv-   { padding-top:       $mp-padding--small !important; padding-bottom:  $mp-padding--small !important; }

}


@if ($mp-enable-paddings--large == true) {

    /**
     * Add large paddings.
     */

    .#{$mp-spacing-namespace}u-p\+   { padding:           $mp-padding--large !important; }
    .#{$mp-spacing-namespace}u-pt\+  { padding-top:       $mp-padding--large !important; }
    .#{$mp-spacing-namespace}u-pr\+  { padding-right:     $mp-padding--large !important; }
    .#{$mp-spacing-namespace}u-pb\+  { padding-bottom:    $mp-padding--large !important; }
    .#{$mp-spacing-namespace}u-pl\+  { padding-left:      $mp-padding--large !important; }
    .#{$mp-spacing-namespace}u-ph\+  { padding-right:     $mp-padding--large !important; padding-left:   $mp-padding--large !important; }
    .#{$mp-spacing-namespace}u-pv\+  { padding-top:       $mp-padding--large !important; padding-bottom: $mp-padding--large !important; }

}


@if ($mp-enable-paddings--huge == true) {

    /**
     * Add huge paddings.
     */

    .#{$mp-spacing-namespace}u-p\+\+     { padding:           $mp-padding--huge !important; }
    .#{$mp-spacing-namespace}u-pt\+\+    { padding-top:       $mp-padding--huge !important; }
    .#{$mp-spacing-namespace}u-pr\+\+    { padding-right:     $mp-padding--huge !important; }
    .#{$mp-spacing-namespace}u-pb\+\+    { padding-bottom:    $mp-padding--huge !important; }
    .#{$mp-spacing-namespace}u-pl\+\+    { padding-left:      $mp-padding--huge !important; }
    .#{$mp-spacing-namespace}u-ph\+\+    { padding-right:     $mp-padding--huge !important; padding-left:    $mp-padding--huge !important; }
    .#{$mp-spacing-namespace}u-pv\+\+    { padding-top:       $mp-padding--huge !important; padding-bottom:  $mp-padding--huge !important; }

}


@if ($mp-enable-paddings--none == true) {

    /**
     * Remove paddings.
     */

    .#{$mp-spacing-namespace}u-p0    { padding:          0 !important; }
    .#{$mp-spacing-namespace}u-pt0   { padding-top:      0 !important; }
    .#{$mp-spacing-namespace}u-pr0   { padding-right:    0 !important; }
    .#{$mp-spacing-namespace}u-pb0   { padding-bottom:   0 !important; }
    .#{$mp-spacing-namespace}u-pl0   { padding-left:     0 !important; }
    .#{$mp-spacing-namespace}u-ph0   { padding-right:    0 !important; padding-left:     0 !important; }
    .#{$mp-spacing-namespace}u-pv0   { padding-top:      0 !important; padding-bottom:   0 !important; }

}


/*
    Generate Responsive Classes
*/




@if ($mp-enable-responsive-margins == true) {

    /**
     * Margin helper classes.
     *
     * Add margins.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-m      { margin:           $mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mt     { margin-top:       $mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mr     { margin-right:     $mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mb     { margin-bottom:    $mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ml     { margin-left:      $mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mh     { margin-right:     $mp-margin !important; margin-left:      $mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mv     { margin-top:       $mp-margin !important; margin-bottom:    $mp-margin !important; }

    }

}


@if ($mp-enable-responsive-margins--tiny == true) {

    /**
     * Add tiny margins.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-m--     { margin:           $mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mt--    { margin-top:       $mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mr--    { margin-right:     $mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mb--    { margin-bottom:    $mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ml--    { margin-left:      $mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mh--    { margin-right:     $mp-margin--tiny !important; margin-left:     $mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mv--    { margin-top:       $mp-margin--tiny !important; margin-bottom:   $mp-margin--tiny !important; }

    }

}


@if ($mp-enable-responsive-margins--small == true) {

    /**
     * Add small margins.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-m-      { margin:           $mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mt-     { margin-top:       $mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mr-     { margin-right:     $mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mb-     { margin-bottom:    $mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ml-     { margin-left:      $mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mh-     { margin-right:     $mp-margin--small !important; margin-left:   $mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mv-     { margin-top:       $mp-margin--small !important; margin-bottom: $mp-margin--small !important; }

    }

}


@if ($mp-enable-responsive-margins--large == true) {

    /**
     * Add large margins.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-m\+     { margin:           $mp-margin--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mt\+    { margin-top:       $mp-margin--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mr\+    { margin-right:     $mp-margin--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mb\+    { margin-bottom:    $mp-margin--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ml\+    { margin-left:      $mp-margin--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mh\+    { margin-right:     $mp-margin--large !important; margin-left:      $mp-margin--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mv\+    { margin-top:       $mp-margin--large !important; margin-bottom:    $mp-margin--large !important; }

    }

}


@if ($mp-enable-responsive-margins--huge == true) {

    /**
     * Add huge margins.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-m\+\+   { margin:           $mp-margin--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mt\+\+  { margin-top:       $mp-margin--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mr\+\+  { margin-right:     $mp-margin--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mb\+\+  { margin-bottom:    $mp-margin--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ml\+\+  { margin-left:      $mp-margin--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mh\+\+  { margin-right:     $mp-margin--huge !important; margin-left:   $mp-margin--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mv\+\+  { margin-top:       $mp-margin--huge !important; margin-bottom: $mp-margin--huge !important; }

    }

}


@if ($mp-enable-responsive-margins--none == true) {

    /**
     * Remove margins.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-m0      { margin:           0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mt0     { margin-top:       0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mr0     { margin-right:     0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mb0     { margin-bottom:    0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ml0     { margin-left:      0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mh0     { margin-right:     0 !important; margin-left:      0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-mv0     { margin-top:       0 !important; margin-bottom:    0 !important; }

    }

}


@if ($mp-enable-responsive-margins--negative == true) {

    /**
     * Negative margins.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--m      { margin:           -$mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mt     { margin-top:       -$mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mr     { margin-right:     -$mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mb     { margin-bottom:    -$mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--ml     { margin-left:      -$mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mh     { margin-right:     -$mp-margin !important; margin-left:      -$mp-margin !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mv     { margin-top:       -$mp-margin !important; margin-bottom:    -$mp-margin !important; }

    }

}


@if ($mp-enable-responsive-margins--negative-tiny == true) {

    /**
     * Tiny negative margins.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--m--    { margin:           -$mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mt--   { margin-top:       -$mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mr--   { margin-right:     -$mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mb--   { margin-bottom:    -$mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--ml--   { margin-left:      -$mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mh--   { margin-right:     -$mp-margin--tiny !important; margin-left:     -$mp-margin--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mv--   { margin-top:       -$mp-margin--tiny !important; margin-bottom:   -$mp-margin--tiny !important; }

    }

}


@if ($mp-enable-responsive-margins--negative-small == true) {

    /**
     * Small negative margins.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--m-     { margin:           -$mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mt-    { margin-top:       -$mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mr-    { margin-right:     -$mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mb-    { margin-bottom:    -$mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--ml-    { margin-left:      -$mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mh-    { margin-right:     -$mp-margin--small !important; margin-left:   -$mp-margin--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mv-    { margin-top:       -$mp-margin--small !important; margin-bottom: -$mp-margin--small !important; }

    }

}


@if ($mp-enable-responsive-margins--negative-large == true) {

    /**
     * Large negative margins.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--m\+    { margin:           double(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mt\+   { margin-top:       double(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mr\+   { margin-right:     double(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mb\+   { margin-bottom:    double(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--ml\+   { margin-left:      double(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mh\+   { margin-right:     double(-$mp-margin) !important; margin-left:      double(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mv\+   { margin-top:       double(-$mp-margin) !important; margin-bottom:    double(-$mp-margin) !important; }

    }

}


@if ($mp-enable-responsive-margins--negative-huge == true) {

    /**
     * Huge negative margins.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--m\+\+  { margin:           quadruple(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mt\+\+ { margin-top:       quadruple(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mr\+\+ { margin-right:     quadruple(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mb\+\+ { margin-bottom:    quadruple(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--ml\+\+ { margin-left:      quadruple(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mh\+\+ { margin-right:     quadruple(-$mp-margin) !important; margin-left:   quadruple(-$mp-margin) !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}--mv\+\+ { margin-top:       quadruple(-$mp-margin) !important; margin-bottom: quadruple(-$mp-margin) !important; }

    }

}





@if ($mp-enable-responsive-paddings == true) {

    /**
     * Padding helper classes.
     *
     * Add paddings.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-p       { padding:          $mp-padding !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pt      { padding-top:      $mp-padding !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pr      { padding-right:    $mp-padding !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pb      { padding-bottom:   $mp-padding !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pl      { padding-left:     $mp-padding !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ph      { padding-right:    $mp-padding !important; padding-left:    $mp-padding !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pv      { padding-top:      $mp-padding !important; padding-bottom:  $mp-padding !important; }

    }

}


@if ($mp-enable-responsive-paddings--tiny == true) {

    /**
     * Add tiny paddings.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-p--     { padding:           $mp-padding--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pt--    { padding-top:       $mp-padding--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pr--    { padding-right:     $mp-padding--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pb--    { padding-bottom:    $mp-padding--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pl--    { padding-left:      $mp-padding--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ph--    { padding-right:     $mp-padding--tiny !important; padding-left:      $mp-padding--tiny !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pv--    { padding-top:       $mp-padding--tiny !important; padding-bottom:    $mp-padding--tiny !important; }

    }

}


@if ($mp-enable-responsive-paddings--small == true) {

    /**
     * Add small paddings.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-p-      { padding:           $mp-padding--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pt-     { padding-top:       $mp-padding--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pr-     { padding-right:     $mp-padding--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pb-     { padding-bottom:    $mp-padding--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pl-     { padding-left:      $mp-padding--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ph-     { padding-right:     $mp-padding--small !important; padding-left:    $mp-padding--small !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pv-     { padding-top:       $mp-padding--small !important; padding-bottom:  $mp-padding--small !important; }

    }

}


@if ($mp-enable-responsive-paddings--large == true) {

    /**
     * Add large paddings.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-p\+     { padding:           $mp-padding--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pt\+    { padding-top:       $mp-padding--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pr\+    { padding-right:     $mp-padding--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pb\+    { padding-bottom:    $mp-padding--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pl\+    { padding-left:      $mp-padding--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ph\+    { padding-right:     $mp-padding--large !important; padding-left:   $mp-padding--large !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pv\+    { padding-top:       $mp-padding--large !important; padding-bottom: $mp-padding--large !important; }

    }

}


@if ($mp-enable-responsive-paddings--huge == true) {

    /**
     * Add huge paddings.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-p\+\+   { padding:           $mp-padding--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pt\+\+  { padding-top:       $mp-padding--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pr\+\+  { padding-right:     $mp-padding--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pb\+\+  { padding-bottom:    $mp-padding--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pl\+\+  { padding-left:      $mp-padding--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ph\+\+  { padding-right:     $mp-padding--huge !important; padding-left:    $mp-padding--huge !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pv\+\+  { padding-top:       $mp-padding--huge !important; padding-bottom:  $mp-padding--huge !important; }

    }

}


@if ($mp-enable-responsive-paddings--none == true) {

    /**
     * Remove paddings.
     */

    @include mp-generate-spacing() {

        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-p0      { padding:          0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pt0     { padding-top:      0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pr0     { padding-right:    0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pb0     { padding-bottom:   0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pl0     { padding-left:     0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-ph0     { padding-right:    0 !important; padding-left:     0 !important; }
        .#{$mp-spacing-namespace}#{$mp-generate-spacing-alias}-pv0     { padding-top:      0 !important; padding-bottom:   0 !important; }

    }

}
