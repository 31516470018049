
.cash-intensity-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.error-container {
  height: 25px;
}
.validation {
  text-align: left;
}
@media (max-width: 870px) {
  .cash-intensity-container {
    flex-direction: column;

    label.radio-container {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
