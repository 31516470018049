/** Grid */
.panel {
    border: solid 1px $grey;
    padding: 30px;
    width: 100%;
    display: block;
    @include bp(mobile) {
        padding: 0;
        min-height: 0 !important;
        margin-bottom: 30px;
        border: none;
    }
    h2.title {
        @include font-size(30);
        color: $grey;
        font-weight: $normal;
        text-align: center;
        margin: 0 auto;
    }
    h3 {
        text-align: center;
        margin: 0 auto;
        @include font-size(40);
        font-weight: $bold;
        color: $grey;
        strong {
            color: $darkerGrey;
        }
    }
    h4 {
        @include font-size(18);
        color: $darkerGrey;
        font-weight: $bold;
        text-transform: uppercase;
        margin: 0 0 15px;
    }
    span.green {
        color: $petroleum;
        font-weight: $semibold;
    }
    // .button {
    //     width: 100%;
    // }
    .error-message {
        display: block !important;
        width: 100%;
        p {
            display: block !important;
            width: 100%;
        }
    }
    dl,
    table {
        width: 100%;
        margin-bottom: 0;
        @extend .transition;
        dd,
        dt {
            width: 100%;
            display: table;
            vertical-align: middle;
            @extend .transition;
            .u-ph- strong {
                display: inline-block;
            }
        }
        dd {
            display: none;
            @extend .transition;
        }
        .expandable {
            width: 100%;
            display: block;
            position: relative;
        }
        .expandable.open dd {
            display: block;
        }
        .expandable dt {
            padding-left: 10px;
            position: relative;
            cursor: pointer;
            margin-bottom: 3px;
            @extend .transition;
        }
        .expandable dl {
            margin-top: -3px;
        }
        .expandable {
            > dt:before {
                content: '+';
                color: $petroleum;
                @include font-size(14);
                width: 10px;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -7px;
                height: 20px;
                text-align: left;
            }
            &.open > dt:before {
                content: '-';
            }
        }
        .vertical-padding div,
        .vertical-padding td {
            padding: 10px 0;
        }
        .bottom-border,
        .top-border {
            border-color: $darkerGrey;
            &.focus {
                border-bottom-color: $petroleum;
            }
            &.error {
                border-bottom-color: $validation;
            }
        }
        div,
        td {
            @include font-size(14);
            color: $grey;
            font-weight: $normal;
            display: table-cell;
            vertical-align: middle;
            text-align: right;
            &.chf {
                width: 25px;
            }
            strong {
                @include font-size(14);
                font-weight: $semibold;
                color: $darkerGrey;
            }
            &:first-child {
                text-align: left;
            }
        }
        div input,
        td input {
            width: 100%;
            border: none;
            background: none;
            display: block;
            position: relative;
            text-align: right;
            color: $darkerGrey;
            font-weight: $semibold;
        }
        div label,
        td label {
            width: 100%;
            display: block;
            position: relative;
            text-align: right;
            color: $grey;
            font-weight: $semibold;
        }
    }
    .custom-graph svg path {
        stroke: $white;
        stroke-width: 5;
    }
    legend {
        margin: 0 auto 5px;
    }
}
.dark-section .panel {
    h2.title {
        color: $white;
    }
    h3 {
        color: $grey;
        strong {
            color: $white;
        }
    }
    h4 {
        color: $white;
    }
    .boxed-content li,
    .text-block li,
    li,
    p {
        color: $white;
    }
    dl,
    table {
        .bottom-border,
        .top-border {
            border-color: $white;
            &.focus {
                border-bottom-color: $petroleum;
            }
            &.error {
                border-bottom-color: $validation;
            }
        }
        dt,
        td {
            color: $grey;
            strong {
                color: $white;
            }
        }
        div,
        td {
            color: $grey;
            strong {
                color: $white;
            }
        }
        div input,
        td input {
            color: $white;
        }
        div label,
        td label {
            color: $grey;
        }
    }
    .custom-graph svg path {
        stroke: $darkerGrey;
        stroke-width: 5;
    }
}
.light-section .panel {
    dl,
    table {
        .bottom-border,
        .top-border {
            border-color: $darkerGrey;
            &.focus {
                border-bottom-color: $petroleum;
            }
            &.error {
                border-bottom-color: $validation;
            }
        }
        dt,
        td {
            color: $darkerGrey;
            strong {
                color: $darkerGrey;
            }
        }
        div,
        td {
            color: $grey;
            strong {
                color: $darkerGrey;
            }
        }
        div input,
        td input {
            color: $darkerGrey;
        }
        div label,
        td label {
            color: $grey;
        }
    }
    .custom-graph svg path {
        stroke: $lightGrey;
        stroke-width: 5;
    }
}
.dark-section.image .panel {
    .custom-graph svg path {
        stroke: none;
    }
}
