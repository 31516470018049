
@import '@/variables.scss';

@media screen and (max-width: $desktop-s) {
  .max-width {
    padding: 0 24px;
  }
}
@media screen and (max-width: $mobile-l) {
  .max-width {
    padding: 0 16px;
  }
}
