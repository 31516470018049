
@import '@/sass/components/common/ing.orange.juice';
.tooltip-container {
  display: inline-block;
  white-space: pre-line;
  &__icon {
    margin: 0 5px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 24px;
      left: 0;
      border-style: solid;
      border-width: 10px 10px 0;
      border-color: $Primary-Grey400 transparent;
      width: 0;
      z-index: 101;
    }
    &:hover:after {
      display: block;
    }
    &--hidden:after {
      visibility: hidden;
    }
  }
  &__message {
    visibility: visible;
    position: absolute;
    width: 100%;
    bottom: 30px;
    left: 0;
    background-color: $Primary-Grey400;
    box-shadow: 0 5px 5px 3px #00000033, 0 3px 14px 2px #0000001f,
      0 8px 10px 1px #00000024;
    border-radius: 16px;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
    color: $Primary-white;
    z-index: 100;
    &--hidden {
      visibility: hidden;
    }
  }
}
