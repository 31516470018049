
@import '../../../../sass/custom/variables';
.offer-conditions-details-information__description {
  margin: 10px 0 10px -0.25em;
  font-size: 14px;
}

.field-label {
  em {
    color: $ing-dark-gray;
  }
}
