
@import '../../sass/custom/settings';
@import '../../sass/custom/variables';
h2 {
  font-weight: bold;
}
h3 {
  font-size: 20px;
  color: $darkGrey;
  line-height: 30px;
  font-weight: bold;
}
.footnote {
  color: $ing-gray-advantages;
  font-size: 16px;
  max-width: 606px;
  margin-top: 40px;
  word-break: break-all;
}

ul {
  margin-top: 20px;
}

li {
  line-height: 24px;
  margin-bottom: 20px;
  word-break: break-all;
  color: $ing-gray-advantages;
}
li::before {
  background: no-repeat left url('~@/assets/img/ing_submit_icon.png');
  background-size: contain;
  width: 16px;
  height: 14px;
  content: '';
  display: inline-block;
  margin-right: 20px;
}
