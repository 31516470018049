
.sidebar-and-offer-container {
  display: flex;
  min-height: 100vh;
}
.offer-content {
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  overflow: auto;
}
@media (max-width: 932px) {
  .offer-content {
    padding: 20px;
  }
}
