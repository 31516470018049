
.modal {
  &.hidden {
    visibility: hidden;
  }
  display: block;
  .authentication-modal {
    height: fit-content;
    min-height: 492px;
    z-index: 1041;
  }
  .authentication-content {
    padding: 10px;
    text-align: center;
    h3 {
      font-size: 24px;
      color: black;
      font-weight: bold;
      padding-bottom: 10px;
    }
    p {
      font-size: 16px;
      color: #808080;
      margin: 0 auto;
    }
    fieldset {
      display: block;
    }
  }
  .password-input {
    display: block;
    padding: 55px 0 20px 0;
    text-align: left;
    margin: 0 auto;
  }
  .error-message {
    visibility: hidden;
    min-height: 102px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #ffd5cc;
    margin: 0;
    border-radius: 3px 3px 0 0;
    p {
      color: #f55937;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .error-message.active {
    visibility: visible;
  }
}
