
@import '@/sass/components/common/ing.orange.juice';
.partner-form {
  @include mq-size(s) {
    width: 86.6%;
  }
  @include mq-size(m) {
    width: 57.1%;
  }
  @include mq-size(xl) {
    width: 48.8%;
  }
}
