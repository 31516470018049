
@import '../sass/custom/variables';

.container {
  font-family: Source Sans Pro, Sans-Serif;
  font-style: normal;
  width: 100%;
  margin: 250px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.container-title {
  font-weight: 600;
  font-size: 64px;
  line-height: 64px;
  color: $ing-black;
}

.container-subtitle {
  padding-top: 50px;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: #29bdba;
}

.container-text {
  padding-top: 20px;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: $ing-dark-gray;
}

@media (max-width: 991px) {
  .container {
    margin: 98px 0;
    padding: 0 15px;
  }

  .container-title {
    font-size: 32px;
    line-height: 38px;
  }

  .container-subtitle {
    padding-top: 20px;
    font-size: 18px;
    line-height: 24px;
  }

  .container-text {
    font-size: 14px;
    line-height: 16px;
  }
}
