
@import '../../../../sass/custom/settings';
h3 {
  font-size: 16px;
  font-weight: bold;
  color: #2e2e2e;
  margin-bottom: 40px;
}
.company-section-container {
  width: 100%;
  padding: 40px;
}
.form-container {
  &.alert {
    border: solid 1px $red;
  }
}
.submit-section-container {
  width: 100%;
  padding: 0 40px 40px 40px;
}
.short-column-alignment {
  vertical-align: top;
}
.general-error-container {
  height: 25px;
}
.general-error-container p {
  text-align: center;
  font-size: 0.8rem;
}
