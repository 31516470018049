
.centered-container {
  flex-grow: 1;
  margin-top: 53px;
  text-align: center;
}

section {
  margin-top: 60px;
}

.first-container {
  margin-top: 0;
}

.submit-container {
  margin-top: 0;
  width: 100%;
  padding: 20px 10px;
}

.last-container {
  margin-bottom: 60px;
}

:deep(.icon-svg svg) {
  width: 150px;
  height: 150px;
}
