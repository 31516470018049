
@import '../../sass/custom/variables';
.thank-you-container {
  display: flex;
  flex-direction: row;
}
.ing-thank-you-image {
  width: 369px;
}
.submit-icon {
  width: 48px;
}
.ing-thank-you-title {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  h2 {
    font-weight: normal;
    color: $ing-orange;
    font-size: 30px;
    margin-left: 20px;
    padding-bottom: 0;
  }
}
.ing-thank-you-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2%;
  margin-top: 5%;
  min-height: 168px;
  p {
    margin-left: 80px;
    font-size: 14px;
    color: $ing-gray-advantages;
    word-break: break-word;
  }
}

@media (max-width: 899px) {
  .thank-you-container {
    flex-direction: column;
  }
  .ing-thank-you-image {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 522px) {
  .ing-thank-you-title {
    flex-direction: column;
    align-self: baseline;
    align-items: flex-start;
    margin-bottom: 40px;
    h2 {
      margin-left: 0;
      font-size: 28px;
      line-height: 36px;
      padding-bottom: 0;
    }
  }
  .submit-icon {
    margin: 20px 0;
    width: 32px;
  }
  .ing-thank-you-text {
    p {
      margin-left: 0;
    }
  }
}
