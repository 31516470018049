
@import '@/sass/components/common/ing.orange.juice';

.terms-and-conditions-container {
  padding-top: px2rem(29px);
  word-spacing: px2rem(1.8px);
  &__para1 {
    margin-bottom: px2rem(44px);
  }
}
