/* Generic Mixins */
/*
Media Queries Mixin
*/
@use "sass:math";

@mixin bp($point) {
     @if $point == mobile{
        @media (max-width: 768px) {
             @content;
        }
    }
    @else if $point == mobile-small {
         @media (max-width: 480px){
            @content;
        }
    }
    @else if $point == mobile-tiny {
      @media (max-width: 363px){
        @content;
      }
    }
    @else if $point == desktop {
       @media (min-width: 769px) {
         @content;
       }
     }
    @else if $point == desktop-medium-1 {
      @media (max-width: 849px) {
        @content;
      }
    }
    @else if $point == desktop-medium {
      @media (min-width: 850px) {
        @content;
      }
    }
    @else if $point == desktop-big-1 {
      @media (max-width: 1079px) {
        @content;
      }
    }
    @else if $point == desktop-big {
      @media (min-width: 1080px) {
        @content;
      }
    }
    @else if $point == desktop-large {
      @media (max-width: 1237px) {
        @content;
      }
    }

}
/*
Hide Text Mixin
*/
@mixin hide-text {
    overflow: hidden;
    text-indent: -9000px;
    display: block;
}
/*
Clearfix Mixin
*/
@mixin clearfix() {
    &:after,
    &:before {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}
/*
Box-sizing Mixin
*/
@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model; // Safari <= 5
    -moz-box-sizing: $box-model; // Firefox <= 19
    box-sizing: $box-model;
}
/*
Border-radius Mixin
*/
@mixin border-radius($radius) {
     @each $prefix in -webkit-, -moz-, -ms-, -o-,''{
        #{$prefix}border-radius: $radius;
    }
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
}
/*
Box Shadow Mixin
*/
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
     @if $inset{
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    }
    @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}
/*
Opacity Mixin
*/
@mixin opacity($opacity) {
     @each $prefix in -webkit-, -moz-, -ms-, -o-,''{
        #{$prefix}opacity: $opacity;
    }
    $opacity-ie: $opacity * 100;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$opacity-ie+")"; // first!
    filter: alpha(opacity=$opacity-ie); //IE8
}
/*
Center Block Mixin
*/
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
/*
Font-size Mixin (Original)
*/
// @mixin font-size($size: 24, $base: 16) {
//   font-size: $size + px;
//   font-size: ($size / $base) * 1rem;
// }
/*
Font-size Mixin -- Modified to standardize font sizes
*/
@mixin font-size($size: 24, $base: 16) {
     @if $size < 14{
        $size: 12;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 12;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 12;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }
    @else if $size < 16 {
        $size: 14;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 14;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 12;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }
    @else if $size < 18 {
        $size: 16;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 14;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 12;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }
    @else if $size < 22 {
        $size: 19;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 17;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 15;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }
    @else if $size < 24 {
        $size: 22;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 19;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 16;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }
    @else if $size < 28 {
        $size: 26;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 24;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 20;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }
    @else if $size < 30 {
        $size: 28;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 24;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 20;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }
    @else if $size < 35 {
        $size: 30;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 26;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 22;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }
    @else if $size < 40 {
        $size: 35;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 30;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 26;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }
    @else if $size < 45 {
        $size: 40;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 32;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 28;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }
    @else if $size < 60 {
        $size: 50;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 36;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 30;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }
    @else if $size == 60 {
        $size: 60;
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
        @include bp(mobile) {
            $size: 40;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
        @include bp(mobile-small) {
            $size: 30;
            font-size: $size + px;
            font-size: math.div($size, $base) * 1rem;
        }
    }

     @else if $size >= 64 {
         $size: 64;
         $lineHeight: 64;
         font-size: $size + px;
         font-size: math.div($size, $base) * 1rem;
         line-height: $lineHeight + px;
         @include bp(mobile) {
             $size: 64;
             $lineHeight: 70;
             font-size: $size + px;
             font-size: math.div($size, $base) * 1rem;
             line-height: $lineHeight + px;
         }
         @include bp(mobile-small) {
             $size: 32;
             $lineHeight: 38;
             font-size: $size + px;
             font-size: math.div($size, $base) * 1rem;
             line-height: $lineHeight + px;
         }
     }
    @else {
        font-size: $size + px;
        font-size: math.div($size, $base) * 1rem;
    }
}
/*
CSS3 Mixin
*/
@mixin css3($property, $value) {
     @each $prefix in -webkit-, -moz-, -ms-, -o-,''{
        #{$prefix}#{$property}: unquote($value);
    }
}
/*
Transform Mixin
*/
@mixin transition-css3($value) {
     @each $prefix in -webkit-, -moz-, -ms-, -o-,''{
        #{$prefix}transition: unquote($value);
    }
}
/*
Background Color HEX to RGBA
*/
@mixin hex-to-rgba($property, $color, $opacity: 1) {
    #{$property}: rgba($color, $opacity);
}
@mixin reset-text() {
    font-family: $font-family-base;
    // We deliberately do NOT reset font-size.
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: $line-height-base;
    text-align: left; // Fallback for where `start` is not supported
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
}
