.tabs {
    position: relative;
    .hide-desktop {
        display: none;
    }
    @include bp(mobile) {
        .hide-mobile {
            display: none;
        }
        .hide-desktop {
            display: inline-block;
        }
    }
}
.tabs nav {
    text-align: center;
    position: relative;
    display: block;
}
.tabs nav .mobile-moving-indicator,
.tabs nav .moving-indicator {
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    background: $darkerGrey;
    @include css3( 'transition', 'all ease-in-out .2s');
    &.green {
        background: $petroleum;
    }
    @include bp(mobile) {
        display: none;
    }
}
.tabs nav .tab {
    a {
        display: table;
        position: relative;
        padding: 40px 0;
        margin: 0 auto;
        //border-bottom: solid 2px rgba($petroleum, 0);
        line-height: 1;
        cursor: pointer;
        font-weight: $semibold;
        color: $grey;
        text-transform: uppercase;
        text-decoration: none;
        @extend .transition;
        @include font-size(16);
        &:focus,
        &:hover {
            color: $darkerGrey;
            text-decoration: none;
            //border-bottom: solid 2px rgba($darkerGrey, 1);
        }
    }
    &.active a {
        &,
        &:focus,
        &:hover {
            color: $darkerGrey;
            text-decoration: none;
            //border-bottom: solid 2px rgba($petroleum, 1);
        }
    }
    &.inactive a {
        &,
        &:focus,
        &:hover {
            color: $grey;
            cursor: default;
            @include opacity(.5);
            //border-bottom: solid 2px rgba($petroleum, 0);
        }
    }
}
.tabs nav .tab {
    @include bp(mobile) {
        display: none;
        &.active {
            display: block;
        }
        a {
            padding: 25px 0;
            max-width: 70%;
            margin: 0 auto !important;
            display: block;
        }
    }
}
.tabs nav .tab {
    @include bp(mobile) {
        display: none;
        &.active {
            display: block;
        }
        a {
            padding: 25px 0;
            max-width: 70%;
            margin: 0 auto !important;
            display: block;
        }
    }
}
.tabs.dark nav {
    background: $darkerGrey;
    .mobile-moving-indicator,
    .moving-indicator {
        background: $white;
    }
}
.tabs.image {
    position: relative;
    .with-overlay {
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: rgba($darkerGrey, .5);
        }
    }
    nav {
        z-index: 100;
        .mobile-moving-indicator,
        .moving-indicator {
            background: $white;
        }
    }
}
.tabs.dark nav .tab,
.tabs.image nav .tab {
    a {
        //border-bottom: solid 2px rgba($white, 0);
        color: $white;
        &:focus,
        &:hover {
            color: $white;
            //border-bottom: solid 2px rgba($white, 1);
        }
    }
    &.active a {
        &,
        &:focus,
        &:hover {
            color: $white;
            //border-bottom: solid 2px rgba($white, 1);
        }
    }
    &.inactive a {
        &,
        &:focus,
        &:hover {
            color: $grey;
            cursor: default;
            //border-bottom: solid 2px rgba($white, 0);
        }
    }
}
.tabs section {
    @include css3( 'transition', 'all .15s');
    @include css3( 'transform', 'translate3d(0, 0, 0)');
    .tab-content {
        position: relative;
        display: block;
        & {
            @include opacity(1);
            @include css3( 'transform', 'translate3d(0, 0, 0) scale(1)');
            @extend .transition;
            top: 0;
        }
        &.ng-leave {
            @include opacity(1);
            top: 0;
            @include css3( 'transform', 'translate3d(0, 0, 0) scale(1)');
            @include css3( 'transition', 'all .1s 0s');
        }
        &.ng-enter {
            @include opacity(0);
            top: 50px;
            @include css3( 'transform', 'translate3d(0, 0, 0) scale(.85)');
            @include css3( 'transition', 'all .3s .3s');
            //position: absolute;
        }
        &.ng-enter.ng-enter-active {
            @include css3( 'transform', 'translate3d(0, 0, 0) scale(1)');
            @include opacity(1);
            top: 0;
        }
        &.ng-leave.ng-leave-active {
            @include opacity(0);
            top: 50px;
            @include css3( 'transform', 'translate3d(0, 0, 0) scale(.85)');
            position: absolute;
        }
        &.no-ng {
            @include opacity(0);
            @include css3( 'transform', 'translate3d(0, 0, 0) scale(.85)');
            @include css3( 'transition', 'all .15s .15s');
            position: absolute;
            top: 50px;
            &.active {
                position: relative;
                display: block;
                @include opacity(1);
                @include css3( 'transform', 'translate3d(0, 0, 0) scale(1)');
                @include css3( 'transition', 'all .31s .15s');
                top: 0;
            }
        }
    }
}
.submenu.tabs nav {
    .submenu-toggler {
        @extend .transition;
        z-index: 10;
    }
    .tab.dropdown .subitems {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: none;
        z-index: 1;
        margin-top: -1px;
        .subitem {
            background: $darkerGrey;
            @include css3 ('box-shadow', '0 0 0 1px #9d9d9d');
        }
        .subitem.active,
        .subitem:hover {
            background: $petroleum;
        }
    }
    .tab.dropdown {
        padding-right: 40px;
        border: solid 1px rgba($grey, 0);
        border-top: none;
        &:after {
          // moved to cdn background-image: url('../assets/images/img-arrow_down-white.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            width: 60px;
            height: 55px;
            position: absolute;
            display: block;
            right: 0;
            top: 0;
            content: '';
            @extend .transition;
        }
        &:hover {
            border: solid 1px $grey;
            border-top: none;
            &:after {
                @include css3( 'transform', 'rotate(-180deg)');
            }
        }
        &:hover .subitems {
            display: block;
        }
    }
    .tab {
        @include bp(mobile) {
            a {
                max-width: 100%;
            }
        }
    }
    .tab.dropdown.closed .subitems,
    .tab.dropdown.closed:hover .subitems {
        display: none !important;
        @include bp(mobile) {
            display: block !important;
        }
    }
    @include bp(mobile) {
        max-width: 100%;
        .submenu-toggler {
          // moved to cdn background-image: url('../assets/images/img-arrow_down-white.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            width: 60px;
            height: 55px;
            position: absolute;
            display: block;
            right: 0;
            top: 0;
        }
        &.open .submenu-toggler {
            @include css3( 'transform', 'rotate(-180deg)');
        }
        .tab {
            display: none;
            text-align: left;
            // margin: 0 auto;
            padding: 0 20px;
            width: 100% !important;
            a {
                color: $grey;
                @include font-size(16);
                font-weight: $normal;
                text-align: left;
                margin: 0;
                width: 100%;
                display: block;
            }
            &.active {
                display: block;
                > a {
                    color: $white;
                    @include font-size(16);
                    font-weight: $bold;
                }
            }
        }
        &.open .tab {
            display: block;
            border-bottom: solid 1px $grey;
        }
        &.open .tab.dropdown .subitems {
            display: block !important;
        }
        .tab.dropdown .subitems {
            display: none;
            position: relative;
            .subitem {
                @include css3( 'box-shadow', 'none');
                border-top: solid 1px $grey;
                text-align: center;
                background: none;
                font-weight: $normal;
                a {
                    padding-left: 20px;
                    padding-right: 20px;
                    text-align: center;
                    margin: 0 auto;
                    color: $white;
                    @include font-size(16);
                    font-weight: $normal;
                }
            }
            .subitem.active,
            .subitem:focus,
            .subitem:hover {
                background: none;
                a {
                    font-weight: $semibold;
                }
            }
        }
        .tab.dropdown {
            padding-left: 0;
            padding-right: 0;
            a {
                padding: 20px !important;
            }
            border: none;
            &:after {
                content: none;
            }
            &.active,
            &:hover {
                border: none;
            }
            &:hover .subitems {
                display: none;
            }
        }
    }
}
.tabs .mobile-nav {
    svg {
        width: 100%;
        height: 100%;
        display: block;
    }
    a.next,
    a.prev {
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        top: 50%;
        z-index: 1;
        margin-top: -15px;
        svg * {
            fill: $white;
        }
        svg circle {
            fill: $petroleum;
        }
    }
    a.prev {
        left: 20px;
    }
    a.next {
        right: 20px;
    }
    .mobile-moving-indicator {
        width: 0;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        background: $darkerGrey;
        @include css3( 'transition', 'all ease-in-out .2s');
        @extend .h_centered_abs_pos_lm_height;
        &.green {
            background: $petroleum;
        }
    }
}

//impersonate page css

.imp-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 600px;
  height: 300px;
  margin: 0px auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #21ebfd;
  border-radius: 10px;
}

.imp-inside-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 590px;
  height: 290px;
  padding-right: 50px;
  padding-left: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
  background-color: #cffdff;
}

.imp-header {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.imp-impersonated-text {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
}

.imp-impersonator-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
}

.imp-lendico-icon {
  margin-right: auto;
  margin-left: auto;
}

// we push the screen in, text is too big, it removes margins to save space, by default they are 20 px
// from: max 1237 to min 1080
@include bp(desktop-large) {
  .no--margin {
    margin-right: 0px !important;
  }
}

// changes the middle stage navbar's color to grey - white - white
// small screen stuff activated
// from max 1079 to min 0
@include bp(desktop-big-1) {
  .big--screen {
    display: none !important;
  }
  .router-link-sub-menu {
    color: #AFAFAF !important;
  }
  /* important, and conditionally used */
  .router-link-exact-active{
    color: #FFFFFF !important;
  }
  .router-link-sub-menu:hover {
    color: #FFFFFF !important;
  }
}

// big screen stuff activated
// from min 1080 to endless
@include bp(desktop-big) {
  .small--screen {
    display: none !important;
  }
}

// in smaller mobiles the long logo might push the logout button to other place
// from max 363 to 0
@include bp(mobile-tiny) {
  .company-logo {
    width: 100px;
  }
}
// mobile browser place the logout button into it;s proper place
// 0 - max 768
@include bp(mobile) {
  .main-navigation-ctas {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

// desktop/tablet browser puts logout button into it's proper place
// 769 -  endless
@include bp(desktop) {
  .main-navigation-ctas {
    padding-top: 29px !important;
    padding-bottom: 29px !important;
  }
}

// helping bootstrap to actually work in lender dashboard page
.table--layout {
  width: 100%;
  table-layout: fixed;
}
.column--half {
  width: 50%;
}
.column--quarter {
  width: 25%;
}
// a header doesn t fit in mobile browser, so conditionally set smaller font
@include bp(desktop-medium-1) {
  .responsive--size {
    font-size:0.75rem !important;
  }
}
@include bp(desktop-medium) {
  .responsive--size {
    font-size:0.875rem !important;
  }
}

.image {
  position: absolute;
  display: inline;
  overflow: hidden;
  width: 100%;
  height: 266px;
}

.div-block {
  position: absolute;
  display: block;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading {
  position: absolute;
  top: 50%;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.div-block-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-2 {
  position: absolute;
  bottom: 40%;
  font-family: 'Source Sans Pro', sans-serif;
  color: #fff;
  font-size: 60px;
  line-height: 66px;
  font-weight: 300;
  text-transform: none;
}

.image-2 {
  width: 100%;
  height: 300px;
}

.text-block {
  font-family: 'Source Sans Pro', sans-serif;
  color: #3cbdb9;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  // text-transform: uppercase;
}

.text-block-2 {
  font-family: 'Source Sans Pro', sans-serif;
}

._404-link {
  color: #3cbdb9;
  text-decoration: none;
}

._404-link:hover {
  text-decoration: underline;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 300px;
  padding-right: 350px;
  padding-left: 350px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .heading-2 {
    font-size: 40px;
  }
}

@media (max-width: 479px) {
  .heading-2 {
    bottom: 30%;
    font-size: 25px;
  }
  .image-2 {
    height: 200px;
  }
}
