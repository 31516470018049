
@import '../../../sass/custom/settings';
@import '../../../sass/custom/variables';
.edit-button-container {
  display: flex;
  flex-direction: row;
  height: fit-content;
  cursor: pointer;
  align-items: center;
  position: relative;
  z-index: 9;
  p {
    font-size: 19px;
    color: $ing-orange;
    margin-left: 10px;
    font-weight: bold;
    margin-bottom: 0;
  }
}
.edit-icon-size {
  width: 24px;
  height: 24px;
}
