
@import '@/sass/components/common/ing.orange.juice';

.ing-modal {
  display: block;

  &__dialog {
    width: calc(100% - 1rem);
    max-width: 1022px;
    border-radius: 1rem;
    overflow: hidden;
    height: fit-content;
    z-index: 1041;
  }
}

.ing-form {
  text-align: left;
  padding: px2rem(24) px2rem(18);

  @include mq-size(s) {
    padding: px2rem(40) px2rem(68) px2rem(40) px2rem(64);
  }

  @include mq-size(m) {
    padding: px2rem(32) px2rem(80) px2rem(104) px2rem(80);
  }

  &__message {
    margin-bottom: px2rem(68);
    @include mq-size(s) {
      margin-bottom: px2rem(40);
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;

    @include mq-size(m) {
      padding: 0 px2rem(105);
    }

    > .button-right {
      align-self: flex-end;
    }
  }
}

.auth-form {
  &--title {
    font-size: px2rem(36);
    color: $Primary-Orange;
    line-height: px2rem(48);
    font-weight: 400;
    padding-bottom: 10px;
  }

  &--explanation {
    font-size: px2rem(19);
    line-height: px2rem(28);
    color: $Primary-Grey600;
    margin: 0 auto;
  }
}
