.filters,
.loan-calculator{
    h4 {
        @include font-size(16);
        color: $white;
        text-align: left;
    }
    input {
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
        color: $white;
        @include font-size(24);
        font-weight: $semibold;
        width: 100%;
        text-align: right;
        padding-right: 10px;
    }
    legend {
        @include font-size(12);
        padding: 0 6px;
        margin: 0;
        min-width: 0;
    }
    p,
    p.caption {
        @include font-size(14);
        color: $white;
    }
    p.caption {
        color: $grey;
    }
    dl,
    table {
        width: 100%;
        margin-bottom: 0;
        @extend .transition;
        dt {
            width: 100%;
            display: table;
            vertical-align: middle;
            @extend .transition;
            padding: 5px 10px;
            border: solid 1px $white;
        }
        &.dropdown {
            cursor: pointer;
            &:after {
                // moved to cdn background-image: url("../assets/images/img-arrow_down-white.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 20px 20px;
                width: 45px;
                height: 55px;
                position: absolute;
                display: block;
                right: 0;
                top: 0;
                content: '';
            }
            position: relative;
            &.open dd,
            &.open dt {
                background-color: rgba($darkerGrey, .9) !important;
            }
            dt {
                padding-right: 45px;
            }
            dt div {
                text-align: left;
            }
            dd {
                //display: none;
                @extend .transition;
                width: 100%;
                display: none;
                position: absolute;
                top: 100%;
                z-index: 1;
                div {
                    text-align: left;
                    padding: 5px 10px;
                    border: solid 1px $white;
                    border-top: none;
                    cursor: pointer;
                }
            }
            &.open {
                dd {
                    display: block;
                }
            }
        }
        dt div,
        tr td {
            @include font-size(24);
            color: $grey;
            font-weight: $normal;
            display: table-cell;
            text-align: right;
            vertical-align: middle;
            label,
            span {
                @include font-size(24);
            }
            strong {
                @include font-size(24);
                font-weight: $semibold;
                color: $white;
            }
            text-align: right;
            &:first-child {
                text-align: left;
            }
        }
    }
    &.big {
        h4 {
            @include font-size(16);
        }
        input {
            @include font-size(30);
        }
        dl,
        table {
            div,
            td {
                @include font-size(30);
                label,
                span {
                    @include font-size(30);
                }
                strong {
                    @include font-size(30);
                }
            }
        }
        .button,
        button {
            // padding-top: 18px !important;
            // padding-bottom: 18px !important;
            padding-top: 8px !important;
            padding-bottom: 8px !important;
            margin-bottom: 7px;
            margin-top: 18px;
            width: 100%;
            display: block;
        }
    }
}
.white-section .filters {
    h4 {
        @include font-size(14);
        color: $darkGrey;
        text-align: left;
        text-transform: uppercase;
        text-align: center;
    }
    p,
    p.caption {
        color: $darkGrey;
        @include font-size(14);
        text-align: center;
    }
    dl,
    table {
        dt {
            border: solid 1px $darkGrey;
        }
        &.dropdown {
            &:after {
                // moved to cdn background-image: url("../assets/images/img-arrow_down-dark.svg");
                background-size: 15px 15px;
                width: 30px;
                height: 40px;
            }
            position: relative;
            &.open dd,
            &.open dt {
                background-color: rgba($white, 1) !important;
            }
            dt {
                max-width: 100%;
                display: block;
                overflow: hidden;
                padding: 10px 7px;
            }
            dd {
                div {
                    padding: 10px 7px;
                    border: solid 1px $darkGrey;
                    border-top: none;
                }
            }
        }
        dd div,
        dt div,
        tr td {
            @include font-size(14);
            color: $darkGrey;
            font-weight: $normal;
            label,
            span {
                @include font-size(14);
            }
            strong {
                @include font-size(14);
                font-weight: $semibold;
                color: $darkerGrey;
            }
        }
    }
}
.filters {
    .ui-button,
    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default {
        @include border-radius(50%);
        background: $white;
        border: solid 1px $darkGrey;
        cursor: pointer;
    }
    .ui-widget-header {
        background: $petroleum;
    }
    .ui-widget-content {
        background: $white;
        border: solid 1px $darkGrey;
        @include border-radius(0);
    }
}
