
@import '@/sass/components/common/ing.orange.juice';

.submit-buttons {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  margin-bottom: 2rem;

  @include mq-size(m) {
    flex-direction: row;
    justify-content: flex-end;
  }
}
