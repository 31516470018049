
@import '../../../sass/custom/variables';
h2 {
  font-weight: bold;
}

.ing-offer-details-container {
  margin: 40px 0;
  line-height: 27px;
  color: $ing-gray-advantages;
  em {
    color: $ing-dark-gray;
  }
}

.explanatory-text {
  font-size: 14px;
  line-height: 22px;
  color: #696969;
}
