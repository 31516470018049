
.seller-central-button-wrapper {
  padding-top: 0;
  padding-bottom: 57px;
}

@media screen and (max-width: 768px) {
  .thank-you-section {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .seller-central-button-wrapper {
    padding-bottom: 14px;
  }

  .contact-us-section {
    padding-top: 50px;
  }
}

@media (max-width: 522px) {
  .seller-central-button-wrapper {
    padding-bottom: 0;
  }

  .contact-us-section {
    padding-top: 58px;
  }
}
