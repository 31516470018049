
.modal-inner {
  font-size: 16px;
  line-height: 24px;
  color: #333;
  letter-spacing: -0.02em;
  padding-bottom: 50px;

  &__header {
    text-transform: none;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
}
