
@import '@/variables.scss';

.salutation-container {
  display: flex;
}
.salutation-container > label {
  margin-right: 30px;
  margin-left: 0;
}
.validation {
  text-align: left;
  margin-top: 2px;
  margin-bottom: 8px;
}
@media (max-width: $mobile-l) {
  .validation {
    margin: 0;
  }
}
