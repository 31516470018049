/* Brand Colours */
$petroleum: #29bdba;
$darkNavy: #00667b;
$grey: #9d9d9d;
$darkerGrey: #2e2e2e;
$darkGrey: #575757;
$lightGrey: #e3e3e3;
$mediumGrey: #808080;
$white: #fff;
$whiteSmoke: #f5f5f5;

$validation: #D64245;

/* Legends Colours */
$green: #93c755;
$emerald: #33c19e;
$blue: #3f91c7;
$violet: #8a4894;
$orange: #e74c3c;
$yellow: #e9dc2c;
$red: #f71224;


/* Font Weights */
$bold: 700;
$semibold: 600;
$normal: 400;
$light: 300;
