
@import '@/sass/components/common/ing.orange.juice';
.ing-select {
  font-family: inherit;
  align-items: stretch;
  padding-bottom: px2rem(33px);
  &__label {
    display: block;
    font-family: inherit;
    color: $Primary-Grey600;
    font-size: px2rem(16px);
    font-weight: 400;
    line-height: px2rem(24px);
  }
  &__caption {
    font-family: inherit;
    font-size: px2rem(14px);
    font-weight: 400;
    line-height: px2rem(20px);
    margin-bottom: 4px;
    color: $Primary-Grey400;
    text-align: left;
    width: 100%;
  }
  &__status {
    font-size: px2rem(16px);
    margin: 6px 0 0;
  }
}
