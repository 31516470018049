
.country-field-wrapper,
.country-input-wrapper {
  position: relative;
}
.country-input {
  &::placeholder {
    color: #333;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #333;
  }
  &::-ms-input-placeholder {
    color: #333;
  }
}
.icon {
  position: absolute;
  cursor: pointer;
  width: 14px;
  height: 8px;
  right: 12px;
  top: 15px;
}
.form-control {
  cursor: pointer;
}
.ing-country-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 100%;
  width: 100%;
  ul {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 100%;
    z-index: 1;
  }
  .dropdown-item {
    cursor: pointer;
    padding: 4px 0px;
    display: flex;
    align-items: center;
  }
  .dropdown-item.highlighted {
    background-color: #f3f3f3;
  }
  .iti-flag {
    margin-right: 5px;
    margin-left: 5px;
  }
  li.last-preferred {
    border-bottom: 1px solid #cacaca;
  }
  li {
    min-height: 30px;
    padding: 4px 0;
  }
  strong {
    font-size: 16px;
    font-weight: bold;
  }
}
