
@import '@/sass/components/common/ing.orange.juice';
.ident-type-warning {
  max-width: 500px;

  .field-status {
    margin-left: 2.4rem;
  }
  &__link {
    color: $Secondary-Indigo500;
    font-weight: 700;
    text-decoration: underline;
    margin-left: 4rem;
  }
}
