
@import 'src/sass/components/common/ing.orange.juice.scss';

.open-preview-text {
  margin-right: 10px;
  cursor: pointer;
  height: 36px;
  font-size: 16px;
}
.modal {
  &.hidden {
    visibility: hidden;
  }

  display: block;

  .modal-content {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 100vh;
  }
  .pagination-container {
    display: flex;
    flex-direction: row;
    width: 200px;
    justify-content: space-between;
    position: relative;
    bottom: 20px;
    margin: 0 auto;
    padding: 5px;
    .pagination-arrow {
      cursor: pointer;
      height: 20px;
    }
    .footer-pagination {
      color: $Primary-Grey800;
      font-weight: bold;
      font-size: 14px;
    }
  }
  .close-icon {
    padding-left: 5px;
  }
  .close-modal-container {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 24px;
    right: 24px;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    color: $Primary-Orange;
    font-weight: bold;
    font-size: 14px;
  }
}
