
@import '@/variables.scss';

.form-header {
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  margin-bottom: 60px;

  @media screen and (max-width: $tablet) {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: $mobile-l) {
    font-size: 27px;
    line-height: 36px;
    text-align: center;
  }
}
.ing-form .section-form {
  transition: opacity 0.3s ease;
  opacity: 1;
}
.ing-form .section-form.fade {
  opacity: 0.15;
}
