
@import '@/sass/components/common/ing.orange.juice';
.field-status {
  font-size: px2rem(16px);
  margin: 6px 0 0;
  &__content {
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-right: 10px;
    flex-basis: px2rem(18);
    flex-shrink: 0;
    align-self: flex-start;
    margin-top: 3px;
  }
}
