
@import '@/variables.scss';
@import '@/sass/components/common/ing.orange.juice';
.ing-checkbox-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &--focus {
    .label-checkbox .check {
      border: 1px solid $Primary-Orange;
    }
  }

  label.label-checkbox {
    font-weight: normal;
    font-size: 16px;
  }
}
.ing-terms-container {
  margin-top: 40px;

  @media screen and (max-width: $mobile-l) {
    margin-top: 20px;
  }
}
