
@import '@/sass/components/common/ing.orange.juice';
.not-found {
  max-width: 740px;
  margin: 0 auto;
  &__title {
    font-size: 40px;
    line-height: 56px;
    color: $Primary-Orange;
    padding: 24px 0;
  }
  &__content {
    font-size: 16px;
    line-height: 24px;
    color: $Primary-Grey400;
  }
}
