.button,
.cta,
a.button,
button.button,
a.cta {
    @extend .transition;
    display: inline-block;
    position: relative;
    text-decoration: none !important;
    //line-height: 1;
    line-height: inherit;
    font-weight: $semibold;
    vertical-align: top;
    cursor: pointer;
}
.cta,
a.cta {
    @include font-size(18);
    color: $petroleum;
    &.active,
    &:focus,
    &:hover {
        color: $darkNavy;
        text-decoration: none;
    }
    &.inactive,
    &:disabled {
        &,
        &.active,
        &:focus,
        &:hover {
            color: $petroleum;
            @include opacity(.5);
            text-decoration: none;
            cursor: default;
            font-weight: $semibold;
        }
    }
    &.white-hover:hover{
      color: $white;
    }
}
.button,
a.button,
button.button {
    background: $petroleum;
    color: $white;
    border: none;
    border-radius: 8px;
    padding: 16px 32px;
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    font-weight: $semibold;
    -webkit-font-smoothing: antialiased;
    //@extend .u-pv;
    //@extend .u-ph\+;
    //min-width: 273px;
    &.full-width {
        width: 100%;
    }
    &.active,
    &:focus,
    &:hover {
        background: $darkNavy;
        text-decoration: none;
        color: $white;
        font-weight: $semibold;
    }
    &.inactive,
    &:disabled {
        &,
        &.active,
        &:focus,
        &:hover {
            background: $petroleum;
            @include opacity(.5);
            text-decoration: none;
            color: $white;
            cursor: default;
            font-weight: $semibold;
        }
    }
    &.no-outline {
        background: rgba($white, 0);
        border: none;
        color: $petroleum;
    }
    &.outline {
        background: rgba($white, 0);
        border: solid 1px rgba($petroleum, 1);
        color: $petroleum;
        // &.active,
        // &:focus,
        // &:hover {
        //     background: rgba($white, 0);
        //     border: solid 1px rgba($darkNavy, 1);
        //     color: $darkNavy;
        //     text-decoration: none;
        //     font-weight: $semibold;
        // }
        &.active,
        &:focus,
        &:hover {
            background: rgba($darkNavy, 1);
            border: solid 1px rgba($darkNavy, 1);
            color: $white;
            text-decoration: none;
            font-weight: $semibold;
        }
        &.inactive,
        &:disabled {
            background: rgba($white, 0);
            border: solid 1px rgba($petroleum, 1);
            color: $petroleum;
            @include opacity(.5);
            text-decoration: none;
            cursor: default;
            font-weight: $semibold;
        }
    }
    &.sm {
        @include font-size(16);
        @include bp(mobile) {
            padding-right: 10px;
            padding-left: 10px;
        }
        padding-right: 20px;
        padding-left: 20px;
        font-weight: $semibold !important;
        //min-width: 230px;
    }
    &.dark.outline {
        background: rgba($white, 0);
        border: solid 1px rgba($white, 1);
        color: $white;
        // &.active,
        // &:focus,
        // &:hover {
        //     background: rgba($white, 0);
        //     border: solid 1px rgba($petroleum, 1);
        //     color: $petroleum;
        // }
        &.active,
        &:focus,
        &:hover {
            background: rgba($darkNavy, 1);
            border: solid 1px rgba($darkNavy, 1);
            color: $white;
        }
        &.inactive,
        &:disabled {
            background: rgba($white, 0);
            border: solid 1px rgba($white, 1);
            color: $white;
        }
    }
    &.light.outline {
        background: rgba($darkerGrey, 0);
        border: solid 1px rgba($darkerGrey, 1);
        color: $darkerGrey;
        // &.active,
        // &:focus,
        // &:hover {
        //     background: rgba($darkerGrey, 0);
        //     border: solid 1px rgba($petroleum, 1);
        //     color: $petroleum;
        // }
        &.active,
        &:focus,
        &:hover {
            background: rgba($darkNavy, 1);
            border: solid 1px rgba($darkNavy, 1);
            color: $white;
        }
        &.inactive,
        &:disabled {
            background: rgba($darkerGrey, 0);
            border: solid 1px rgba($white, 1);
            color: $white;
        }
    }
}

@media (min-width: 769px) {
  .button.sm.outline.light{
    position: fixed;
    top: 30px;
    left: 80%;
  }
}

@media (max-width: 768px) {
  .button.sm.outline.light{
    position: fixed;
    top: 1%;
    left: 80%;
  }
}

@media (max-width: 530px) {
  .button.sm.outline.light{
    position: fixed;
    top: 1%;
    left: 73%;
  }
}

@media (max-width: 328px) {
  .button.sm.outline.light{
    position: fixed;
    top: 1%;
    left: 68%;
  }
}
