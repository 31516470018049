
.company-trading-name-wrapper {
  position: relative;
}

.company-name-tooltip {
  position: absolute;
  left: 80px;
  top: 5px;
  &.on-input-focus {
    top: -12px;
  }
}
