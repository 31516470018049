
@import '@/sass/custom/_settings.scss';
@import '@/variables.scss';

.help-message {
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;

  .base-link {
    font-weight: $bold;
  }
}

.error-wrapper {
  margin-top: 50px;

  .validation {
    font-size: 18px;
    line-height: 24px;
    .error-link {
      color: $validation;
    }
  }
}
.partner-general-error {
  font-size: 14px;
  text-align: center;
}
#serverError {
  padding-top: 20px;
}
.button {
  margin-top: 50px;

  &.has-error {
    margin-top: 20px;
  }
}
@media screen and (max-width: $mobile-l) {
  .help-message {
    font-size: 16px;
    line-height: 18px;
  }
  .error-wrapper {
    margin-top: 10px;
  }
  .button {
    margin-top: 15px;

    &.has-error {
      margin-top: 20px;
    }
  }
}
