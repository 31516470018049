
.legal-status {
  position: relative;

  &__not-supported {
    position: absolute;
    left: 0;
    top: 40px;
    font-size: 14px;
    line-height: 14px;
    color: #3c31c4;
    padding-left: 20px;
    background: url('~@/assets/img/len_info_icon.svg') 0 0 no-repeat;
  }
}
