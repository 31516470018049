
@import '@/sass/components/common/ing.orange.juice';
.ident-types {
  display: flex;
  flex-direction: column;
  @include mq-size(m) {
    flex-direction: row;
  }
  .ident-type-automation {
    flex: 5.5;
  }
  .ident-type-manual {
    flex: 4.5;
  }

  .radio-group {
    padding-bottom: 0;
  }

  .radio-container__label {
    font-weight: 700;
  }

  .sub-text {
    font-weight: 400;
    font-size: px2rem(16px);
    line-height: 24px;
    color: $Primary-Grey600;
    padding: 0.5rem 2.4rem;

    .warning-icon {
      position: relative;
      top: 2px;
    }
  }
}
