
@import '@/sass/components/common/ing.orange.juice';
.ing-page-header {
  &__title {
    font-size: 36px;
    line-height: 48px;
    color: $Primary-Orange;
    font-weight: 400;

    @include mq-size(m) {
      width: 89.6%;
    }
    @include mq-size(xl) {
      width: 69.3%;
    }
    &--bottom-sm {
      padding-bottom: 32px;
    }
  }
  &__content {
    font-size: 19px;
    line-height: 28px;
    @include mq-size(m) {
      width: 89.6%;
    }
    @include mq-size(xl) {
      width: 69.3%;
    }
  }
}
