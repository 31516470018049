
.checkbox {
  .checkbox-material span.check {
    z-index: 0;
  }
  span.check.error {
    border: 2px solid #d64245;
  }
  p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
  }
}
.validation {
  text-align: left;
  padding-left: 30px;
  padding-top: 5px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 14px;
}
