
@import '@/sass/components/common/ing.orange.juice';
.ing-the-footer {
  background: $Primary-Grey200;
  font-size: 16px;
  &__linklist {
    margin: 0 auto;
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    @include mq-size(m) {
      flex-direction: row;
      padding: 24px;
    }
  }
  &__link {
    color: $Primary-Grey600;
    line-height: 24px;
    display: inline-block;
    padding-bottom: 12px;
    @include mq-size(m) {
      padding-right: 22px;
      margin-right: 22px;
      padding-bottom: 0;
      border-right: 1px solid $Primary-Grey400;
    }
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
}
