
@import '@/variables.scss';

.salutation-input-container {
  display: flex;
  flex-direction: row;
  margin: 8px 0 14px 0;

  .radio-container {
    margin-bottom: 0;

    &:first-child {
      margin-right: 20px !important;
    }
  }
}

@media screen and (max-width: $mobile-l) {
  .salutation-input-container {
    margin: 5px 0 2px 0;
  }
}
