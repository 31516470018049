// primary colors
@use 'sass:math';

$Primary-Orange: #ff6200;
$Primary-white: #fff;

$Primary-Grey800: #050505;
$Primary-Grey700: #212121;
$Primary-Grey600: #333;
$Primary-Grey500: #3d3d3d;
$Primary-Grey400: #696969;
$Primary-Grey300: #a8a8a8;
$Primary-Grey200: #d9d9d9;
$Primary-Grey100: #f0f0f0;

//secondary colors
$Secondary-Indigo500: #525199;
$Secondary-Sky500: #559bd1;
$Secondary-Fuchsia500: #ab0066;
$Secondary-Lime500: #d0d93c;
$Secondary-Leaf500: #349651;

//functional
$Functional-Minus500: #d70000;
$Functional-Minus400: #e14242;

//oneWeb Breakpoints
$oneWebBreakpoints: (
  xxs: 360px,
  xs: 480px,
  s: 600px,
  m: 840px,
  l: 960px,
  xl: 1280px,
  xxl: 1440px,
);

//generate mediaQuery based on breakpoint param
@mixin mq-size($breakpoint) {
  @media only screen and (min-width: map-get($oneWebBreakpoints, $breakpoint )) {
    @content;
  }
}

//hippo CMS
$fontSize: 16px;

// Breakpoints
$breakpoints: (
  small: 320px,
  medium: 768px,
  large: 1200px,
);

//scaling ratios
$smallRatio: 1;
$mediumRatio: 1.125;
$largeRatio: 1.375;

//fn to convert values in to rem based on html fontSize
@function px2rem($pxValue) {
  @return #{math.div(stripUnit($pxValue), stripUnit($fontSize))}rem;
}

@function stripUnit($value) {
  @return math.div($value, $value * 0 + 1);
}
