
@import 'src/sass/components/common/ing.orange.juice.scss';

.title {
  color: $Primary-Orange;
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 40px;
}
.identification-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}
.nextstep-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
}
.content {
  line-height: 24px;
  margin-bottom: 16px;
  color: $Primary-Grey600;
}
.p-last {
  margin-bottom: 40px;
}
.next-step-list {
  list-style: decimal;
  padding-left: 16px;
}
.next-step-item {
  line-height: 24px;
  padding-bottom: 20px;
}
.next-step-item:last-child {
  padding-bottom: 0;
}
