
@import '@/sass/components/common/ing.orange.juice';
.logout-page-container {
  min-height: calc(100vh - 150px);
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 840px) {
  .logout-page-container {
    min-height: calc(100vh - 240px);
  }
}

.title {
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  padding: 8rem 2rem 0rem 2rem;
  color: $Primary-Orange;
}

.sub-title {
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  padding: 2rem;
  color: $Primary-Grey600;
}
