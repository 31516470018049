
@import '../../../../sass/custom/settings';
.company-overview-container {
  display: flex;
  justify-content: space-between;
}
.edit-button-alignment {
  margin-left: auto;
}
.flex--column {
  flex-direction: column;
}

p {
  font-size: 14px;
  line-height: 21px;
}
.separator {
  border-top: 1px solid $lightGrey;
  width: 90%;
  margin: 20px 40px;
}
.overview-container {
  &.alert {
    border: solid 1px $red;
  }
}
@media (max-width: 990px) {
  .company-overview-container {
    flex-wrap: wrap;
    word-break: break-all;
  }
  .company-data-container {
    flex: 1 0 33%;
  }
  .edit-button-alignment {
    flex-basis: 100%;
    padding: 20px 0;
  }
}
@media (max-width: 400px) {
  .company-overview-container {
    flex-direction: column-reverse;
  }
}
