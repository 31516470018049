
@import '@/variables.scss';
@import '@/sass/components/common/ing.orange.juice';

.section-header {
  display: flex;
  padding-bottom: 2rem;
  flex-direction: column;
  gap: 1rem;

  @include mq-size(s) {
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
    color: $Primary-Grey600;
  }
}

.alert {
  border: solid 3px $Functional-Minus500;
  padding: 6px;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.company-info-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3%;
  &__col {
    flex-basis: 100%;
    box-sizing: content-box;
    @include mq-size(s) {
      flex-basis: 87.15%;
    }
    @include mq-size(m) {
      flex-basis: 48.5%;
    }
    @include mq-size(l) {
      flex-basis: 48.5%;
    }
  }

  .section {
    display: flex;
    column-gap: 3%;
    row-gap: 48px;
    flex-wrap: wrap;
    padding-bottom: 48px;
    &:last-child {
      padding-bottom: 0;
    }
    &__list {
      flex-basis: 100%;
      @include mq-size(xl) {
        flex-basis: 98.5%;
      }
    }
    &__address {
      white-space: pre-line;
    }
    &__row {
      display: flex;
      column-gap: 3%;
      flex-wrap: wrap;
      @include mq-size(s) {
        column-gap: 3%;
      }
      @include mq-size(xl) {
        column-gap: 5%;
      }
      &--divider {
        padding-top: 24px;
      }
      &--header {
        padding-bottom: 8px;
        @include mq-size(s) {
          text-align: right;
        }
      }
      &--header#{&} span {
        font-size: 19px;
        line-height: 28px;
        font-weight: 700;
      }
      .title {
        font-weight: 700;
        font-size: 19px;
        line-height: 28px;
        color: $Primary-Grey600;
      }
      span {
        font-size: 16px;
        line-height: 24px;
        flex-basis: 100%;
        word-break: break-all;
        @include mq-size(s) {
          flex-basis: 48.5%;
        }
        @include mq-size(xl) {
          flex-basis: 47.5%;
        }
        &:first-child {
          @include mq-size(s) {
            text-align: right;
          }
        }
        &:last-child {
          font-weight: 700;
          padding-bottom: 5px;
          min-height: 24px;
          @include mq-size(s) {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
.sme-section {
  margin-top: 64px;
  width: 100%;
  @include mq-size(m) {
    width: 89.6%;
  }
  @include mq-size(xl) {
    width: 69.3%;
  }
  &__header {
    color: $Primary-Grey600;
    font-size: 19px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;
  }
  &__description,
  &__mllf-info {
    font-size: 16px;
    line-height: 24px;
  }
  &__mllf-info {
    margin: 16px 0 24px 0;
  }
  &__questions {
    dt {
      margin-top: 20px;
    }
  }
}
