
@import '@/styles/animations';
@import '@/variables';

.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
.ing-modal-overlay {
  background: rgba(84, 84, 84, 0.5);
  backdrop-filter: blur(4px);
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition-delay: 5s;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.ing-modal {
  display: flex;
  flex: 0 1 auto;
  overflow: hidden;
  position: relative;
  width: calc(100vw - 24%);
  max-width: 1094px;
  height: 100vh;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 16px;
  margin: 6.875rem auto;
}

.close-button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0 28px;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 9;
}

.close-button {
  width: 24px;
  height: 24px;
  line-height: 28px;
  font-size: revert; // needed to add it to reset font-size: 0 which is set in Lendico CSS
  padding: 0;
  margin: 26px -2px 0 0;
  background: transparent;
  cursor: pointer;
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 1, 1);
  transition-property: box-shadow, border-width;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid #559bd1;
    box-shadow: 0 0 8px 0 #559bd1;
  }
}

.scroll-container {
  overflow: auto;
  flex: 1 1 auto;
  padding: 3.78125rem 5.84375rem 0 2.75rem;

  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

/*
  * Tablet
  */
@media screen and (max-width: $desktop-s) {
  .ing-modal {
    width: calc(100vw - 16px);
    height: auto;
    margin: 5.625rem auto;
  }

  .scroll-container {
    padding: 3.09375rem 4.78125rem 0 2.25rem;
  }

  .close-button-wrapper {
    padding: 0 1.6875rem;
    height: 64px;
  }

  .close-button {
    margin: 25px -3px 0 0;
  }
}

/*
* Mobile
*/
$st-tablet: $tablet - 1;
@media screen and (max-width: $st-tablet) {
  .ing-modal {
    width: 100%;
    height: 100vh;
    margin: 0;
    border-radius: 0;
  }

  .close-button-wrapper {
    height: 56px;
    padding: 0 20px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(2px);
  }

  .close-button {
    margin: 18px -2px 0 0;
  }

  .scroll-container {
    padding: 3.25rem 5% 6.25rem 5%;
  }
}
