@import '@/sass/components/common/ing.orange.juice';

.addMaterialDesign {
  .checkbox {
    p {
      padding-left: 30px;
      width: 100%;
      display: block;
    }

    p a {
      color: $petroleum;
      text-decoration: underline;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }

    .checkbox-material {
      position: absolute;

      .check {
        border: 1px solid $grey;
        border-radius: 0;
      }
    }

    input[type='checkbox'] {
      &:checked + .checkbox-material {
        .check {
          border: 1px solid $darkerGrey;
          background: $petroleum;
        }
      }
    }
  }

  .radio-checkmark:after {
    background: #29bdba;
  }

  .togglebutton label {
    input[type='checkbox'] {
      &:checked + .toggle {
        &:after {
          border-color: $petroleum;
        }
      }
    }
  }

  .radio {
    label {
      .circle {
        border: 1px solid $grey;
        height: 21px;
        width: 21px;
      }

      .check {
        height: 21px;
        width: 21px;
        background-color: $petroleum;
      }
    }
  }

  input[type='radio'] {
    &:checked ~ {
      .check {
        background-color: $petroleum;
      }

      .circle {
        border-color: $grey;
      }

      .check {
        -webkit-transform: scale3d(0.55, 0.55, 1);
        -moz-transform: scale3d(0.55, 0.55, 1);
        -o-transform: scale3d(0.55, 0.55, 1);
        -ms-transform: scale3d(0.55, 0.55, 1);
        transform: scale3d(0.55, 0.55, 1);
      }
    }
  }

  .label {
    &.label-primary {
      background-color: $petroleum;
    }
  }

  .form-control,
  .form-group .form-control {
    background-image: linear-gradient($petroleum, $petroleum),
      linear-gradient(#d2d2d2, #d2d2d2);
  }

  .form-control:-ms-input-placeholder,
  .form-control::-moz-placeholder,
  .form-control::-webkit-input-placeholder,
  .form-group .form-control:-ms-input-placeholder,
  .form-group .form-control::-moz-placeholder,
  .form-group .form-control::-webkit-input-placeholder {
    color: $grey;
  }

  .form-group {
    margin: 0;

    &.with-button {
      button,
      span.span-button {
        background: none;
        border: none;
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        padding: 10px;
        vertical-align: top;
        cursor: pointer;
      }

      button svg,
      span.span-button svg {
        width: 16px;
        height: 16px;
        display: block;
        position: relative;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        vertical-align: top;
      }

      button.icon-magnifier {
        padding: 10px 0 10px 20px;
      }

      button.icon-magnifier svg path {
        fill: $petroleum;
      }
    }

    &.is-empty {
      .form-control {
        background-image: linear-gradient($grey, $grey),
          linear-gradient($grey, $grey);

        .material-input:after {
          background-color: $petroleum;
        }
      }
    }

    .form-control {
      background-image: linear-gradient($darkerGrey, $darkerGrey),
        linear-gradient($darkerGrey, $darkerGrey);

      .material-input:after {
        background-color: $petroleum;
      }
    }

    &.is-focused {
      .form-control {
        background-image: linear-gradient($petroleum, $petroleum),
          linear-gradient($petroleum, $petroleum);

        .material-input:after {
          background-color: $petroleum;
        }
      }

      label {
        color: $petroleum;

        &.control-label {
          color: $petroleum;
        }
      }

      &.label-placeholder label {
        color: $grey;

        &.control-label {
          color: $grey;
        }
      }
    }
  }

  .form-control {
    &:-ms-input-placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder {
      color: $grey;
    }
  }

  .checkbox label,
  .radio label {
    color: $grey;
  }

  label {
    &.control-label {
      color: $grey;
    }
  }

  .form-group {
    .form-control {
      &:-ms-input-placeholder,
      &::-moz-placeholder,
      &::-webkit-input-placeholder {
        color: $grey;
      }
    }

    .checkbox label,
    .radio label {
      color: $grey;
    }

    label {
      color: $darkerGrey;

      &.control-label {
        color: $grey;
      }
    }

    &.form-group-sm {
      .form-control {
        &:-ms-input-placeholder,
        &::-moz-placeholder,
        &::-webkit-input-placeholder {
          color: $grey;
        }
      }

      .checkbox label,
      .radio label {
        color: $grey;
      }

      label {
        color: $grey;

        &.control-label {
          color: $grey;
        }
      }
    }

    &.form-group-lg {
      .form-control {
        &:-ms-input-placeholder,
        &::-moz-placeholder,
        &::-webkit-input-placeholder {
          color: $grey;
        }
      }

      .checkbox label,
      .radio label {
        color: $grey;
      }

      label {
        color: $grey;

        &.control-label {
          color: $grey;
        }
      }
    }
  }

  .checkbox,
  .radio {
    margin: 0;
    display: inline-block;

    label {
      vertical-align: top;
    }
  }

  .radio input[type='radio'] ~ .circle {
    border-color: $grey;
  }

  .radio input[type='radio']:checked ~ .circle {
    border-color: $grey;
  }

  .radio input[type='radio']:checked ~ .check {
    background-color: $petroleum;
  }

  .radio input[type='radio']:checked ~ .check {
    -webkit-transform: scale3d(0.55, 0.55, 1);
    -moz-transform: scale3d(0.55, 0.55, 1);
    -o-transform: scale3d(0.55, 0.55, 1);
    -ms-transform: scale3d(0.55, 0.55, 1);
    transform: scale3d(0.55, 0.55, 1);
  }

  .form-group label.control-label {
    @include font-size(12);
    color: $grey;
    margin: 10px 0 0;
  }

  .form-group.label-floating
    input.form-control:-webkit-autofill
    ~ label.control-label
    label.control-label,
  .form-group.label-floating.is-focused label.control-label,
  .form-group.label-floating:not(.is-empty) label.control-label {
    top: -20px;
  }

  .form-group.label-floating
    input.form-control:-webkit-autofill
    ~ label.control-label
    label.control-label,
  .form-group.label-floating.is-focused label.control-label,
  .form-group.label-floating:not(.is-empty) label.control-label {
    @include font-size(12);
  }

  .form-control,
  .form-group .form-control {
    font-size: 16px;
    line-height: 16px;
    color: $darkerGrey;
  }

  .form-group.is-focused label.control-label {
    color: $grey;
  }

  .form-group .form-control {
    margin-bottom: 0;
  }

  .checkbox .checkbox-material:before {
    content: none;
  }

  .validation {
    font-size: 14px;
    line-height: 14px;
    margin-top: 5px;
  }

  &.fieldset-container {
    margin: 0 0 10px 0;
  }
}

a.base-link {
  color: $petroleum;
  text-decoration: underline;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

button.secondary-button {
  border: 2px solid $petroleum;
  background-color: $white;
  color: $petroleum;
  box-sizing: border-box;
  height: 50px;

  &.active,
  &:focus,
  &:hover {
    background-color: $petroleum;
    text-decoration: none;
    color: $white;
  }
}

.form,
.borrower-form-container,
.partner-form-container,
.signer-form-container,
.ing-signer-form-container,
.ing-ident-form-container {
  font-size: 0;
  text-align: left;

  .fieldset,
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }

  .fieldset input,
  fieldset input {
    width: 100%;
  }

  .fieldset label,
  fieldset label {
    font-weight: $normal;
    display: inline-block;
    vertical-align: top;
    color: $grey;
    @include font-size(14);
    line-height: 1.6;
  }

  strong {
    color: $darkerGrey;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    line-height: 1;
  }

  .fieldset.error,
  fieldset.error {
    &.addMaterialDesign .checkbox .checkbox-material .check {
      border: solid 1px $validation;
    }

    &.addMaterialDesign .checkbox .checkbox-material .check {
      border: solid 1px $validation;
    }

    &.addMaterialDesign .radio input[type='radio'] ~ .circle {
      border-color: $validation;
    }

    &.addMaterialDesign .form-group .form-control,
    &.addMaterialDesign .form-group.is-empty .form-control,
    &.addMaterialDesign .form-group.is-focused .form-control {
      background-image: linear-gradient($validation, $validation),
        linear-gradient($validation, $validation);
      background-size: 100% 1px, 100% 1px;
      box-shadow: none;
      transition-duration: 0.3s;
    }
  }

  fieldset[disabled='disabled'] {
    cursor: default;
    @include opacity(0.5);

    &.addMaterialDesign .checkbox p,
    &.addMaterialDesign .checkbox p a {
      color: $grey;

      &:focus,
      &:hover {
        color: $grey;
      }
    }
  }

  .dropdown-custom {
    position: relative;

    .toggler {
      position: absolute;
      top: -45px;
      right: 0;
      height: 15px;
      padding-bottom: 30px;
      border: none !important;
      background: none !important;
      text-shadow: none !important;
      border-radius: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;

      svg {
        width: 14px;
        height: 14px;
        display: block;
        position: relative;
        @include css3('transform-origin', 'center');
        @extend .transition;
      }

      svg polygon {
        fill: $darkerGrey;
      }
    }

    .toggler.toggler-select {
      width: 100%;

      svg {
        float: right;
      }
    }

    &.open {
      .toggler svg {
        @include css3('transform', 'rotate(-180deg)');
      }
    }

    .dropdown-menu {
      font-size: 16px;
      text-align: left;
      border: none !important;
      border-radius: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      width: 100%;
      top: -10px;
      background: darken($white, 10%);

      &:after {
        box-sizing: content-box;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        border: solid white 10px;
        border-top: none;
        box-sizing: content-box;
        z-index: 0;
        margin: 0 0 0 -10px;
      }
    }

    .dropdown-menu > li {
      z-index: 1;
      display: block;
      position: relative;
    }

    .dropdown-menu > li > a {
      padding: 20px 10px 15px;
      clear: both;
      font-weight: $normal;
      line-height: 1;
      color: $darkerGrey;
      white-space: nowrap;
      border-bottom: solid 1px $grey;
      background: none !important;
      @include font-size(14);
      @extend .transition;

      &:focus,
      &:hover {
        color: $petroleum;
        font-weight: $bold;
      }
    }
  }
}

.dark-section {
  .addMaterialDesign {
    .checkbox {
      .checkbox-material {
        .check {
          border: 1px solid $grey;
        }
      }
    }

    .radio {
      label {
        .circle {
          border: 1px solid $grey;
        }
      }
    }

    input[type='radio'] {
      &:checked ~ {
        .circle {
          border-color: $grey;
        }
      }
    }

    .form-control,
    .form-group .form-control {
      background-image: linear-gradient($petroleum, $petroleum),
        linear-gradient(#ffffff, #ffffff);
    }

    .form-control:-ms-input-placeholder,
    .form-control::-moz-placeholder,
    .form-control::-webkit-input-placeholder,
    .form-group .form-control:-ms-input-placeholder,
    .form-group .form-control::-moz-placeholder,
    .form-group .form-control::-webkit-input-placeholder {
      color: $grey;
    }

    .form-group {
      &.is-empty {
        .form-control {
          background-image: linear-gradient($grey, $grey),
            linear-gradient($grey, $grey);
        }
      }

      .form-control {
        background-image: linear-gradient($white, $white),
          linear-gradient($white, $white);
      }

      &.is-focused {
        &.label-placeholder label {
          color: $grey;

          &.control-label {
            color: $grey;
          }
        }
      }
    }

    .form-control {
      &:-ms-input-placeholder,
      &::-moz-placeholder,
      &::-webkit-input-placeholder {
        color: $grey;
      }
    }

    .checkbox label,
    .radio label {
      color: $grey;
    }

    label {
      &.control-label {
        color: $grey;
      }
    }

    .form-group {
      .form-control {
        &:-ms-input-placeholder,
        &::-moz-placeholder,
        &::-webkit-input-placeholder {
          color: $grey;
        }
      }

      .checkbox label,
      .radio label {
        color: $grey;
      }

      label {
        color: $grey;

        &.control-label {
          color: $grey;
        }
      }

      &.form-group-sm {
        .form-control {
          &:-ms-input-placeholder,
          &::-moz-placeholder,
          &::-webkit-input-placeholder {
            color: $grey;
          }
        }

        .checkbox label,
        .radio label {
          color: $grey;
        }

        label {
          color: $grey;

          &.control-label {
            color: $grey;
          }
        }
      }

      &.form-group-lg {
        .form-control {
          &:-ms-input-placeholder,
          &::-moz-placeholder,
          &::-webkit-input-placeholder {
            color: $grey;
          }
        }

        .checkbox label,
        .radio label {
          color: $grey;
        }

        label {
          color: $grey;

          &.control-label {
            color: $grey;
          }
        }
      }
    }

    .radio input[type='radio'] ~ .circle {
      border-color: $grey;
    }

    .radio input[type='radio']:checked ~ .circle {
      border-color: $grey;
    }

    .form-group label.control-label {
      color: $grey;
    }

    .form-control,
    .form-group .form-control {
      color: $white;
    }

    .form-group.is-focused label.control-label {
      color: $grey;
    }

    .form,
    .borrower-form-container,
    .partner-form-container {
      .fieldset label,
      fieldset label {
        color: $grey;
      }

      strong {
        color: $white;
      }

      fieldset[disabled='disabled'] {
        cursor: default;

        &.addMaterialDesign .checkbox p,
        &.addMaterialDesign .checkbox p a {
          color: $grey;

          &:focus,
          &:hover {
            color: $grey;
          }
        }
      }

      .dropdown-custom {
        .dropdown-menu > li > a {
          color: $white;
          border-bottom: solid 1px $grey;
        }
      }
    }

    .addMaterialDesign .form-control,
    .addMaterialDesign .form-group .form-control {
      color: $white;
    }

    .addMaterialDesign .form-group .form-control {
      background-image: linear-gradient($white, $white),
        linear-gradient($white, $white);
    }
  }

  .dropdown-menu {
    background: ligthen($darkerGrey, 10%);
  }

  .form .dropdown-custom .dropdown-menu > li > a,
  form .dropdown-custom .dropdown-menu > li > a {
    color: $white;
  }

  .form .dropdown-custom .dropdown-menu > li > a:focus,
  .form .dropdown-custom .dropdown-menu > li > a:hover,
  form .dropdown-custom .dropdown-menu > li > a:focus,
  form .dropdown-custom .dropdown-menu > li > a:hover {
    color: $petroleum;
  }

  .form strong,
  form strong {
    color: $white;
  }

  .addMaterialDesign
    .checkbox
    input[type='checkbox']:checked
    + .checkbox-material
    .check:before {
    color: $darkerGrey;
  }

  .addMaterialDesign .checkbox p a:focus,
  .addMaterialDesign .checkbox p a:hover {
    color: $white;
  }

  .form .dropdown-custom .toggler svg polygon,
  form .dropdown-custom .toggler svg polygon {
    fill: $white;
  }
}

.light-section {
  .addMaterialDesign
    .checkbox
    input[type='checkbox']:checked
    + .checkbox-material
    .check:before {
    color: $lightGrey;
  }

  .form,
  form {
    .dropdown-menu {
      background: ligthen($lightGrey, 10%);

      &:after {
        border: solid $lightGrey 10px;
        margin: -11px 0 0 -10px;
      }
    }
  }
}

.dark-section {
  .form,
  form {
    .dropdown-menu {
      &:after {
        border: solid $darkerGrey 10px;
        margin: -11px 0 0 -10px;
      }
    }
  }
}

.signer-form-container {
  * {
    font-family: 'Source Sans Pro', Calibri, Helvetica, sans-serif;
    font-weight: normal;
  }

  strong {
    font-weight: bold;
  }

  .field-label {
    display: inline-block;
    vertical-align: top;

    em {
      font-weight: 600;
    }
  }

  .company-overview-container,
  .controlling-company-overview-container {
    h3 {
      font-size: 16px;
      margin-bottom: 16px;
      font-weight: bold;
      color: $darkerGrey;
    }

    p {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .header-container {
    display: flex;
    margin-bottom: 50px;

    p {
      text-align: left;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.64;
      letter-spacing: normal;
      color: #808080;
    }

    h2 {
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #29bdba;
      margin-bottom: 7px;
      margin-top: 0px;
    }

    .text-container {
      margin-left: 40px;
    }

    /* Extra Small Devices, Phones */
    @media only screen and (max-width: 480px) {
      .text-container {
        margin: 0;
      }
      h2,
      p {
        text-align: center;
        margin-top: 15px;
      }
    }
  }

  @media (max-width: 480px) {
    .header-container {
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
    }
  }

  .overview-container {
    border: solid 1px #e3e3e3;
    padding: 40px;
    display: flex;
  }

  .form-container {
    border: solid 1px #e3e3e3;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  // tooltips
  .controlling-tooltip-message {
    width: 332px;
    left: -125px;
    top: -68px;
  }

  @media screen and (max-width: 430px) {
    .controlling-tooltip-message {
      width: 200px;
      left: -75px;
      top: -80px;
    }
  }
  @media screen and (max-width: 340px) {
    .controlling-tooltip-message {
      width: 150px;
      left: -60px;
      top: -106px;
    }
  }

  .name-tooltip-message {
    width: 250px;
    left: -90px;
    top: -75px;
  }

  .company-name-tooltip-message {
    width: 350px;
    left: -140px;
    top: -180px;
    u {
      color: $petroleum;
    }
  }

  .company-tax-number-tooltip-message {
    width: 375px;
    left: -120px;
    top: -95px;
  }

  .tax-number-tooltip-message {
    width: 375px;
    left: -120px;
    top: -117px;
  }

  .trading-name-tooltip-message {
    width: 300px;
    left: -100px;
    top: -96px;
  }

  .cash-intensity-tooltip-message {
    width: 251px;
    top: -95px;
    left: -90px;
  }

  @media screen and (max-width: 400px) {
    .cash-intensity-tooltip-message {
      width: 200px;
      top: -138px;
      left: -65px;
    }
  }

  .issuing-authority-tooltip-message {
    width: 350px;
    left: -150px;
    top: -118px;
    word-break: normal;
  }

  @media screen and (max-width: 675px) {
    .issuing-authority-tooltip-message {
      width: 300px;
      left: -150px;
      top: -138px;
    }
  }
  @media screen and (max-width: 375px) {
    .issuing-authority-tooltip-message {
      width: 250px;
      left: -150px;
      top: -138px;
    }
  }
}

.ing-wrapper {
  em {
    font-weight: bold;
    font-style: normal;
  }

  label {
    font-weight: normal;
  }

  .ing-fields-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ing-column-left {
    padding-right: 30px;
    flex-grow: 1;
  }

  .ing-column-right {
    padding-left: 30px;
    flex-grow: 1;
  }

  .ing-label {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-family: $ing-main-font;
    color: $ing-gray-label;
  }

  .ing-input-error {
    border: 1px solid $ing-red;
  }

  .form-control:focus,
  .ing-input-focus {
    border: 1px solid $ing-orange;
    box-shadow: none;
  }

  .ing-error {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 15px;
    margin: 4px 0 15px 0;

    img {
      width: 15px;
      height: 15px;
      align-self: baseline;
      margin-right: 5px;
    }

    p {
      align-self: center;
      color: $ing-gray-label;
      font-family: $ing-main-font;
      font-size: 12px;
      margin: 0;
    }
  }

  .ing-error.active {
    visibility: visible;
  }

  .ing-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid $ing-light-gray;
    margin-right: 10px;
    background: $ing-orange;
    cursor: pointer;
  }

  a.ing-link {
    color: $ing-blue;
    text-decoration: underline;

    &:visited {
      color: $ing-fuchsia;
    }

    &:hover,
    &:focus {
      background: #e6e5ef;
    }
  }

  // tooltips
  .tooltip-wrapper {
    position: relative;
    font-size: 14px;
    line-height: 24px;
  }

  .ing-tooltip {
    height: 15px;
    width: 15px;
    margin: 0 5px 0 5px;
    vertical-align: sub;
  }

  .ing-tooltip-message {
    visibility: hidden;
    word-break: normal;
    font-weight: normal;
    position: absolute;
    background-color: $ing-gray;
    border-radius: 4px;
    border: 1px solid $ing-light-gray;
    font-size: 12px;
    padding: 10px;
    color: white;
    z-index: 100;

    p {
      margin: 0;
    }

    .ing-link {
      color: #fff;
    }
  }

  .ing-tooltip-arrow {
    position: absolute;
    content: '';
    left: 10px;
    top: -10px;
    height: 0;
    width: 0;
    border: 5px solid transparent;
    border-top: 5px solid $ing-gray;
    display: block;
    z-index: 200;
  }

  .ing-tooltip-message.active {
    visibility: visible;
  }

  .header-tooltip-message {
    top: 170%;
    right: -4px;
    width: 369px;
    font-size: 14px;
  }

  .header-tooltip-message:before {
    position: absolute;
    content: '';
    bottom: 100%;
    left: 95%;
    height: 0;
    width: 0;
    border-left: 5px solid #fff;
    border-right: 5px solid #fff;
    border-bottom: 5px solid #767676;
    display: block;
  }

  .controlling-tooltip-message {
    width: 337px;
    left: -125px;
    top: -55px;
  }

  @media screen and (max-width: 430px) {
    .controlling-tooltip-message {
      width: 200px;
      left: -75px;
      top: -79px;
    }
  }
  @media screen and (max-width: 340px) {
    .controlling-tooltip-message {
      width: 160px;
      left: -60px;
      top: -103px;
    }
  }

  .name-tooltip-message {
    width: 250px;
    left: -90px;
    top: -55px;
  }

  .company-name-tooltip-message {
    width: 350px;
    left: -140px;
    top: -175px;
  }

  .tax-number-tooltip-message {
    width: 220px;
    left: -90px;
    top: -55px;
  }

  .trading-name-tooltip-message {
    width: 260px;
    left: -100px;
    top: -79px;
  }

  .cash-intensity-tooltip-message {
    width: 251px;
    left: -90px;
    top: -79px;
  }

  .iban-tooltip-message {
    width: 290px;
    left: -90px;
    top: -79px;
  }

  .company-revenues-tooltip-message {
    width: 310px;
    left: -90px;
    top: -79px;
  }

  .company-assets-tooltip-message {
    width: 300px;
    left: -90px;
    top: -79px;
  }

  @media screen and (max-width: 400px) {
    .cash-intensity-tooltip-message {
      width: 200px;
      top: -103px;
      left: -65px;
    }
  }

  .issuing-authority-tooltip-message {
    width: 350px;
    left: -150px;
    top: -103px;
  }

  @media screen and (max-width: 675px) {
    .issuing-authority-tooltip-message {
      width: 300px;
      left: -150px;
      top: -127px;
    }
  }
  @media screen and (max-width: 375px) {
    .issuing-authority-tooltip-message {
      width: 250px;
      left: -150px;
      top: -127px;
    }
  }

  /** bootstrap tooltip */
  .tooltip-inner {
    width: 479px;
    max-width: 479px !important;
    padding: 10px !important;
    text-align: left !important;
  }

  @media screen and (max-width: 425px) {
    .tooltip-inner {
      width: 280px;
      padding: 6px !important;
      font-size: 12px;
    }
  }

  .ing-section {
    padding: 80px 0;

    @media screen and (max-width: $mobile-l) {
      padding: 40px 0;
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
      padding-bottom: 10px;
      text-align: left;
    }
  }

  .ing-phone-email {
    padding-top: 50px;
  }

  .ing-dark-section {
    background: $ing-section-gray;
  }

  .ing-signer-form-container {
    strong {
      font-weight: bold;
    }

    h3 {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .header-container {
      display: flex;
      margin-bottom: 50px;

      p {
        text-align: left;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.64;
        letter-spacing: normal;
        color: #808080;
      }

      h2 {
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $ing-black;
        margin-bottom: 7px;
        margin-top: 0px;
      }

      .text-container {
        margin-left: 40px;
      }

      @media (max-width: 480px) {
        .text-container {
          margin: 0;
        }
        h2 {
          text-align: center;
          margin-top: 15px;
        }
      }
    }

    /* Extra Small Devices, Phones */
    @media (max-width: 480px) {
      .header-container {
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
      }
    }

    .overview-container {
      border: solid 1px #e3e3e3;
      padding: 40px;
      display: flex;

      .header {
        font-size: 19px;
        font-weight: bold;
        line-height: 19px;
      }

      .description {
        font-size: 14px;
        line-height: 24px;
      }
    }

    .form-container {
      border: solid 1px #e3e3e3;
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 476px) {
      .overview-container {
        padding: 30px;
      }
    }
  }

  .ing-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (min-width: $mobile-l) and (max-width: $tablet) {
      align-items: flex-start;
    }

    .company-icon {
      height: 64px;
    }

    .representative-icon {
      width: 64px;
    }

    .submit-icon {
      height: 59px;
      width: 64px;
    }

    &__content {
      margin-left: 20px;
    }

    p {
      font-size: 16px;
      text-align: left;
      line-height: 24px;
      color: $ing-gray;
    }

    .header-icon {
      @media screen and (max-width: $mobile-l) {
        margin-bottom: 20px;
      }
    }
  }

  .ing-ident-form-container {
    h2 {
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px;
      letter-spacing: -0.02em;
      text-align: left;
      color: $ing-orange;
    }

    h3 {
      margin-top: 10px;
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.02em;
      text-align: left;
    }

    h4 {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-align: left;
    }

    .form-container {
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    .form-section-container {
      width: 100%;
      padding: 20px 10px;
    }

    .error-container {
      padding-top: 20px;
    }
  }

  .ing-ident-header-container {
    a.ing-header-link {
      color: #ffffff;
      text-decoration: underline;

      &:visited {
        color: #ffffff;
      }
    }
  }

  .form-container {
    h3 {
      font-weight: bold;
      font-size: 19px;
      line-height: 19px;
    }
  }

  .overview-container {
    .field-label {
      line-height: 24px;
      font-weight: bold;
      font-size: 14px;
      display: inline-flex;
      align-items: baseline;
      color: $ing-gray-thank-you-page;
    }

    .field-value {
      line-height: 24px;
      font-weight: bold;
      font-size: 14px;
      color: $ing-dark-gray;
    }
  }

  .ing-section-submit-button {
    background: $ing-orange;
    border-radius: 8px;
    max-width: 288px;
    width: fit-content;
    padding: 0 2.5em;
    min-height: 44px;
    color: white;
    cursor: pointer;
    font-weight: bold;
    border: none;
    @include font-size(19);
  }

  .ing-section-submit-no-outline-button {
    background: rgba($white, 0);
    border: none;
    color: $ing-orange;
    cursor: pointer;
    font-weight: bold;
    height: fit-content;
    width: fit-content;
    padding: 0;
    border-bottom: 2px solid $ing-orange;
    @include font-size(19);
  }

  .ing-submit-button-wrapper {
    text-align: center;
  }
  .ing-submit-button {
    background: $ing-orange;
    border-radius: 8px;
    padding: 0 100px;
    min-height: 55px;
    color: white;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    -webkit-font-smoothing: antialiased;

    @media screen and (max-width: $mobile-l) {
      padding: 0 14px;
      width: 100%;
    }
  }

  .ing-medium-button {
    background: $ing-orange;
    border-radius: 8px;
    width: 50%;
    min-height: 44px;
    color: white;
    font-size: 19px;
    font-weight: bold;
    cursor: pointer;
    border: none;
  }

  .ing-ident-submit-button {
    background: $ing-orange;
    border-radius: 8px;
    width: fit-content;
    padding: 0 2.5em;
    min-height: 44px;
    color: white;
    cursor: pointer;
    font-weight: bold;
    border: none;
    @include font-size(19);

    @media screen and (max-width: $mobile-l) {
      padding: 0 14px;
      width: 100%;
    }
  }

  //overrides for checkbox and dropdown
  .radio-checkmark:after {
    background: $ing-orange;
  }

  .ing-dropdown {
    position: relative;
    cursor: pointer;
    border: none;
    width: 100%;

    button {
      position: absolute;
      top: -35px;
      height: 15px;
      padding-bottom: 30px;
      width: 100%;
      background: transparent;
      border: 0;
    }

    .btn:hover,
    .btn:active,
    .btn:focus {
      background: transparent;
      box-shadow: none;
    }

    svg {
      position: absolute;
      width: 14px;
      height: 8px;
      left: 90%;
      top: 40%;
    }

    ul {
      width: 100%;
    }
  }

  .label-text {
    line-height: 22px;
    padding-left: 30px;
  }

  .label-checkbox {
    display: block;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .label-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .check {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: #ffffff;
    border: 1px solid #a8a8a8;
    box-sizing: border-box;
    box-shadow: inset 0px 2px 2px #d5d5d5;
    border-radius: 4px;

    &--error {
      border-color: $ing-red;
    }
  }

  /* On mouse-over, add a grey background color */
  .label-checkbox:hover input ~ .check {
    /*background-color: lightgrey;*/
  }

  /* When the checkbox is checked, add a blue background */
  .label-checkbox input:checked ~ .check {
    background-color: white;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .check:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .label-checkbox input:checked ~ .check:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .label-checkbox .check:after {
    left: 6px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid $ing-orange;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .ing-section-content {
    max-width: 984px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  // media queries
  @media (max-width: 767px) {
    .ing-fields-container {
      flex-direction: column;
    }
    .ing-column-left,
    .ing-column-right {
      padding: 0;
    }
  }
  @media (max-width: 479px) {
    .ing-header-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: space-between;

      &__content {
        margin: 0;
      }
    }
    .company-icon {
      width: 64px;
    }
  }

  @media (max-width: 400px) {
    .ing-section-content {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  @media screen and (max-width: ($tablet)) and (min-width: ($mobile-m)) {
    .ing-header-container {
      p {
        padding-right: 20px;
      }
    }
  }
}
