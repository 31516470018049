
@import '@/variables.scss';

.submit-container {
  margin-top: 80px;
  text-align: center;
}
.general-error-container {
  margin-bottom: 20px;
}
.general-error-container p.validation {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}
@media only screen and (max-width: $mobile-l) {
  .submit-container {
    margin-top: 40px;
  }
  .general-error-container p.validation {
    line-height: 18px;
  }
}
