
@import '../../../../sass/custom/variables';
p {
  margin-top: 20px;
}
.flex--between {
  display: flex;
  justify-content: space-between;
}
.flex--column {
  display: flex;
  flex-direction: column;
}
.display--flex {
  display: flex;
}
:deep(.icon-svg svg) {
  width: 24px;
  height: 24px;
}
.automation-box {
  margin-right: 40px;
}
.option-box {
  font-size: 14px;
  width: 560px;
  border: solid 1px #e3e3e3;
  padding-top: 20px;
}
.option-description {
  color: $ing-gray;
}
.option-2-warning {
  display: flex;
  align-items: center;
  height: 54px;
  border-top: solid 1px #e3e3e3;
  background-color: #f6f6f6;
  margin-top: 20px;
}
.option-2-text-wrapper {
  padding-left: 10px;
  line-height: 0.5;
}
p.option-2-text {
  display: inline;
}
.option-2-text {
  font-size: 12px;
  color: #2e2e2e;
  margin: 0;
  line-height: normal;
}
.mail-contact {
  color: #3cbdb9;
}
.text-padding {
  padding-left: 20px;
  padding-right: 20px;
}
.radio-sub-text {
  padding-left: 55px;
  padding-right: 20px;
}

.validation-text {
  font-size: 14px;
  margin: 2% 5%;
  display: block;
}
@media (max-width: 767px) {
  .options-container {
    flex-direction: column;
  }
  .option-box {
    width: 100%;
    margin: 2% 0% 0% 0%;
  }
}
@media (max-width: 358px) {
  .option-2-text {
    font-size: 10px;
  }
}
