.skewed-top {
    border-bottom: 90px solid $white;
    top: 0;
    &.dark{
      border-bottom-color: $darkerGrey;
    }
    &.light{
      border-bottom-color: $lightGrey;
    }
}

.skewed-bottom {
    border-top: 90px solid $white;
    bottom: 0;
    &.dark{
      border-top-color: $darkerGrey;
    }
    &.light{
      border-top-color: $lightGrey;
    }
}

.skewed-top, .skewed-bottom {
  width: 100%;
  height: 90px;
  position: absolute;
  z-index: 15;
  background-color: transparent;
    &.to-left{
      border-left: 100vw solid transparent;
    }
    &.to-right{
      border-right: 100vw solid transparent;
    }
}

.skewed-edge-top, .skewed-edge-bottom, .skewed-content{
  position: relative;
  display: block;
  width: 100%;
}

.skewed-content{
  //padding: 60px 0;
}

.skewed-edge-top{
  padding-top: 90px;
}

.skewed-edge-top.join-top{
  margin-top: -90px;
}


.skewed-edge-bottom{
  padding-bottom: 90px;
  .white-section {
    padding-bottom: 0 !important;
    margin-bottom: -20px !important;
  }
}
.skewed-edge-bottom.join-bottom{
  margin-bottom: -90px;
}

@include bp(mobile){
  .skewed-top {
      border-bottom: 30px solid $white;
  }

  .skewed-bottom {
      border-top: 30px solid $white;
  }

  .skewed-top, .skewed-bottom {
    height: 30px;
  }

  .skewed-content{
    //padding: 40px 0;
  }

  .skewed-edge-top{
    padding-top: 30px;
  }

  .skewed-edge-top.join-top{
    margin-top: -30px;
  }


  .skewed-edge-bottom{
    padding-bottom: 30px;
  }
  .skewed-edge-bottom.join-bottom{
    margin-bottom: -30px;
  }
}
