
@import '@/variables.scss';

.revenue-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 9px 0 13px 0;
  width: fit-content;

  .radio-container {
    margin-bottom: 0;

    &:nth-child(2) {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: $tablet) {
  .revenue-input-container {
    .radio-container {
      font-size: 16px;

      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: $mobile-l) {
  .revenue-input-container {
    flex-direction: column;
    margin: 7px 0 0 0;

    .radio-container {
      &:nth-child(2) {
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }
}
