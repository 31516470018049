
@import '@/variables.scss';

.company-representative-container {
  margin-bottom: 50px;
}
.representative-info-container {
  margin-top: 30px;
}
.first-column {
  vertical-align: top;
  padding-right: 3.5%;
}
.second-column {
  vertical-align: top;
  padding-left: 3.5%;
}

@media (max-width: $desktop-s) {
  .first-column {
    padding-right: 3.5%;
  }
  .second-column {
    padding-left: 3.5%;
  }
}
@media (max-width: $mobile-l) {
  .company-representative-container {
    margin-bottom: 12px;
  }
  .representative-info-container {
    margin-top: 25px;
  }
  .first-column {
    margin-top: 0;
    padding-right: 0;
  }
  .second-column {
    margin-top: 0;
    padding-left: 0;
  }
}
