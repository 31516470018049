
@import '../../../../sass/custom/variables';
.ing-offer-explanation {
  margin: 40px 0 20px 0;
  font-size: 14px;
  line-height: 18px;
  color: $ing-gray-advantages;
}
.button-fixed-width {
  max-width: 225px;
  width: fit-content;
  padding: 5px 10px;
}
