
@import '../../sass/custom/_settings.scss';
.edit-button-container {
  display: flex;
  flex-direction: row;
  height: fit-content;
  cursor: pointer;
  align-items: baseline;
  p {
    font-size: 16px;
    color: $petroleum;
    margin-left: 10px;
  }
}
