
@import '@/variables.scss';

.len-custom-select {
  position: relative;

  &__input {
    position: relative;

    display: flex;
    border-radius: 4px;

    &:after {
      content: '';
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent;
    }

    &__icon,
    &__element {
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
      cursor: pointer;
    }

    &__icon {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      padding: 6px 0 6px 15px;
      display: flex;
      align-items: center;
    }

    &__element {
      padding: 6px 24px 6px 0;
      background: transparent;
      outline: none;
      border: none;
      width: 100%;
      font-family: inherit;

      &::selection {
        background: transparent;
      }
      &::-moz-selection {
        background: transparent;
      }
    }
  }

  &__options {
    background: white;
    border: none;
    box-shadow: 0 2px 4px rgba(46, 46, 46, 0.15);
    border-radius: 0 0 4px 4px;

    position: absolute;
    display: -ms-grid;
    display: grid;
    right: 0;
    left: 0;
    top: 36px;
    overflow: hidden;
    z-index: 1;

    font-size: 16px;
    font-weight: 400;
    line-height: 16px;

    &__item {
      padding: 12px;
      text-align: initial;
      color: #2e2e2e;

      &:hover {
        background-color: #29bdba;
        color: white;
        cursor: pointer;
        font-weight: 600;
      }

      &--selected {
        color: #29bdba;
        font-weight: 600;
      }
    }
  }
}

/*
  * Mobile
  */
@media screen and (max-width: $mobile-l) {
  .len-custom-select {
    &__input {
      &__icon,
      &__element {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}
