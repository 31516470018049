<template>
  <div ref="spacer" class="spacer" />
</template>
<style scoped>
  .spacer {
    border: 0.5px solid #e3e3e3;
    position: relative;
    width: 100vw;
    left: calc(-50vw + 50%);
  }
</style>
