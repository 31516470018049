
@import 'src/sass/components/common/ing.orange.juice.scss';

.ing-offer-explanation {
  margin: 40px 0 20px 0;
  font-size: 16px;
  line-height: 24px;
  color: $Primary-Grey600;

  @include mq-size(s) {
    width: 94%;
  }
  @include mq-size(m) {
    width: 86%;
  }
  @include mq-size(l) {
    width: 78%;
  }
}
.button-fixed-width {
  max-width: 225px;
  width: fit-content;
  padding: 5px 10px;
}
.accept-button-error {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 6px 0 0;
  &__message {
    display: flex;
    align-items: center;
    font-size: px2rem(16px);
  }
  &__icon {
    margin-right: 10px;
    flex-basis: px2rem(18);
    flex-shrink: 0;
  }
}
