.main-footer{
  //position: relative;
  flex-shrink: 0;
  margin-top: 5%;
  .image-team{
    @include bp(mobile){
      &, img { width: 100%; display: block; height: auto; }
    }
  }
  .hide-desktop{
    display: none;
  }
  @include bp(mobile){
    .hide-mobile{
      display: none !important;
    }
    .hide-desktop{
      display: inline-block;
    }
  }
  a{
    color: $white;
    text-decoration: none;
    @include font-size(14);
    font-weight: $light;
    @extend .transition;
    &:hover, &:focus{
      text-decoration: underline;
      color: $white;
    }
  }
  p{
    color: $white;
    @include font-size(14);
    font-weight: $light;
  }
  h4{
    @include font-size(18);
    color: $white;
    font-weight: $bold;
    margin-bottom: 10px;
  }

  .social-media-links{
    h4{
      @include font-size(14);
    }
    @include bp(mobile){
      h4{
        display: block !important;
      }
    }
    svg *{
     fill: $white;
     @extend .transition;
    }
    a:hover, a:focus{
      svg *{
       fill: $petroleum;
      }
    }
  }
  .site-map-links{
    a{
      color: $grey;
    }
    a:hover, a:focus{
      text-decoration: underline;
      //color: $white;
    }
  }
  .faq-cta{
    p{
      color: $grey;
    }
  }
  .copyright{
    p, a{
      color: $grey;
      @include font-size(11);
    }
    a:hover, a:focus{
      text-decoration: underline;
      //color: $white;
    }
    ul li{
      display: inline-block;
      margin-right: 5px;
      &:last-child{
        margin-right: 0;
      }
    }
    @include bp(mobile){
      text-align: left;
      a{
        display: block;
        &:after{
          content: ' »';
        }
      }
      ul li{
        display: block;
      }
    }
  }
  .top-border, .bottom-border{
    border-color: lighten($darkerGrey, 5%);
  }
  &__faq a{
    margin: 20px 0 0 !important;
  }
}

span{
  &.com, &.at, &.de, &.es, &.pl, &.nl, &.ch, &.za, &.br{
      vertical-align: middle;
      display: inline-block;
      position: relative;
      line-height: 1;
    	background-size: 100% auto;
      // moved to cdn background-image: url('../assets/images/flags_all.png');
      background-repeat: no-repeat;
    	width: 16px;
    	height: 16px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
  }
  &.com{
    background-position: 0 0;
  }

  &.at{
  	background-position: 0 12.55%;
  }

  &.de{
  	background-position: 0 24.967%;
  }

  &.es{
  	background-position: 0 37.517%;
  }

  &.pl{
  	background-position: 0 49.933%;
  }

  &.nl{
  	background-position: 0 62.483%;
  }

  &.ch{
  	background-position: 0 74.9%;
  }

  &.za{
  	background-position: 0 87.45%;
  }

  &.br{
  	background-position: 0 100%;
  }
}
