
@import '../../../../sass/custom/variables';

.overview-container {
  font-size: 16px;

  &.--no-padding {
    padding: 0;
  }

  &__subsection {
    padding: 40px;

    &.dark-background {
      background-color: $ing-signer-section-background;
    }
  }
}

.flex--column {
  flex-direction: column;
}

.field-label {
  em {
    color: $ing-dark-gray;
  }
}

p {
  font-size: 14px;
  line-height: 21px;
}
