
@import '@/variables.scss';

.explanation {
  font-size: 18px;
  line-height: 24px;
  color: #2e2e2e;
  margin-top: 30px;
}

@media only screen and (max-width: $mobile-l) {
  .explanation {
    font-size: 16px;
    line-height: 18px;
    margin-top: 20px;
  }
}

.modal-inner-content {
  font-size: 16px;
  line-height: 20px;
  color: #333;
  letter-spacing: -0.02em;
  padding-bottom: 50px;
}
.modal-inner-header {
  text-transform: none;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
