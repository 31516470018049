
@import '../../../sass/custom/variables';
img {
  height: max-content;
  width: max-content;
}
.contract-download-container {
  display: flex;
  padding: 40px 0;
}
.contract-text {
  margin-left: 20px;
  font-size: 20px;
}
.contract-actions {
  display: flex;
  flex-direction: row;
}
.unavailable-contract {
  font-size: 16px;
  color: $ing-red;
}
