
@import '@/sass/components/common/ing.orange.juice';
.radio-group {
  padding-bottom: px2rem(33px);
  position: relative;
  &__title {
    font-size: px2rem(16px);
    font-weight: normal;
    display: block;
    position: relative;
  }
  &__elements {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: px2rem(24px);
    &--row {
      flex-direction: row;
    }
  }
  &__element {
    flex-basis: auto;
  }
}
