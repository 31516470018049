
@import '@/sass/custom/_settings.scss';
@import '@/sass/custom/_variables.scss';
h3 {
  margin-bottom: 40px;
}
.company-section-container {
  width: 100%;
  padding: 40px 20px;
  &:nth-child(even) {
    background-color: $ing-signer-section-background;
  }
  &.extra-top-padding {
    padding-top: 40px;
  }
  &.narrow-bottom-padding {
    padding-bottom: 20px;
  }
}
.company-section__iban-label {
  font-size: 14px;
  padding-bottom: 24px;
}
.form-container {
  &.alert {
    border: solid 1px $red;
  }
}
.col-sm-11 {
  margin-left: 4%;
}
.ing-controlling-title-alignment {
  display: flex;
  align-items: baseline;
}
.padding-left-alignment {
  padding-left: 2%;
}
.padding-extra-left-alignment {
  padding-left: 4%;
}
.submit-section-container {
  width: 100%;
  padding: 20px 40px 40px 40px;
}
.short-column-alignment {
  vertical-align: top;
}
.general-error-container {
  height: 25px;
}
.general-error-container p {
  text-align: center;
  font-size: 0.8rem;
}
.assets-container {
  display: flex;
  align-items: center;
}
.mllf-info {
  font-size: 14px;
  margin-bottom: 24px;
}
