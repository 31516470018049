
@import '@/sass/custom/_settings.scss';
@import '@/variables.scss';

.fieldset-container {
  margin: 11px 0 30px 0;
}
.radio {
  &__wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 34px;
  }

  &__label {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    color: #2e2e2e;
    margin-right: 20px;
  }

  &__container {
    display: inline-block;

    label.radio-container {
      margin: 0 0 15px;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__tooltip {
    display: inline-block;
    &__icon {
      margin-left: 5px;
    }

    &__message {
      visibility: visible;
      position: absolute;
      bottom: 110%;
      left: 2px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 4px rgba(46, 46, 46, 0.1);
      border-radius: 1px;
      font-size: 12px;
      line-height: 14px;
      padding: 8px;
      color: $darkGrey;
      z-index: 100;
      width: 400px;

      &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 126px;
        border-style: solid;
        border-width: 5px 5px 0;
        border-color: #ffffff transparent;
        display: block;
        width: 0;
        z-index: 1;
      }

      &--hidden {
        visibility: hidden;
        position: absolute;
      }
    }
  }
}

.error-container {
  height: 20px;
  padding-left: 161px;
  width: 100%;
  margin-top: -4px;
}
.validation {
  margin-bottom: 0;
}

@media (max-width: $tablet) {
  .radio__tooltip__message {
    width: 380px;
  }
  .radio {
    &__container {
      margin-top: 15px;
    }
  }
  .error-container {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 5px;
  }
  .validation {
    margin-top: 0;
  }
}

@media (max-width: $mobile-l) {
  .radio__tooltip__message {
    width: 100%;
  }
  .fieldset-container {
    margin: 0 0 28px 0;
  }
}
