
.form-group {
  max-height: 89px;
}
.radio-input-container {
  display: flex;
}
:deep(.last-name-birth-radio) {
  margin-right: 20px;
  padding-top: 7px;
  text-align: center;
}
.error-container {
  height: 25px;
}
.validation {
  margin-bottom: 0px;
}
.hidden {
  visibility: hidden;
}
