
@import '@/sass/components/common/ing.orange.juice';

.client {
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
    color: $Primary-Grey600;
    margin-bottom: unset;
  }
  &__designation {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $Primary-Grey400;
  }
}
