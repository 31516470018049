
@import '@/sass/components/common/ing.orange.juice';
.subscription-section {
  &__content {
    @include mq-size(m) {
      width: 89.6%;
    }
    @include mq-size(xl) {
      width: 69.3%;
    }
  }
  &__form {
    padding-top: px2rem(40px);
    @include mq-size(s) {
      width: 86.6%;
    }
    @include mq-size(m) {
      width: 57.1%;
    }
    @include mq-size(xl) {
      width: 48.8%;
    }
  }
  &__title {
    font-size: px2rem(36px);
    line-height: px2rem(48px);
    color: $Primary-Orange;
    font-weight: normal;
    flex: 0 0 100%;
    margin: 0 0 px2rem(18px) 0;
  }
  &__subtitle {
    color: $Primary-Grey600;
    font-size: px2rem(19px);
    line-height: px2rem(28px);
    font-weight: 400;
    margin-top: px2rem(14px);
    display: block;
  }
  &__more-info {
    margin: px2rem(80px) 0;
  }
}
.bold-text {
  font-weight: 700;
}
