
.skeleton-loader {
  &__bone {
    overflow: hidden;
    position: relative;

    &:after {
      animation: loading 1.5s infinite;
      background: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0),
        hsla(0, 0%, 100%, 0.3),
        hsla(0, 0%, 100%, 0)
      );
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(-100%);
      z-index: 1;
    }
  }

  &__header {
    display: flex;
  }

  &__paragraph {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  &__icon {
    background-color: #ebebeb;
    height: 80px;
    width: 60px;
    margin-right: 32px;
  }

  &__heading {
    background-color: #ebebeb;
    flex: 1 0 auto;
    height: 24px;
    width: 243px;
    margin-bottom: 12px;
  }

  &__text {
    background-color: #ebebeb;
    flex: 1 0 auto;
    height: 18px;
    margin-bottom: 6px;
    width: 100%;

    &--large {
      height: 24px;
    }

    &:first-child {
      width: 288px;
      margin-bottom: 12px;
    }
  }

  &__block {
    background-color: #ebebeb;
    flex: 1 0 auto;
    height: 40px;
  }

  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }
}
