
@import '../../../sass/custom/_settings.scss';
.edit-overview {
  padding-bottom: 40px;
  color: $mediumGrey;
}
.field-label {
  display: inline-block;
  vertical-align: top;
}
.signer-overview-container {
  padding: 40px 0;
}
.signer-header-wrapper {
  display: flex;
  justify-content: space-between;
  vertical-align: center;
  padding-bottom: 20px;
}
h3 {
  font-size: 16px;
  font-weight: bold;
  color: $darkerGrey;
  margin-bottom: 10px;
}
p {
  font-size: 14px;
  line-height: 21px;
  color: $darkerGrey;
}
.missing-ubo-sentence {
  padding: 40px 40px 0 0;
  a {
    color: $petroleum;
  }
}
.position {
  margin-bottom: 40px;
}
.signer-information-container {
  display: flex;
}
.signer-column-container {
  flex-grow: 1;
  word-break: break-all;
}

.signer-information-container {
  justify-content: space-between;
}
.overview-container {
  &.alert {
    border: solid 1px $red;
  }
}
@media (max-width: 990px) {
  .signer-information-container {
    flex-wrap: wrap;
  }
}
@media (max-width: 1024px) {
  .signer-information-container {
    flex-direction: column;
  }
}
@media (max-width: 476px) {
  .signer-header-wrapper {
    flex-direction: column;
    justify-content: space-between;
  }
  .edit-button {
    padding-top: 10px;
  }
}
