
.error-container {
  height: 25px;
}

.validation {
  margin-bottom: 0px;
}

.dropdown {
  margin-top: -17px;
  margin-bottom: 17px;
}
