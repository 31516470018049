
h2 {
  font-weight: bold;
}

.caption {
  font-size: 18px;
  line-height: 27px;
}

.ing-phone-email {
  font-size: 24px;
  line-height: 31px;
}

.union {
  padding: 0 30px;
  font-size: 18px;
  line-height: 23px;
}

@media (max-width: 522px) {
  .ing-wrapper {
    padding-top: 20px;
  }
  .ing-phone-email {
    padding-top: 30px;
  }

  .union {
    display: block;
    padding: 20px 0;
  }
}
